import { TenantMemberSummaryDTO } from '@repositories/tenantMemberRepository/Types';

export class TenantMemberSummaryDTOModel {
  private _dto: TenantMemberSummaryDTO;

  constructor(dto: TenantMemberSummaryDTO) {
    this._dto = dto;
  }

  get monthlyTotalMemberIncrease(): number {
    return this._dto.monthlyTotalMemberIncrease || 0;
  }

  get totalCancelMemberCount(): number {
    return this._dto.totalCancelMemberCount || 0;
  }

  get totalMemberCount(): number {
    return this._dto.totalMemberCount || 0;
  }

  get totalNormalMemberCount(): number {
    return this._dto.totalNormalMemberCount || 0;
  }
}
