import { makeAutoObservable } from 'mobx';

export class UserEditStore {
  private _visible: boolean;
  private _userId: string;

  constructor() {
    this._visible = false;
    this._userId = '';
    makeAutoObservable(this);
  }

  get userId(): string {
    return this._userId;
  }

  get visible(): boolean {
    return this._visible;
  }

  open = (userId: string) => {
    this._visible = true;
    this._userId = userId;
  };

  close = () => {
    this.clear();
  };

  private clear = () => {
    this._visible = false;
  };
}
