import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { Button } from '@components/index';
import { useStore } from '@stores/RootStore';
import { fonts } from '@theme/fontsCustomer';
import { PencilIcon, TrashIcon } from '@icons/index';
import type { TenantCreateRq } from '@repositories/tenantRepository';
import { useGetTenant, useGetTenantMembers, useUpdateTenantLogo } from '@queryHooks/useTenant';

export const CompanyInfo = () => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const { authStore } = useStore();
  const blobRef = useRef<Blob>();
  const [imgSrc, setImgSrc] = useState('');
  const [isEditVisible, setEditVisible] = useState(false);

  const { data: tenant } = useGetTenant(authStore.curTenant.id);
  const {
    data: { content: members },
  } = useGetTenantMembers(authStore.curTenant.id, { memberRole: 'SUPER_ADMIN' });
  const rootAdmin = members.length > 0 ? members[0] : null;
  const { mutate: updateTenantLogo } = useUpdateTenantLogo();

  const disableDeleteButton = !tenant?.logoImageUrl;

  useEffect(() => {
    if (tenant) {
      setImgSrc(tenant.logoImageUrl);
    }
  }, [tenant]);

  const handleSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      // eslint-disable-next-line prefer-destructuring
      blobRef.current = e.target.files[0];
      const reader = new FileReader();
      reader.addEventListener('load', () => setImgSrc(reader.result?.toString() || ''));
      reader.readAsDataURL(e.target.files[0]);
      setEditVisible(true);
    }
  };

  const handleInputClick = (e: React.MouseEvent<HTMLInputElement>) => {
    e.currentTarget.value = '';
  };

  const handleDeleteLogo = () => {
    blobRef.current = undefined;
    setImgSrc('');
    setEditVisible(true);
  };

  const handleSave = () => {
    if (tenant) {
      const tenantCreateRq: TenantCreateRq = {
        name: tenant.name,
        tenantCode: tenant.tenantCode,
        companyId: tenant.company?.id,
        description: tenant.description,
        parentId: tenant.parentTenant.id,
        properties: tenant.properties,
      };
      updateTenantLogo(
        { id: tenant.id, logoImage: blobRef.current, tenant: tenantCreateRq },
        {
          onSuccess: () => {
            setEditVisible(false);
          },
        },
      );
    }
    blobRef.current = undefined;
  };

  const handleCancel = () => {
    blobRef.current = undefined;
    if (tenant) {
      setImgSrc(tenant.logoImageUrl);
    }
    setEditVisible(false);
  };

  return (
    <Conatiner>
      <Header>
        <strong>회사 정보 관리</strong>
      </Header>
      <Row>
        <RowTitle variant="headline8" component="strong">
          {t('Member_Company_01')}
        </RowTitle>
        <Typography variant="body2" component="p">
          {tenant?.name}
        </Typography>
      </Row>
      <Row>
        <RowTitle variant="headline8" component="strong">
          {t('Member_Manager_06')}
        </RowTitle>
        <Typography variant="body2" component="p">
          {rootAdmin ? `${rootAdmin.name}(${rootAdmin.username})` : '-'}
        </Typography>
      </Row>
      <Row>
        <LogoInfoWrap>
          <RowTitle variant="headline8" component="strong">
            {t('Member_Company_03')}
          </RowTitle>
          <Typography variant="body4" component="p" color={colors['text-gray-sub-dark']}>
            {t('Member_Company_04')}
          </Typography>
        </LogoInfoWrap>
        <LogoWrapper>
          <DashedBorder>
            {imgSrc ? <Logo alt="logo" src={imgSrc} /> : <CompanyName>{tenant?.name ?? ''}</CompanyName>}
            <Dimmed className="dimmed">
              <DeleteLogo
                variant="text"
                size="small"
                onClick={handleDeleteLogo}
                disabled={disableDeleteButton}
                startIcon={
                  <TrashIcon
                    width={16}
                    height={16}
                    color={disableDeleteButton ? colors['text-gray-light'] : colors['text-white']}
                  />
                }
              >
                {t('Member_Company_05')}
              </DeleteLogo>
              <FileInput>
                <input
                  id="input-file"
                  type="file"
                  accept="image/*"
                  onChange={handleSelectFile}
                  onClick={handleInputClick}
                />
                <EditLogo component="label" htmlFor="input-file">
                  <PencilIcon width={16} height={16} color={colors['ic-white']} />
                  {t('Member_Company_06')}
                </EditLogo>
              </FileInput>
            </Dimmed>
          </DashedBorder>
          <Typography variant="body4" component="p" color={colors['text-gray-light']}>
            {t('Member_Company_07')}
          </Typography>
        </LogoWrapper>
      </Row>
      {isEditVisible ? (
        <ButtonWrapper>
          <FooterButton variant="contain" size="large" onClick={handleSave}>
            {t('Member_Group_11')}
          </FooterButton>
          <FooterButton variant="outline" size="large" onClick={handleCancel}>
            {t('Subscrib_Detail_BuyRequest_13')}
          </FooterButton>
        </ButtonWrapper>
      ) : null}
    </Conatiner>
  );
};

const Conatiner = styled.section`
  width: 100%;
  height: calc(100vh - var(--manager-gnb-height));
  overflow: auto;
  padding: 0 32px 32px;
`;

const Header = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 28px 0 14px;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors['border-gray-lighter']};
  & > strong {
    ${fonts.Headline5};
    margin-left: 9px;
  }
`;

const Row = styled.div`
  display: flex;
  padding: 24px 0px 41px 18px;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors['border-gray-w-lighter']};
  &:last-of-type {
    border: none;
  }
`;

const RowTitle = styled(Typography)`
  width: 368px;
` as typeof Typography;

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 18px;
  gap: 6px;
`;

const FooterButton = styled(Button)`
  padding: 8px 16px;
`;

const DashedBorder = styled.div`
  position: relative;
  width: 380px;
  height: 168px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme: { colors } }) => colors['bg-gray-light']};
  border: 1px dashed ${({ theme: { colors } }) => colors['border-gray-dark']};
  border-radius: 3px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;

  &:hover > .dimmed {
    display: flex;
  }
`;

const Logo = styled.img`
  width: auto;
  max-width: 300px;
  height: 25px;
  object-fit: contain;
`;

const Dimmed = styled.div`
  display: none;
  align-items: center;
  justify-content: center;
  background: ${({ theme: { colors } }) => colors['bg-bk-opacity-80%']};
  border-radius: 3px;
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;

  z-index: 1;
`;

const FileInput = styled.div`
  color: ${({ theme: { colors } }) => colors['text-white']};
  z-index: 2;

  & > label {
    cursor: pointer;
    ${fonts.Button6};
  }

  & > input[type='file'] {
    position: absolute;
    width: 0;
    height: 0;
    padding: 0;
    overflow: hidden;
    border: 0;
  }
`;

const DeleteLogo = styled(Button)`
  position: relative;
  height: auto;
  gap: 2px;
  ${fonts.Button4};
  color: ${({ theme: { colors } }) => colors['text-white']};
  &:hover {
    color: ${({ theme: { colors } }) => colors['text-white']};
  }
  &:disabled {
    color: ${({ theme: { colors } }) => colors['text-gray-light']};
  }
  &:after {
    content: '';
    display: block;
    width: 1px;
    height: 12px;
    background-color: ${({ theme: { colors } }) => colors['border-gray-dark']};
    transform: translate(-50%, 0);
    margin: 0 17px;
  }
`;

const EditLogo = styled(Typography)`
  display: flex;
  align-items: center;
  gap: 2px;
  ${fonts.Button6};
  color: ${({ theme: { colors } }) => colors['text-white']};

  &:hover {
    color: ${({ theme: { colors } }) => colors['text-white']};
  }

  &:disabled {
    color: ${({ theme: { colors } }) => colors['text-gray-light']};
  }
` as typeof Typography;
const LogoInfoWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  & p {
    white-space: pre;
  }
`;

const CompanyName = styled('span')`
  ${fonts.Display3};
  color: ${({ theme }) => theme.colors['text-gray-main']};
`;
