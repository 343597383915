import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import type { ProductConnectorListDTO, UsageCollectMethod } from '@repositories/productConnectorRepository/Types';
import { SoftwareSimpleModel } from '@models/softwareModels';

export class ProductConnectorListModel {
  private _dto: ProductConnectorListDTO;
  private _product: SoftwareSimpleModel;

  constructor(dto: ProductConnectorListDTO) {
    this._dto = dto;
    this._product = new SoftwareSimpleModel(dto.product);
  }

  get connectorId(): string {
    return this._dto.connectorId || '';
  }

  get name(): string {
    return this._dto.name;
  }

  get description(): string | undefined {
    return this._dto.description;
  }

  get product(): SoftwareSimpleModel {
    return this._product;
  }

  get remoteConnectorId(): string {
    return this._dto.remoteConnectorId;
  }

  get usageCollectMethod(): UsageCollectMethod {
    return this._dto.usageCollectMethod;
  }

  get productCode(): string {
    return this._dto.productCode;
  }

  get connectionCount(): number {
    return this._dto.connectionCount ?? 0;
  }

  get createdBy(): string {
    return this._dto.createdBy || '';
  }

  get createdDate(): Dayjs {
    return dayjs(this._dto.createdDate) || dayjs();
  }

  get updatedBy(): string {
    return this._dto.updatedBy || '';
  }

  get updatedDate() {
    return dayjs(this._dto.updatedDate) || dayjs();
  }
}
