import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { useTheme } from '@mui/material';
import { DepthIcon, OrganizationIcon } from '@icons/index';
import { withLogin } from '@hoc/withLogin';
import { LNB } from '../../common/lnb';
import * as S from './UserGroupStyles';

export const UserGroup = withLogin(() => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <S.Container>
      <LNB
        menuItem={[
          {
            name: `설정`,
            icon: <OrganizationIcon width={20} height={20} color={theme.colors['ic-gray-main']} />,
            subMenu: [
              {
                name: '회사 정보 관리',
                icon: <DepthIcon width={16} height={16} color={theme.colors['ic-gray-main']} />,
                selectedIcon: <DepthIcon width={16} height={16} color={theme.colors['ic-purple-light']} />,
                to: 'companyInfo',
              },
              {
                name: `${t('Member_LNB_04')}`,
                icon: <DepthIcon width={16} height={16} color={theme.colors['ic-gray-main']} />,
                selectedIcon: <DepthIcon width={16} height={16} color={theme.colors['ic-purple-light']} />,
                to: 'groupMgmt',
              },
              {
                name: `${t('Member_LNB_05')}`,
                icon: <DepthIcon width={16} height={16} color={theme.colors['ic-gray-main']} />,
                selectedIcon: <DepthIcon width={16} height={16} color={theme.colors['ic-purple-light']} />,
                to: 'memberMgmt',
              },
              {
                name: `${t('Member_LNB_06')}`,
                icon: <DepthIcon width={16} height={16} color={theme.colors['ic-gray-main']} />,
                selectedIcon: <DepthIcon width={16} height={16} color={theme.colors['ic-purple-light']} />,
                to: 'managerMgmt',
              },
            ],
          },
        ]}
      />
      <Outlet />
    </S.Container>
  );
});
