import { CartProduct, SubscriptionType } from '@repositories/subscriptionRepository';
import { nanoid } from 'nanoid';
import { ProductPlanListModel } from './ProductPlanListModel';

export type Order = {
  subscriptionType: SubscriptionType;
  startDate: string;
  endDate: string;
  request: string;
};
export class CartProductModel {
  private _companyName: string;
  private _iconUrl?: string;
  private _thumbnailUrl?: string;
  private _subscriptionType?: SubscriptionType;
  private _startDate?: string;
  private _endDate?: string;
  private _request?: string;
  private _isModified: boolean;
  private _isChecked: boolean;
  private _productPlans: ProductPlanListModel;
  private _description: string | undefined;

  private _id: string;
  private _productId: string;
  private _productName: string;
  private _planId: string;
  private _planName: string;
  private _inquiry: string;

  constructor(dto: CartProduct) {
    this._companyName = dto.companyName;
    this._iconUrl = dto.iconUrl;
    this._thumbnailUrl = dto.thumbnailUrl;
    this._subscriptionType = dto.subscriptionType;
    this._startDate = dto.startDate;
    this._endDate = dto.endDate;
    this._request = dto.request;
    this._isModified = dto.isModified;
    this._isChecked = dto.isChecked;
    this._productPlans = dto.productPlans;
    this._description = dto.description;

    this._id = dto.id ?? nanoid();
    this._productId = dto.productId;
    this._productName = dto.productName;
    this._planId = dto.planId;
    this._planName = dto.planName;
    this._inquiry = dto.inquiry ?? '';
  }

  get id() {
    return this._id;
  }

  get productId() {
    return this._productId;
  }

  get productName() {
    return this._productName;
  }

  get planId() {
    return this._planId;
  }

  get planName() {
    return this._planName;
  }

  get inquiry(): string {
    return this._inquiry;
  }

  get description(): string | undefined {
    return this._description;
  }

  get productPlans() {
    return this._productPlans;
  }

  get isChecked(): boolean {
    return this._isChecked;
  }

  setCheck(flag: boolean) {
    this._isChecked = flag;
  }

  get isModified(): boolean {
    return this._isModified;
  }

  get iconUrl() {
    return this._iconUrl;
  }

  get thumbnailUrl() {
    return this._thumbnailUrl;
  }

  get companyName() {
    return this._companyName;
  }

  get subscriptionType() {
    return this._subscriptionType;
  }

  get startDate() {
    return this._startDate;
  }

  get endDate() {
    return this._endDate;
  }

  get request() {
    return this._request;
  }

  setOrder(order: Order) {
    this._subscriptionType = order.subscriptionType;
    this._startDate = order.startDate;
    this._endDate = order.endDate;
    this._request = order.request;
    this._isModified = true;
  }

  isSame(target: CartProductModel): boolean {
    return this.id === target.id;
  }
}
