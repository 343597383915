import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery, useTheme } from '@mui/material';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import spotSlide01 from '@images/introduction_spot_bg01.png';
import spotSlide02 from '@images/introduction_spot_bg02.png';
import logo from '@images/service_logo.png';
import { ArrowIcon } from '@icons/index';
import * as S from './IntroductionKoStyles';
import 'swiper/css';
import 'swiper/css/pagination';

export const IntroductionKo = () => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const theme = useTheme();
  const navigate = useNavigate();
  const matches = useMediaQuery(theme.breakpoints.down(1240));
  const handleIntroduceInquiryClick = () => {
    navigate('/tenant-request');
  };

  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const swiperSetting = {
    centeredSlides: true,
  };

  return (
    <S.Content>
      <div className="spot">
        <div className="inner">
          <h2>
            기업 맞춤 구독 서비스, <br className="mobile" /> <span>SPLITTER.</span>
          </h2>
          <p>
            번거로운 디지털 자산 관리를 간편하게, <br /> SaaS 구매와 관리를
            <br className="mobile" /> 스플리터에서 한 번에 해결하세요.
          </p>
          <button type="button" onClick={handleIntroduceInquiryClick}>
            바로 시작하기
            <ArrowIcon rotateNum={45} width={matches ? 20 : 24} height={matches ? 20 : 24} color={colors['ic-white']} />
          </button>
          <S.SpotCarousel {...settings}>
            <figure>
              <img src={spotSlide01} alt="구독현황 화면" width="100%" />
            </figure>
            <figure>
              <img src={spotSlide02} alt="스토어 화면" width="100%" />
            </figure>
          </S.SpotCarousel>
        </div>
      </div>
      <div className="section01">
        <div className="inner">
          <h3>주요 서비스</h3>
          <div className="service_info01">
            <strong className="info_title">
              <span>01</span>
              Digital Asset
              <br className="mobile" /> Total Management
            </strong>
            <S.SectionCarousel>
              <Swiper
                modules={[Pagination]}
                spaceBetween={matches ? 10 : 0}
                slidesPerView={1}
                pagination={{ clickable: true }}
                {...swiperSetting}
              >
                <SwiperSlide className="card01">
                  <strong>Digital Asset 통합 관리</strong>
                  <p>
                    중앙 집중식 관리 도구를 사용하여 디지털
                    <br className="mobile" /> 자산을 관리합니다. 거버닝 메시지를 전송
                    <br className="mobile" />
                    하고 구독 제품의 포트폴리오를 정기적으로
                    <br className="mobile" /> 검토합니다. 중복되거나 사용하지 않는
                    <br className="mobile" /> 구독을 발견하여 비용 절감에 도움을
                    <br className="mobile" />
                    드립니다.
                  </p>
                </SwiperSlide>
                <SwiperSlide className="card02">
                  <strong>외부(기존) IT 자산 통합 관리</strong>
                  <p>
                    기업에서 기존에 사용하고 있던 디지털
                    <br className="mobile" /> 자산을 직접 등록
                    <br className="pc" />
                    하여 관리할 수 있습니다.
                    <br className="mobile" /> 연동할 API를 직접 연결하고
                    <br className="pc" /> 사용량과
                    <br className="mobile" /> 지출 비용 등 원하는 데이터를 수집하고
                    <br className="mobile" /> 모니터링
                    <br className="pc" /> 할 수 있습니다.
                  </p>
                </SwiperSlide>
                <SwiperSlide className="card03">
                  <strong>개별 권한 관리</strong>
                  <p>
                    구성원/부서 단위로 라이선스의 사용량과
                    <br className="mobile" /> 사용자 활성화
                    <br className="pc" /> 상태를 실시간으로 체크할
                    <br className="mobile" /> 수 있습니다. 역할 단위로 라이선스 액세스
                    <br className="mobile" /> 권한을 부여하고 회수할 수 있습니다. 부서
                    <br className="mobile" /> 내<br className="pc" /> 라이선스의 활성화 유무와 갱신 기간
                    <br className="mobile" /> (활성 상태, 사용 중지) 등의 세부 사항을
                    <br className="mobile" /> 조회할 수 있습니다.
                  </p>
                </SwiperSlide>
                <SwiperSlide className="card04">
                  <strong>{t('Workflow_LNB_01')}</strong>
                  <p>
                    워크플로 자동화 도구를 통해 사용 중인
                    <br className="mobile" /> SaaS 제품의 관리
                    <br className="pc" /> 프로세스를 간소화하여
                    <br className="mobile" /> 관리 업무 효율을 극대화합니다.
                    <br className="pc" /> 사용하기
                    <br className="mobile" /> 쉽고 직관적인 UI로 임직원 온 보딩/오프
                    <br className="mobile" /> 보딩에
                    <br className="pc" /> 필요한 반복작업을 자동화할 수<br className="mobile" />
                    있습니다.
                  </p>
                </SwiperSlide>
                <SwiperSlide className="card05">
                  <strong>사용량 최적화 및 비용 예측</strong>
                  <p>
                    사용량과 지출 비용을 모니터링하여
                    <br className="mobile" /> 구독 현황에 대한
                    <br className="pc" /> 인사이트를 확보할 수<br className="mobile" /> 있습니다. 사용량 최적화, 비용
                    절감,
                    <br className="pc" /> 예산
                    <br className="mobile" /> 예측 기능으로 재무/조달 관리를 더욱
                    <br className="mobile" /> 체계적으로 할 수 있습니다.
                  </p>
                </SwiperSlide>
                <SwiperSlide className="card06">
                  <strong>{t('Acc_GNB_01')}</strong>
                  <p>
                    매월 라이선스 사용량에 비례한 이용 요금
                    <br className="mobile" />을 정산해 드립니다. 라이선스의 과금 내역
                    <br className="mobile" />을 실시간으로 파악할 수 있습니다.
                    <br className="pc" />
                    <br className="mobile" /> 부서 별로 라이선스 사용 수량과 임계치를
                    <br className="mobile" /> 설정할 수 있으며 이를 통해 지출 비용을
                    <br className="mobile" /> 추적할 수 있습니다.
                  </p>
                </SwiperSlide>
              </Swiper>
            </S.SectionCarousel>
          </div>
          <div className="service_info02">
            <strong className="info_title">
              <span>02</span>Open Market Place
            </strong>
            <S.SectionCarousel>
              <Swiper
                modules={[Pagination]}
                spaceBetween={matches ? 10 : 0}
                slidesPerView={1}
                pagination={{ clickable: true }}
                {...swiperSetting}
              >
                <SwiperSlide className="card0201">
                  <strong>다양한 Digital Asset 구독마켓</strong>
                  <p>
                    SaaS뿐만 아니라 소프트웨어, 하드웨어,
                    <br className="mobile" /> 네트워크 등<br className="pc" /> 1,000여개가 넘는 구독 제품을
                    <br className="mobile" /> 검색하고 구매할 수 있습니다.
                  </p>
                </SwiperSlide>
                <SwiperSlide className="card0202">
                  <strong>오픈형 구독마켓</strong>
                  <p>
                    SaaS에서 On-premise, 하드웨어에
                    <br className="mobile" /> 이르기까지 다양한
                    <br className="pc" /> 공급업체를 만나볼 수<br className="mobile" /> 있습니다. 각 분야에서 검증된
                    전문
                    <br className="pc" /> 컨설턴
                    <br className="mobile" />
                    트가 기업 맞춤형 서비스를 제공합니다.
                  </p>
                </SwiperSlide>
                <SwiperSlide className="card0203">
                  <strong>계약부터 도입까지 한번에 </strong>
                  <p>
                    SaaS 공급업체(ISV)와 합의하여 기업이
                    <br className="mobile" /> 원하는 최상의 계약 조건과 합리적인 가격
                    <br className="mobile" />
                    으로 구독 제품을 구매할 수 있습니다. 번<br className="mobile" />
                    거로운 요청 없이, 마켓 내 가격 시뮬레이
                    <br className="mobile" />션 기능으로 예산을 가늠하고 가장 적합한
                    <br className="mobile" /> 제품을 손쉽게 도입할 수 있습니다.
                  </p>
                </SwiperSlide>
                <SwiperSlide className="card0204">
                  <strong>임직원 온디맨드 스토어</strong>
                  <p>
                    기업이 필요로 하는 제품만 쏙쏙 골라 담아
                    <br className="mobile" /> 구독 스토어를
                    <br className="pc" /> 분양해 드립니다. 회사 규모,
                    <br className="mobile" /> 산업 군, 필요한 제품 카테고리,
                    <br className="pc" /> 요구 사항을
                    <br className="mobile" /> 수집해 동종 유사 업종에서 많이 찾는
                    <br className="mobile" /> 구독을
                    <br className="pc" /> 추천해 드립니다.
                  </p>
                </SwiperSlide>
              </Swiper>
            </S.SectionCarousel>
          </div>
          <div className="service_info03">
            <strong className="info_title">
              <span>03</span> Employ Subscription Store
            </strong>
            <S.SectionCarousel>
              <Swiper
                modules={[Pagination]}
                spaceBetween={matches ? 10 : 0}
                slidesPerView={1}
                pagination={{ clickable: true }}
                {...swiperSetting}
              >
                <SwiperSlide className="card0301">
                  <strong>One-Step 구독 서비스 신청</strong>
                  <p>
                    임직원은 테일러링 된 임직원 온디맨드
                    <br className="mobile" /> 스토어에서 구독 신청부터 라이선스 발급
                    <br className="mobile" />
                    까지 한 번에 진행할 수 있습니다. SaaS
                    <br className="mobile" /> 소프트웨어 별로 다양한 요금 체계를 선택
                    <br className="mobile" />
                    하고 신청할 수 있는 페이지를 제공합니다.
                  </p>
                </SwiperSlide>
                <SwiperSlide className="card0302">
                  <strong>Self Service 관리</strong>
                  <p>
                    마켓 내 기술 지원 서비스를 통해 구독
                    <br className="mobile" /> 제품의 A/S를 직접
                    <br className="pc" /> 신청할 수 있습니다.
                    <br className="mobile" /> 기업 관리자는 임직원의 요청 현황을
                    <br className="pc" />
                    <br className="mobile" /> 모니터링하고 자체 지원 서비스를 관리할
                    <br className="mobile" /> 수 있습니다.
                  </p>
                </SwiperSlide>
              </Swiper>
            </S.SectionCarousel>
          </div>
        </div>
      </div>
      <div className="banner">
        <p>
          Subscription Management Platform,<strong>SPLITTER</strong>
        </p>
      </div>
      <div className="section02">
        <div className="inner">
          <h3>특장점</h3>
          <div className="service_info01">
            <strong className="info_title">업무에 필요한 구독 제품이 1,000개 이상!</strong>
            <ul className="card-wrapper">
              <li className="card01">
                <strong className="blind">스토어</strong>
                <p className="blind">회사에서 사용하고 싶었던 디지털 자산을 전-부 구독해봐요!</p>
              </li>
              <li className="card02">
                <strong className="blind">구독신청</strong>
                <p className="blind">결재 기다릴 필요 없이 사용 신청하면 바로 라이선스 액세스 할 수 있어요!</p>
              </li>
            </ul>
          </div>
          <div className="service_info02">
            <strong className="info_title">꼭 알아야 하는 구독 정보가 한 곳에!</strong>
            <ul className={matches ? 'card-wrapper' : 'card-wrapper pc'}>
              <li className="card01">
                <strong className="blind">구독관리</strong>
                <p className="blind">여기 저기 흩어져있는 구독 정보를 한 곳에서 관리해요!</p>
              </li>
              <li className="card02">
                <strong className="blind">구독상세관리</strong>
                <p className="blind">구독 현황을 확인하고, 갱신할 구독을 잊지 마세요!</p>
              </li>
              <li className="card03">
                <strong className="blind">기존자산관리</strong>
                <p className="blind">기존에 구독하고 있던 SaaS도 등록하고 관리해요.</p>
              </li>
            </ul>
          </div>
          <div className="service_info03">
            <strong className={matches ? 'info_title' : 'blind'}>
              지출과 직결되는
              <br className="mobile" /> 사용량 관리는 철저하게!
            </strong>
            <ul className={matches ? 'card-wrapper' : 'blind'}>
              <li className="card01">
                <strong className="blind">기간별 조회</strong>
                <p className="blind">기간별 조회를 통해 사용하고 있는 라이선스의 사용량을 확인해보세요.</p>
              </li>
              <li className="card02">
                <strong className="blind">데이터 시각화</strong>
                <p className="blind">사용중인 제품의 누적 사용량 Top10을 한눈에 파악해보세요.</p>
              </li>
              <li className="card03">
                <strong className="blind">구독상세항목</strong>
                <p className="blind">구독의 상세 항목별로 자세하게 분석할 수 있어요.</p>
              </li>
              <li className="card04">
                <strong className="blind">사용자별 사용현황</strong>
                <p className="blind">구성원의 활성화 날짜를 확인하고 액세스 권한을 수정할 수 있어요.</p>
              </li>
            </ul>
          </div>
          <div className="service_info04">
            <strong className={matches ? 'info_title' : 'blind'}>
              구독 비용 아끼려면
              <br className="mobile" /> 정산서 확인은 필수!
            </strong>
            <ul className={matches ? 'card-wrapper' : 'blind'}>
              <li className="card01">
                <strong className="blind">{t('Acc_GNB_01')}</strong>
                <p className="blind">실시간 사용량 분석을 통해 지출 모니터링과 실시간 정산내역을 확인해보세요.</p>
              </li>
              <li className="card02">
                <strong className="blind">예산예측</strong>
                <p className="blind">사용량 인사이트로 다음 지출 금액을 예측해요!</p>
              </li>
            </ul>
          </div>
          <div className="service_info05">
            <strong className={matches ? 'info_title' : 'blind'}>
              프로세스 자동화로
              <br className="mobile" /> 스마트하게 일하자!
            </strong>
            <div className={matches ? 'card01' : 'blind'}>
              <strong className="blind">프로비저닝 / 디프로비저닝</strong>
              <p className="blind">ID 프로비저닝 / 디프로비저닝으로 임직원을 위한 다양한 워크플로를 만드세요!</p>
            </div>
          </div>
        </div>
      </div>
      <div className="banner02">
        <p>
          기업의 모든 구독 상품, <br />
          스플리터에서 관리하세요.
        </p>
        <button type="button" onClick={handleIntroduceInquiryClick}>
          {t('Subscrib_Detail_Overview_26')}
        </button>
      </div>
      <div className="footer">
        <div className="inner">
          <figure>
            <img
              src={logo}
              alt="메가존 디지털 로고"
              width={matches ? '155px' : '123px'}
              height={matches ? '48px' : '38px'}
            />
          </figure>
          {/* <ul>
              <li>
                <span>서비스이용약관</span>
              </li>
              <li>
                <span>개인 정보처리방침</span>
              </li>
            </ul> */}
          <p>Ⓒ 1998-2023 MEGAZONE.DIGITAL ALL RIGHTS RESERVED</p>
        </div>
      </div>
    </S.Content>
  );
};
