import { SoftwareCategoryListDTO } from '@repositories/softwareCategoryRepository';

export class SoftwareCategoryListModel {
  private _dto: SoftwareCategoryListDTO;

  constructor(dto: SoftwareCategoryListDTO) {
    this._dto = dto;
  }

  get id() {
    return this._dto.id;
  }

  get name() {
    return this._dto.name;
  }

  get iconUrl() {
    return this._dto.iconUrl;
  }

  get code() {
    return this._dto.code;
  }

  get description() {
    return this._dto.description;
  }

  get parentId() {
    return this._dto.parentId;
  }

  get softwareCount() {
    return this._dto.softwareCount;
  }
}
