import { ConnectorAction, InOutType } from '@repositories/subscriptionConnectionRepository/Types';
import { nanoid } from 'nanoid';
import i18next from 'i18next';
import { InputParameterModel } from './InputParameterModel';

export class ConnectorActionModel {
  private _description: string;
  private _inputParameters: InputParameterModel[];
  private _method: string;
  private _name: string;
  private _outputType: InOutType;
  private _selected: boolean;
  private _outputSchema: string;
  private _id: string;
  private _source: 'none' | string;

  constructor(dto: ConnectorAction) {
    this._description = dto.description || '';
    this._inputParameters = dto.inputParameters?.length
      ? dto.inputParameters.map(param => new InputParameterModel(param))
      : [];
    this._method = dto.method || '';
    this._name = dto.name || '';
    this._outputType = dto.outputType || 'STRING';
    this._selected = false;
    this._outputSchema = dto.outputSchema || '';
    this._id = nanoid();
    this._source = 'none';
  }

  get source() {
    return this._source;
  }

  setSource(sourceId: string) {
    this._source = sourceId;
  }

  get id() {
    return this._id;
  }

  get outputSchema() {
    return this._outputSchema;
  }

  get description() {
    return this._description;
  }

  get inputParameters() {
    return this._inputParameters;
  }

  set inputParameters(value: InputParameterModel[]) {
    this._inputParameters = value;
  }

  get method() {
    return this._method;
  }

  get name() {
    return this._name;
  }

  get outputType() {
    return this._outputType;
  }

  get configName() {
    if (this._selected) return '설정';
    return '';
  }

  get selected() {
    return this._selected;
  }

  selectAction() {
    this._selected = true;
  }
}
