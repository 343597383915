import dayjs from 'dayjs';
import i18next from 'i18next';
import type {
  SubscriptionUseRequest,
  SubscriptionUseRequestStatus,
} from '@repositories/subscriptionUseRequestRepository/Types';

export class SubscriptionUseRequestModel {
  private dto: SubscriptionUseRequest;

  private _createdDate: string;
  private _id: string;
  private _planName: string;
  private _productIconUrl: string;
  private _productName: string;
  private _seq: number;
  private _status: SubscriptionUseRequestStatus;
  private _updateDate: string;
  private _userAccount: string;
  private _userName: string;

  constructor(dto: SubscriptionUseRequest) {
    this.dto = dto;

    this._createdDate = dto?.createdDate || '';
    this._id = dto?.id || '';
    this._planName = dto?.planName || '';
    this._productIconUrl = dto?.productIconUrl || '';
    this._productName = dto?.productName || '';
    this._seq = dto?.seq || 0;
    this._status = dto?.status || 'CREATED';
    this._userAccount = dto?.userAccount || '';
    this._userName = dto?.userName || '';
    this._updateDate = dto?.updateDate || '';
  }

  get categoryId() {
    return this.dto.categoryId;
  }

  get updateDate(): string {
    return dayjs(this._updateDate).format('YYYY.MM.DD');
  }

  get createdDate(): DateTimeString {
    return dayjs(this._createdDate).format('YYYY.MM.DD');
  }

  get id(): string {
    return this._id;
  }

  get planName(): string {
    return this._planName;
  }

  get productIconUrl(): string {
    return this._productIconUrl;
  }

  get productName(): string {
    return this._productName;
  }

  get seq(): number {
    return this._seq;
  }

  get status(): SubscriptionUseRequestStatus {
    return this._status;
  }

  get statusStr(): '대기' | '보류' | '승인' | '반려' {
    switch (this.status) {
      case 'PENDING':
        return i18next.t('Subscrib_Detail_UseRequest_11');
      case 'APPROVED':
        return i18next.t('Subscrib_Detail_UseRequest_12');
      case 'REJECTED':
        return i18next.t('Subscrib_Detail_UseRequest_13');
      default:
        return i18next.t('Subscrib_Detail_UseRequest_10');
    }
  }

  get statusLabelColor(): 'yellow' | 'green' | 'gray' | 'pink' {
    switch (this.status) {
      case 'PENDING':
        return 'yellow';
      case 'APPROVED':
        return 'green';
      case 'REJECTED':
        return 'gray';
      default:
        return 'pink';
    }
  }

  get userAccount(): string {
    return this._userAccount;
  }

  get userName(): string {
    return this._userName;
  }
}
