import { useEffect, useState } from 'react';
import { Autocomplete, TextField, useTheme } from '@mui/material';
import { useStore } from '@stores/RootStore';
import { useGetUserGroups } from '@queryHooks/useUserGroup';
import type { UserGroupSimpleModel } from '@models/userGroupModels/UserGroupSimpleModel';

interface Props {
  onChange: (group: UserGroupSimpleModel) => void;
  value: string | undefined;
}

const GroupAutocomplete = ({ onChange, value }: Props) => {
  const theme = useTheme();
  const { authStore } = useStore();
  const [selectedGroup, setSelectedGroup] = useState<UserGroupSimpleModel | null>();
  const { data: userGroups } = useGetUserGroups(
    { tenantId: authStore.curTenant.id },
    {
      onSuccess: data => {
        const selectedGroup = data?.filter(userGroup => userGroup.userGroupId === value)[0];
        setSelectedGroup(selectedGroup as UserGroupSimpleModel);
      },
    },
  );

  useEffect(() => {
    setSelectedGroup(userGroups?.find(userGroup => userGroup.userGroupId === value) ?? null);
  }, [userGroups, value]);

  return (
    <Autocomplete
      className="autocomplete"
      value={selectedGroup || null}
      size="medium"
      options={userGroups || []}
      renderInput={params => (
        <TextField
          {...params}
          size="medium"
          sx={{
            '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderRadius: '2px 2px 0 0',
            },
            '& .MuiInputBase-input.Mui-disabled': {
              color: `${theme.colors['text-gray-light']}`,
            },
          }}
        />
      )}
      fullWidth
      isOptionEqualToValue={(op, val) => op.userGroupId === val.userGroupId}
      getOptionLabel={group => group.name || '-'}
      placeholder="직접 입력"
      onChange={(e, val) => {
        setSelectedGroup(val as UserGroupSimpleModel);
        onChange(val as UserGroupSimpleModel);
      }}
      ListboxProps={{
        style: {
          maxHeight: '328px',
        },
      }}
      componentsProps={{
        paper: {
          sx: {
            width: '100%',
            marginTop: 0,
            borderRadius: '0 0 2px 2px',
            boxShadow: '0',
            border: `1px solid ${theme.colors['border-gray-light']}`,
            borderWidth: '0 1px 1px 1px',
            overflow: 'hidden',
            '& .MuiAutocomplete-listbox': {
              height: '328px',
            },
            '& .MuiList-root': {
              border: 'none',
              padding: '4px 0',
              '& .MuiMenuItem-root': {
                margin: '0 5px',
              },
            },
          },
        },
      }}
      sx={{
        '&.MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input': {
          padding: '7.5px 4px 7.5px 12px',
        },
      }}
    />
  );
};

export default GroupAutocomplete;
