import type { SubscriptionAnnualBillingStatDTO } from '@repositories/subscriptionBillingStatRepository/Types';
import { CostOptimizationPointModel } from './CostOptimizationPointModel';

export class SubscriptionBillingStatModel {
  private dto: SubscriptionAnnualBillingStatDTO;
  private _optimizationPoints: CostOptimizationPointModel[];

  constructor(dto: SubscriptionAnnualBillingStatDTO) {
    this.dto = dto;
    this._optimizationPoints = dto.optimizationPoints?.map(dto => new CostOptimizationPointModel(dto)) ?? [];
  }

  get id() {
    return `${this.dto.month}_${this.dto.subscription.id}`;
  }

  get annualPaymentEstimate() {
    return this.dto.annualPaymentEstimate ?? 0;
  }

  get monthlyPaymentEstimate() {
    return Math.floor(this.annualPaymentEstimate / 12);
  }

  // 올해 남은 줄일 수 있는 금액
  get remainingAnnualPaymentReductionEstimate() {
    return this.dto.remainingAnnualPaymentReductionEstimate ?? 0;
  }

  get remainingMonthlyPaymentReductionEstimate() {
    return Math.floor(this.remainingAnnualPaymentReductionEstimate / 12);
  }

  get subscriptionId() {
    return this.dto.subscription.id;
  }

  get subscriptionName() {
    return this.dto.subscription.name;
  }

  get productIcon() {
    return this.dto.subscription.product?.iconUrl;
  }

  get optimizationPoints() {
    return this._optimizationPoints;
  }

  get userOptimizationPoints() {
    return this._optimizationPoints.filter(model => model.isUserOptimzationPoint);
  }

  get planOptimizationPoints() {
    return this._optimizationPoints.filter(model => model.isPlanOptimizationPoint);
  }

  get updatedDate() {
    return this.dto.updatedDate;
  }
}
