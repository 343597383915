import { useEffect, useState } from 'react';
import { useForm, SubmitErrorHandler, SubmitHandler, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Modal, TextField, useTheme } from '@mui/material';
import { observer } from 'mobx-react';
import { useStore } from '@stores/RootStore';
import { BulletIcon, ErrorIcon, XIcon } from '@icons/index';
import { useGetUserList } from '@queryHooks/index';
import { useAddTenantMembers } from '@queryHooks/useTenant';

import * as S from './ExternalManagerAddFromModalStyle';
import { AddRequestFormData, useAddRequestForm } from './validationScheme';

interface Props {
  isClose: () => void;
  isOpen: boolean;
  adminUsername: string[];
}

export const ExternalManagerAddFromModal = observer(({ isOpen, isClose, adminUsername }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [isNextInfo, setIsNextInfo] = useState(false);
  const [isManager, setIsManager] = useState(false);
  const [isNotUser, setIsNotUser] = useState(false);
  const {
    handleSubmit,
    formState: { errors },
    getValues,
    watch,
    reset,
    control,
  } = useAddRequestForm();

  const { authStore } = useStore();
  const { data: user, isSuccess } = useGetUserList({ username: getValues('email') });
  const { mutate: addTenantMember } = useAddTenantMembers();

  const handleForm: {
    valid: SubmitHandler<AddRequestFormData>;
    invalid: SubmitErrorHandler<AddRequestFormData>;
  } = {
    valid: data => {
      console.debug('Valid', data);
    },
    invalid: (/* data */) => {
      // console.debug('Invalid', data);
    },
  };

  const handleClickNextBtn = () => {
    const checkUsername = adminUsername.find(username => username === getValues('email'));
    if (checkUsername !== getValues('email')) {
      if (isSuccess && user.content.length > 0 && user.content[0].username) {
        setIsNextInfo(true);
      } else {
        setIsNotUser(true);
      }
    } else {
      setIsManager(true);
    }
  };
  const handleClickAdminAddBtn = () => {
    if (isSuccess && user.content.length > 0 && user.content[0].username) {
      addTenantMember(
        [{ memberRole: 'EXTERNAL_ADMIN', tenantId: authStore.managerTenantInfo.id, userId: user.content[0].id }],
        {
          onSuccess: isClose,
        },
      );
    }
  };

  useEffect(() => {
    if (!isOpen) {
      setIsNextInfo(false);
      reset();
    }
  }, [isOpen, reset]);

  return (
    <Modal open={isOpen}>
      <S.ModalWrapper onSubmit={handleSubmit(handleForm.valid)}>
        <S.Header>
          <S.HeaderTitle>{t('Member_Manager_05')}</S.HeaderTitle>
          <S.IconButton onClick={isClose}>
            <XIcon color={theme.colors['ic-gray-main']} width={24} height={24} />
          </S.IconButton>
        </S.Header>
        {!isNextInfo ? (
          <S.Body>
            <S.subTitle>
              {t('Member_User_14')}
              <span>*</span>
            </S.subTitle>
            <Controller
              name="email"
              control={control}
              rules={{ required: t('Account_Validation_Check_02') }}
              render={({ field: { onChange, value } }) => (
                <TextField
                  value={value}
                  onChange={onChange}
                  size="medium"
                  fullWidth
                  error={!!errors.email || isManager || isNotUser}
                  helperText={
                    isManager ? t('Member_Manager_16') : isNotUser ? t('Member_Manager_15') : errors.email?.message
                  }
                  placeholder="example@gamil.com"
                />
              )}
            />

            <S.InfoWrapper>
              <S.InfoItem>
                <BulletIcon width={6} height={6} color={theme.colors['ic-gray-lighter']} />
                <span>{t('Member_Manager_09')}</span>
              </S.InfoItem>
              <S.InfoItem>
                <BulletIcon width={6} height={6} color={theme.colors['ic-gray-lighter']} />
                <span>{t('Member_Manager_10')}</span>
              </S.InfoItem>
            </S.InfoWrapper>
          </S.Body>
        ) : (
          <S.Body $isNextInfoModal={isNextInfo}>
            <S.IconWrapper>
              <ErrorIcon width={32} height={32} color={theme.colors['ic-purple-light']} />
            </S.IconWrapper>
            <p>{t('Member_Manager_13')}</p>
            <S.EmailWrapper>
              <span>{getValues('email')}</span>
            </S.EmailWrapper>
          </S.Body>
        )}
        <S.Footer>
          <S.StyledButton
            disabled={!!errors.email || watch().email === ''}
            variant="contain"
            size="extraLarge"
            type={isNextInfo ? 'submit' : 'button'}
            onClick={isNextInfo ? handleClickAdminAddBtn : handleClickNextBtn}
          >
            {t('Member_Manager_11')}
          </S.StyledButton>
          <S.StyledButton variant="outline" size="extraLarge" onClick={isClose}>
            {t('Subscrib_Detail_BuyRequest_13')}
          </S.StyledButton>
        </S.Footer>
      </S.ModalWrapper>
    </Modal>
  );
});
