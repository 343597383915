import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Divider, TextField } from '@mui/material';
import { Dialog } from '@components/Dialog';
import { Button } from '@components/index';
import { fonts } from '@theme/fontsCustomer';
import IllustExit from '@icons/IllustExit';
import { IllustGroup } from '@icons/IllustGroup';
import IllustInactive from '@icons/IllustInactive';
import { IllustNew } from '@icons/IllustNew';
import { IllustNomember } from '@icons/IllustNomember';
import { IllustOut } from '@icons/IllustOut';
import { IllustSchedule } from '@icons/IllustSchedule';
import type { TriggerInputType } from '@repositories/workflowRepository/Types';
import { useGetWorkflowBaseTrigger } from '@queryHooks/useWorkflowBaseTrigger';
import type { WorkflowBaseTriggerFieldDTOModel } from '@models/workflow/WorkflowBaseTriggerFieldDTOModel';
import type { WorkflowModel } from '@models/workflow/WorkflowModel';

type Props = {
  workflow: WorkflowModel;
  open: boolean;
  onClose: () => void;
  onSubmit: (formData: TriggerInputType) => void;
};

export const WorkflowExecModal = ({ workflow, open, onClose, onSubmit }: Props) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState<TriggerInputType>({});

  const { data: baseTrigger } = useGetWorkflowBaseTrigger(workflow.trigger.baseTrigger.id || '');

  // baseTrigger가 로드되면 defaultValue들을 formData에 설정
  useEffect(() => {
    if (baseTrigger) {
      const initialFormData: TriggerInputType = {};
      baseTrigger.fields.forEach(field => {
        if (field.defaultValue !== undefined && field.defaultValue !== null) {
          initialFormData[field.name] = field.defaultValue;
        }
      });
      setFormData(initialFormData);
    }
  }, [baseTrigger]);

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = () => {
    onClose();
    onSubmit(formData);
  };

  const renderInputField = (field: WorkflowBaseTriggerFieldDTOModel) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      let value: string | number = e.target.value;

      // 숫자만 입력 가능하도록 검증
      if (field.dataType === 'NUMBER') {
        // 소수점 허용
        if (!/^\d*\.?\d*$/.test(value)) return;
        value = Number(value);
      } else if (field.dataType === 'INTEGER') {
        // 정수만 허용
        if (!/^\d*$/.test(value)) return;
        value = Number(value);
      }

      setFormData(prev => ({ ...prev, [field.name]: value }));
    };

    return (
      <Element>
        <NameText>{field.displayName}</NameText>
        <TextField
          type={field.dataType === 'NUMBER' || field.dataType === 'INTEGER' ? 'number' : 'text'}
          onChange={handleChange}
          value={formData[field.name] || ''}
          inputProps={{
            step: field.dataType === 'NUMBER' ? 'any' : 1,
          }}
        />
      </Element>
    );
  };

  return (
    <Dialog open={open} onClose={onClose} title="워크플로우 실행" size="medium">
      <form onSubmit={handleSubmit}>
        <Container>
          <TriggerElement>
            <TriggerIcon>
              {baseTrigger?.eventName === 'TENANT_MEMBER_JOINED' && <IllustNew />}
              {baseTrigger?.eventName === 'TENANT_MEMBER_LEFT' && <IllustExit />}
              {baseTrigger?.eventName === 'MEMBER_GROUP_JOINED' && <IllustGroup />}
              {baseTrigger?.eventName === 'MEMBER_GROUP_LEFT' && <IllustOut />}
              {baseTrigger?.eventName === 'SCHEDULED_EVENT_TRIGGERED' && <IllustSchedule />}
              {baseTrigger?.eventName === 'INACTIVE_USER_DETECTED' && <IllustInactive />}
              {baseTrigger?.eventName === 'EXTERNAL_USER_DETECTED' && <IllustNomember />}
            </TriggerIcon>
            <TriggerInfo>
              <TriggerName>{baseTrigger?.name}</TriggerName>
              <TriggerDescription>{baseTrigger?.description}</TriggerDescription>
            </TriggerInfo>
          </TriggerElement>
          <Divider />
          <InputContainer>
            {baseTrigger &&
              baseTrigger.fields.map(field => {
                return renderInputField(field);
              })}
          </InputContainer>
          <ButtonPosition>
            <ButtonBox>
              <Button
                type="submit"
                variant="contain"
                size="extraLarge"
                paddingHorizontal={69.5}
                style={{ maxWidth: '168px', whiteSpace: 'pre' }}
              >
                {t('Additional_Commitment_08')}
              </Button>
              <Button
                variant="outline"
                color="gray"
                size="extraLarge"
                paddingHorizontal={69.5}
                onClick={handleClose}
                style={{ maxWidth: '168px', whiteSpace: 'pre' }}
              >
                {t('Subscrib_Detail_BuyRequest_13')}
              </Button>
            </ButtonBox>
          </ButtonPosition>
        </Container>
      </form>
    </Dialog>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
`;

const TriggerElement = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 10px 0px;
  align-items: center;
  gap: 16px;
  background-color: ${props => props.theme.colors['bg-white']};
  border-radius: 5px;

  :hover {
    border: 1px solid ${({ theme: { colors } }) => colors['border-purple-light']};
    background-color: ${({ theme: { colors } }) => colors['state-white-purple-hover']};
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.02);
  }
`;

const TriggerIcon = styled.span`
  display: flex;
  width: 44px;
  height: 44px;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ theme: { colors } }) => colors['border-gray-lighter']};
  border-radius: 4px;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.02);
`;

const TriggerInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const TriggerName = styled.strong`
  ${fonts.Headline8};
  color: ${({ theme: { colors } }) => colors['text-gray-main']};
`;

const TriggerDescription = styled.p`
  ${fonts.Body2};
  color: ${({ theme: { colors } }) => colors['text-gray-sub-dark']};
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const Element = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
`;

const NameText = styled.span`
  ${fonts.Headline8}
  margin-bottom: 6px;
`;

const ButtonPosition = styled.div`
  display: flex;
  justify-content: center;
`;

const ButtonBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 8px;
`;
