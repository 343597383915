import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import type { Provider } from '@repositories/softwareProviderRepository/Types';

export class SoftwareProviderModel {
  private _dto?: Provider;

  private _createdDate?: Dayjs;
  private _updatedDate?: Dayjs;

  constructor(dto?: Provider) {
    this._dto = dto;

    this._createdDate = dto?.createdDate ? dayjs(dto?.createdDate) : undefined;
    this._updatedDate = dto?.updatedDate ? dayjs(dto?.updatedDate) : undefined;
  }

  get id() {
    return this._dto?.id;
  }

  get name() {
    return this._dto?.name;
  }

  get email() {
    return this._dto?.email;
  }

  get phone() {
    return this._dto?.phone;
  }

  get url() {
    return this._dto?.url;
  }

  get icon() {
    return this._dto?.icon;
  }

  get address() {
    return this._dto?.address;
  }

  get businessRegistrationNumber() {
    return this._dto?.businessRegistrationNumber;
  }

  get representative() {
    return this._dto?.representative;
  }

  get createdAt() {
    return this._createdDate?.format('YYYY-MM-DD HH:mm');
  }

  get updatedAt() {
    return this._updatedDate?.format('YYYY-MM-DD HH:mm');
  }
}
