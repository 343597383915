import { makeAutoObservable } from 'mobx';

type Vertical = 'bottom' | 'top';
type Horizontal = 'center' | 'right' | 'left';
type Action = { text: string; onClick: () => void };

type OpenParams = {
  message: string;
  vertical?: Vertical;
  horizontal?: Horizontal;
  action?: Action;
  hideAction?: boolean;
};

export class ToastStore {
  private _visible: boolean;
  private _message: string;
  private _vertical: Vertical;
  private _horizontal: Horizontal;
  private _action?: Action;
  private _hideAction?: boolean;

  constructor() {
    this._visible = false;
    this._message = '';
    this._vertical = 'bottom';
    this._horizontal = 'center';
    this._action = undefined;
    this._hideAction = true;

    makeAutoObservable(this);
  }

  get visible(): boolean {
    return this._visible;
  }

  get message(): string {
    return this._message;
  }

  open = (
    message: string | OpenParams,
    vertical?: Vertical,
    horizontal?: Horizontal,
    action?: Action,
    hideAction?: boolean,
  ) => {
    if (typeof message === 'string') {
      this._visible = true;
      this._message = message;
      if (vertical) this._vertical = vertical;
      if (horizontal) this._horizontal = horizontal;
      if (action) this._action = action;
      if (hideAction) this._hideAction = hideAction;
    } else {
      this.open(message.message, message.vertical, message.horizontal, message.action, message.hideAction);
    }
  };

  close = () => {
    this._message = '';
    this.clear();
  };

  private clear = () => {
    this._visible = false;
  };

  get vertical(): Vertical {
    return this._vertical;
  }

  get horizontal(): Horizontal {
    return this._horizontal;
  }

  get hideAction() {
    return !!this._hideAction;
  }

  get action() {
    return this._action;
  }
}
