import { makeAutoObservable } from 'mobx';
import { type SoftwareCategoryListModel } from '@models/softwareModels/SoftwareCategoryListModel';

class CategoryStore {
  private _categories: Map<string, SoftwareCategoryListModel>;

  constructor() {
    this._categories = new Map<string, SoftwareCategoryListModel>();
    makeAutoObservable<CategoryStore>(this);
  }

  get categories(): SoftwareCategoryListModel[] {
    return Array.from(this._categories.values());
  }

  get categoryMap() {
    return this._categories;
  }

  setCategories(categories: SoftwareCategoryListModel[]) {
    categories.forEach(category => {
      this._categories.set(category.id, category);
    });
  }

  getParentCategory(categoryId: string) {
    const curCategory = this._categories.get(categoryId);
    if (curCategory?.parentId) {
      return this._categories.get(curCategory.parentId);
    }
    return curCategory;
  }

  findRootCategory(categoryId: string): SoftwareCategoryListModel | undefined {
    const curCategory = this._categories.get(categoryId);
    if (curCategory?.parentId) {
      return this.findRootCategory(curCategory.parentId);
    }
    return curCategory;
  }

  getChildCategories(categoryId: string, includeEmptyItem = false) {
    const curCategory = this._categories.get(categoryId);
    if (curCategory) {
      const childCategories = this.categories.filter(({ parentId }) => parentId === curCategory.id);
      return includeEmptyItem ? childCategories : childCategories.filter(({ softwareCount }) => softwareCount > 0);
    }
    return [];
  }

  xGetChildCategoryIds(categoryId: string, includeEmptyItem = false) {
    let result: string[] = [];
    const curCategory = this._categories.get(categoryId);
    if (curCategory) {
      result = [categoryId];
      const childCategories = this.categories
        .filter(({ parentId, softwareCount }) => {
          return includeEmptyItem ? parentId === curCategory.id : parentId === curCategory.id && softwareCount > 0;
        })
        .map(({ id }) => id);
      childCategories.forEach(cid => {
        result = result.concat(this.xGetChildCategoryIds(cid, includeEmptyItem));
      });
    }
    return result;
  }
}

const categoryStore = new CategoryStore();

export default categoryStore;
