import { Outlet, useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import { observer } from 'mobx-react';
import { Loader } from '@components/loader';
import { GoBackHeader } from '@components/navigation/GoBackHeader';
import { RoutingTabs } from '@components/tab';
import { useGetWorkflow } from '@queryHooks/useWorkflow';
import type { WorkflowModel } from '@models/workflow/WorkflowModel';
import { WorkflowDetailHeader } from './WorkflowDetailHeader';

export type WorkflowOutletContext = {
  workflow: WorkflowModel;
};

export const WorkflowDetail = observer(() => {
  const { workflowId } = useParams();
  const { data: workflowDetail, isSuccess } = useGetWorkflow(workflowId ?? '');

  if (!isSuccess) return <Loader />;

  return (
    <Container>
      <GoBackHeader title="워크플로우 목록" />
      <Header>{workflowDetail && <WorkflowDetailHeader />}</Header>
      <RoutingTabs
        variant="contain"
        tabList={[
          { name: '개요', path: '', replace: true },
          {
            name: '실행',
            path: 'executions',
            replace: true,
          },
        ]}
      />
      {isSuccess && <Outlet context={{ workflow: workflowDetail }} />}
    </Container>
  );
});

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 23px 32px 0px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 36px;
  padding: 0px 28px;
  margin-bottom: 10px;
`;
