import React, { ChangeEvent, HTMLAttributes, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { TableComponents, TableVirtuoso } from 'react-virtuoso';
import styled from '@emotion/styled';
import {
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  css,
  useTheme,
} from '@mui/material';
import * as FileSaver from 'file-saver';
import { nanoid } from 'nanoid';
import * as XLSX from 'xlsx';
import { Button, EmptyRow } from '@components/index';
import { useStore } from '@stores/RootStore';
import { fonts } from '@theme/fontsCustomer';
import { BulletIcon } from '@icons/BulletIcon';
import { ChevronIcon } from '@icons/ChevronIcon';
import { DownloadIcon } from '@icons/DownloadIcon';
import { CheckboxOffNoIcon, CheckedBgIcon, ExcelIcon, RadioIcon, RadioOffIcon } from '@icons/index';
import { UserGroupListCreateDTO } from '@repositories/userGroupsRepository/Types';
import { useAddBatchUsergroups, useGetUserGroups } from '@queryHooks/useUserGroup';
import { transientOptions } from '@utils/CommonUtil';

type Sheet = {
  code: string;
  name: string;
  parentCode?: string;
  checked: boolean;
  id: string;
};

type ExcelSheet = {
  '그룹 코드': string;
  '그룹 이름': string;
  '상위 그룹 코드'?: string;
};

type ValidateRow = {
  code: Error;
  name: Error;
  parent: Error;
};

type Error = {
  err: boolean;
  message: string;
};

type TableContext = {
  emptyDataMessage: string;
};

type BatchType = 'update' | 'replace';

const StyledTableRow = styled(TableRow)`
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.colors['state-white-hover']};
  }
`;

const StyledTable = styled(Table)`
  border-radius: 5px;
  box-shadow: 0 0 0 1px ${({ theme }) => theme.colors['border-gray-lighter']};
`;

const tableComponents: TableComponents<Sheet, TableContext> = {
  Scroller: React.forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>((props, ref) => (
    <TableWrapper {...props} ref={ref} />
  )),
  Table: StyledTable,
  TableHead,
  TableRow: ({ children, ...props }) => <StyledTableRow {...props}>{children}</StyledTableRow>,
  TableBody,
  EmptyPlaceholder: ({ context }) =>
    context ? (
      <TableBody>
        <EmptyRow colSpan={4} title={context.emptyDataMessage} />
      </TableBody>
    ) : null,
};

export const UserGroupBatchRegister = () => {
  const { t } = useTranslation();
  const {
    uiStore: { toastStore, alertStore },
  } = useStore();

  const theme = useTheme();
  const params = useParams();
  const tenantId = params.tenantId as string;

  const fileRef = useRef<HTMLInputElement | null>(null);

  const [batchType, setBatchType] = useState<'update' | 'replace' | undefined>(undefined);
  const [groupsFromSheet, setGroups] = useState<Sheet[]>([]);
  const [uploadFile, setUploadFile] = useState<FileList | null>(null);
  const [textFieldFocused, setTextFieldFocused] = useState(false);
  const [scrollTarget, setScrollTarget] = useState<HTMLElement | null>(null);
  const [showErrorRowsOnly, setShowErrorRowsOnly] = useState(false);
  const [errGroups, setErrGroups] = useState<Sheet[]>([]);

  const totalRows = groupsFromSheet.length;
  const checkedCount = showErrorRowsOnly
    ? errGroups.filter(group => group.checked).length
    : groupsFromSheet.filter(group => group.checked).length;

  const { mutate: batchUsergroups } = useAddBatchUsergroups();
  const { data: groups } = useGetUserGroups({ tenantId });

  const validateGroupRow = (target: Sheet): ValidateRow => {
    const error: ValidateRow = {
      code: {
        err: false,
        message: '',
      },
      name: {
        err: false,
        message: '',
      },
      parent: {
        err: false,
        message: '',
      },
    };

    // 코드 공백
    if (!target.code) {
      error.code.err = true;
      error.code.message = `${t('Member_Group_Delete_05')}`;
    }

    // 테이블 내 중복 코드
    const dupleOfTable = groupsFromSheet.filter(group => group.code === target.code);
    // 상위 그룹 코드와의 중복체크
    const dupleCodeData = groups?.find(code => code.userGroupCode === target.code);
    if (dupleCodeData && batchType === 'update') {
      error.code.err = true;
      error.code.message = t('Member_Group_Move_10');
    }
    if (dupleOfTable.length > 1) {
      error.code.err = true;
      error.code.message = t('Member_User_Bulk_Create_10');
    }

    // 이름 공백
    if (!target.name?.length) {
      error.name.err = true;
      error.name.message = `${t('Member_Group_Delete_05')}`;
    }
    // 상위 그룹 코드 후보가 없음
    const codeInTable = groupsFromSheet.find(group => group.code === target.parentCode);
    const codeInServer = groups?.find(group => group.userGroupCode === target.parentCode);
    if (!codeInTable && !codeInServer) {
      error.parent.err = true;
      error.parent.message = t('Member_Group_Bulk_Create_23');
    }
    if (!target.parentCode) {
      error.parent.err = false;
      error.parent.message = '';
    }

    return error;
  };

  const errorCount = groupsFromSheet.filter(group => {
    const err = validateGroupRow(group);
    return err.code.err || err.name.err || err.parent.err;
  }).length;

  const handleFileUpload = {
    clickFileUploadBtn: () => {
      fileRef.current?.click();
    },
    changeFileUpload: (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files?.length) {
        setUploadFile(e.target.files);
        const changeCheckedOrigin = groupsFromSheet.map(v => {
          return { ...v, checked: false };
        });
        setGroups(changeCheckedOrigin);

        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(e.target.files[0]);
        fileReader.onload = (e: ProgressEvent<FileReader>) => {
          if (e.target) {
            const bufferArray = e.target.result;
            const fileInfo = XLSX.read(bufferArray, {
              type: 'buffer',
              cellText: false,
              cellDates: true,
            });
            const sheetName = fileInfo.SheetNames[0];
            const rawData = fileInfo.Sheets[sheetName];
            const groupsOfExcel: ExcelSheet[] = XLSX.utils.sheet_to_json(rawData);

            if (groupsOfExcel.length) {
              const nonEmptyRows = groupsOfExcel.filter(group => {
                // 행에 데이터가 1개라도 존재하는지 확인하여 빈 행을 제거합니다.
                return Object.values(group).some(value => value !== undefined && value !== null && value !== '');
              });

              const groups: Sheet[] = nonEmptyRows.map(group => {
                return {
                  code: group['그룹 코드'],
                  name: group['그룹 이름'],
                  parentCode: group['상위 그룹 코드'],
                  checked: false,
                  id: nanoid(),
                };
              });

              const errorRows = groups.filter(group => {
                const err = validateGroupRow(group);
                return err.code.err || err.name.err || err.parent.err;
              });

              setGroups(groups);
              setErrGroups(errorRows);
            }
          }
        };
        setUploadFile(e.target.files);
      }
    },
  };

  const handleExcelDownload = () => {
    const ws = XLSX.utils.aoa_to_sheet([['그룹 코드', '그룹 이름', '상위 그룹 코드']]);

    const workbook = {
      Sheets: { data: ws },
      SheetNames: ['data'],
    };

    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const excelFile = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
    });

    FileSaver.saveAs(excelFile, '그룹일괄등록양식.xlsx');
  };

  const handleTable = {
    addRow: () => {
      const newRow = { checked: false, code: '', name: '', parentCode: '', id: nanoid() };
      const origin = [...groupsFromSheet];
      origin.unshift(newRow);
      setGroups(origin);
      const errorOrigin = [...errGroups];
      errorOrigin.unshift(newRow);
      setErrGroups(errorOrigin);
    },

    deleteRow: () => {
      const origin = [...groupsFromSheet];
      const errOrigin = [...errGroups];
      setGroups(origin.filter(v => !v.checked));
      setErrGroups(errOrigin.filter(v => !v.checked));

      toastStore.open(
        t('Member_Group_Bulk_Create_24', {
          number: showErrorRowsOnly
            ? origin.length - errOrigin.filter(v => !v.checked).length
            : origin.length - origin.filter(v => !v.checked).length,
        }),
      );
    },
    changeCheckbox: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, checked: boolean, id: string) => {
      const updatedGroups = groupsFromSheet.map(group => {
        if (group.id === id) {
          return { ...group, checked };
        }
        return group;
      });

      if (showErrorRowsOnly) {
        setErrGroups(prev => {
          const target = prev.find(row => row.id === id);
          if (target) {
            target.checked = checked;
          }
          return prev;
        });
      }
      setGroups(updatedGroups);
    },

    changeAllCheckbox: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, checked: boolean) => {
      const changeCheckedOrigin = groupsFromSheet.map(v => {
        return { ...v, checked };
      });
      if (showErrorRowsOnly) {
        const updatedErrorGrpups = changeCheckedOrigin.filter(group => {
          const err = validateGroupRow(group);
          return err.code.err || err.name.err || err.parent.err;
        });
        setErrGroups(updatedErrorGrpups);
        console.log('updatedErrorGrpups', updatedErrorGrpups);
      }
      setGroups(changeCheckedOrigin);
    },

    changeGroupRow: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, id: string) => {
      const origin = [...groupsFromSheet];
      const idx = origin.findIndex(group => group.id === id);
      const target: any = origin[idx];
      target[e.currentTarget.name] = e.currentTarget.value;
      origin.splice(idx, 1, target);
      setGroups(origin);
    },
  };

  const handleShowErrorRowsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    setShowErrorRowsOnly(target.checked);

    if (target.checked) {
      const filteredErrGroups = groupsFromSheet.filter(group => {
        const err = validateGroupRow(group);
        return err.code.err || err.name.err || err.parent.err;
      });
      setErrGroups(filteredErrGroups);
    } else {
      setGroups(
        groupsFromSheet.map(group => {
          const errorUser = validateGroupRow(group);
          if (errorUser.code.err || errorUser.name.err || errorUser.parent.err) {
            return {
              ...group,
            };
          }
          return {
            ...group,
            checked: false,
          };
        }),
      );
    }
  };

  const handleClickRegisterBtn = () => {
    if (errGroups.length) {
      alertStore.open({
        title: `${t('Member_Group_Bulk_Create_25')}`,
        message: `${t('Member_Group_Bulk_Create_26')}`,
        useCancel: false,
        confirmName: `${t('Member_User_24')}`,
      });
    } else {
      alertStore.open({
        title: `${t('Member_Group_Bulk_Create_01')}`,
        message: `${t('Member_Group_Bulk_Create_27')}`,
        useCancel: true,
        confirmName: `${t('Member_User_24')}`,
        onConfirm: () => {
          const rqData: UserGroupListCreateDTO = {
            groups: groupsFromSheet?.map(group => {
              return {
                name: group.name,
                userGroupCode: group.code,
                parentUserGroupCode: group.parentCode,
              };
            }),
            resetGroups: batchType === 'replace',
            tenantId,
          };

          batchUsergroups(
            { rqData },
            {
              onSuccess: () => {
                setGroups([]);
                setUploadFile(null);
                setBatchType(undefined);
              },
            },
          );
        },
      });
    }
  };

  const handleChangeRadio = (batchType: BatchType) => {
    if (uploadFile) {
      alertStore.open({
        title: '그룹 일괄 등록 방식 변경',
        message: `${t('Member_User_Bulk_Create_13')}`,
        useCancel: true,
        confirmName: `${t('Member_User_24')}`,
        onConfirm: () => {
          setUploadFile(null);
          setGroups([]);
          setBatchType(batchType);
          setErrGroups([]);
        },
      });
    } else {
      setBatchType(batchType);
    }
  };

  const handleTextFieldFocus = () => {
    setTextFieldFocused(true);
  };

  const handleTextFieldBlur = () => {
    setTextFieldFocused(false);
  };

  const defaultUser: Sheet = {
    code: '',
    name: '',
    parentCode: '',
    checked: false,
    id: nanoid(),
  };

  const allChecked =
    (showErrorRowsOnly &&
      errGroups.length > 0 &&
      errGroups.length === errGroups.filter(group => group.checked).length) ||
    (!showErrorRowsOnly &&
      groupsFromSheet.length > 0 &&
      groupsFromSheet.length === groupsFromSheet.filter(group => group.checked).length);

  const renderFixedHeaderContent = () => (
    <TableRow>
      <CheckboxCell width={40} align="center">
        <Checkbox
          checked={allChecked}
          onChange={handleTable.changeAllCheckbox}
          icon={<CheckboxOffNoIcon width={16} height={16} color={theme.colors['border-gray-light']} />}
          checkedIcon={
            <CheckedBgIcon
              width={16}
              height={16}
              bgColor={theme.colors['bg-purple']}
              color={theme.colors['bg-white']}
            />
          }
        />
      </CheckboxCell>
      <HeaderCell width={200}>
        {t('Member_Group_Bulk_Create_17')}
        <span>*</span>
      </HeaderCell>
      <HeaderCell width={311}>
        {t('Member_Group_Bulk_Create_18')}
        <span>*</span>
      </HeaderCell>
      <HeaderCell width={200}>{t('Workflow_Create_62')}</HeaderCell>
    </TableRow>
  );

  const renderItemContent = (index: number, group: Sheet = defaultUser) => {
    const error = validateGroupRow(group);

    return (
      <>
        <CheckboxCell>
          <Checkbox
            checked={group.checked}
            onChange={(e, checked) => handleTable.changeCheckbox(e, checked, group.id)}
            icon={<CheckboxOffNoIcon width={16} height={16} color={theme.colors['border-gray-light']} />}
            checkedIcon={
              <CheckedBgIcon
                width={16}
                height={16}
                bgColor={theme.colors['bg-purple']}
                color={theme.colors['bg-white']}
              />
            }
          />
        </CheckboxCell>
        <StyledTableCell>
          <Tooltip
            title={error.code.message}
            placement="bottom-start"
            arrow
            PopperProps={{
              sx: {
                '& .MuiTooltip-tooltip': {
                  maxWidth: 'none',
                },
                '& .MuiTooltip-arrow': {
                  transform: 'translate(20px, 0) !important',
                },
              },
            }}
          >
            <Textfield
              name="code"
              fullWidth
              value={group.code}
              type="text"
              placeholder={t('Member_Group_Bulk_Create_17')}
              $err={error.code.err}
              onChange={e => handleTable.changeGroupRow(e, group.id)}
              onFocus={handleTextFieldFocus}
              onBlur={handleTextFieldBlur}
            />
          </Tooltip>
        </StyledTableCell>
        <StyledTableCell>
          <Tooltip
            title={error.name.message}
            placement="bottom-start"
            arrow
            PopperProps={{
              sx: {
                '& .MuiTooltip-tooltip': {
                  maxWidth: 'none',
                },
                '& .MuiTooltip-arrow': {
                  transform: 'translate(20px, 0) !important',
                },
              },
            }}
          >
            <Textfield
              name="name"
              fullWidth
              value={group.name}
              type="text"
              placeholder={t('Member_Group_Bulk_Create_18')}
              $err={error.name.err}
              onChange={e => handleTable.changeGroupRow(e, group.id)}
              onFocus={handleTextFieldFocus}
              onBlur={handleTextFieldBlur}
            />
          </Tooltip>
        </StyledTableCell>
        <StyledTableCell>
          <Tooltip
            title={error.parent.message}
            placement="bottom-start"
            arrow
            PopperProps={{
              sx: {
                '& .MuiTooltip-tooltip': {
                  maxWidth: 'none',
                },
                '& .MuiTooltip-arrow': {
                  transform: 'translate(20px, 0) !important',
                },
              },
            }}
          >
            <Textfield
              name="parentCode"
              fullWidth
              value={group.parentCode}
              type="text"
              placeholder={t('Member_Group_Bulk_Create_17')}
              $err={error.parent.err}
              onChange={e => handleTable.changeGroupRow(e, group.id)}
              onFocus={handleTextFieldFocus}
              onBlur={handleTextFieldBlur}
            />
          </Tooltip>
        </StyledTableCell>
      </>
    );
  };

  return (
    <Container ref={setScrollTarget}>
      <div className="header">
        <IconButton>
          <ChevronIcon rotateNum={270} />
        </IconButton>
        <Title>{t('Member_Group_Bulk_Create_01')}</Title>
      </div>
      <div className="body">
        <DescriptionBox>
          <div>
            <BulletIcon />
            <span className="description">{t('Member_Group_Bulk_Create_02')}</span>
          </div>
          <div>
            <BulletIcon />
            <span className="description">{t('Member_Group_Bulk_Create_03')}</span>
          </div>
          <div>
            <BulletIcon />
            <span className="description">{t('Member_Group_Bulk_Create_04')}</span>
          </div>
        </DescriptionBox>
        <BatchRegister>
          <div className="title">{t('Member_Group_Bulk_Create_05')}</div>
          <div className="type-select-box">
            <div className="type-select">
              <Radio
                size="small"
                checked={batchType === 'update'}
                icon={<RadioOffIcon width={16} hanging={16} color={theme.colors['border-gray-light']} />}
                checkedIcon={<RadioIcon bgColor={theme.colors['bg-white']} color={theme.colors['bg-purple']} />}
                onClick={() => {
                  handleChangeRadio('update');
                }}
              />
              <span>
                {t('Member_Group_Bulk_Create_06')}
                <span className="description">{t('Member_Group_Bulk_Create_07')}</span>
              </span>
            </div>
            <div className="type-select">
              <Radio
                size="small"
                checked={batchType === 'replace'}
                icon={<RadioOffIcon width={16} hanging={16} color={theme.colors['border-gray-light']} />}
                checkedIcon={<RadioIcon bgColor={theme.colors['bg-white']} color={theme.colors['bg-purple']} />}
                onClick={() => {
                  handleChangeRadio('replace');
                }}
              />
              <span>
                {t('Member_Group_Bulk_Create_08')}
                <span className="description">{t('Member_Group_Bulk_Create_09')}</span>
              </span>
            </div>
          </div>
          <div className="file-upload">
            <StyledTextField
              className="upload-field"
              size="small"
              fullWidth
              value={uploadFile ? uploadFile[0].name : ''}
              InputProps={{
                startAdornment: uploadFile ? (
                  <StyledInputAdornment position="start">
                    <ExcelIcon width={20} height={20} />
                  </StyledInputAdornment>
                ) : undefined,
              }}
              disabled
            />
            <div className="buttons">
              <Button
                size="medium"
                paddingHorizontal={16}
                variant={batchType ? 'contain' : 'outline'}
                disabled={batchType === undefined}
                onClick={handleFileUpload.clickFileUploadBtn}
              >
                {t('Member_Group_Bulk_Create_10')}
              </Button>
              <FileUpload
                type="file"
                ref={fileRef}
                onChange={handleFileUpload.changeFileUpload}
                onClick={e => {
                  e.currentTarget.value = '';
                }}
              />
              <Button size="medium" paddingHorizontal={16} variant="outline" onClick={handleExcelDownload}>
                <ExcelBtn>
                  <DownloadIcon width={20} height={20} />
                  <span>{t('Member_Group_Bulk_Create_11')}</span>
                </ExcelBtn>
              </Button>
            </div>
          </div>
        </BatchRegister>
        <TableContainer>
          <TableInfoWrapper>
            <div className="count-wrapper">
              <strong className="total">
                {t('Acc_Main_12')}
                {checkedCount ? (
                  <span className="count">
                    {checkedCount}/{totalRows}
                  </span>
                ) : (
                  <span className="count">{totalRows}</span>
                )}
              </strong>
              <span className="err">
                (오류<span className={errorCount > 0 ? 'err_num' : ''}>{errorCount}</span>개)
              </span>
            </div>
            <div className="tablecell-wrapper">
              <div className="err-collect">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showErrorRowsOnly}
                      onChange={handleShowErrorRowsChange}
                      checkedIcon={
                        <CheckedBgIcon
                          width={16}
                          height={16}
                          color={theme.colors['bg-white']}
                          bgColor={theme.colors['bg-purple']}
                        />
                      }
                      icon={<CheckboxOffNoIcon width={16} height={16} bgColor={theme.colors['bg-white']} />}
                    />
                  }
                  label={t('Member_Group_Bulk_Create_14')}
                />
              </div>
              <div className="tablecell-btns">
                <Button
                  disabled={uploadFile === null}
                  size="small"
                  variant="outline"
                  paddingHorizontal={12}
                  onClick={handleTable.addRow}
                >
                  {t('Member_Group_Bulk_Create_15')}
                </Button>
                <Button
                  disabled={uploadFile === null}
                  size="small"
                  variant="outline"
                  paddingHorizontal={12}
                  onClick={handleTable.deleteRow}
                >
                  {t('Member_Group_Bulk_Create_16')}
                </Button>
                <Button
                  size="small"
                  variant="contain"
                  paddingHorizontal={24}
                  onClick={handleClickRegisterBtn}
                  disabled={groupsFromSheet.length === 0 || batchType === undefined}
                >
                  {t('Member_Group_Bulk_Create_21')}
                </Button>
              </div>
            </div>
          </TableInfoWrapper>
          <TableVirtuoso
            data={showErrorRowsOnly ? errGroups : groupsFromSheet}
            components={tableComponents}
            fixedHeaderContent={renderFixedHeaderContent}
            itemContent={renderItemContent}
            context={{ emptyDataMessage: t('Member_Group_Bulk_Create_20') }}
            customScrollParent={scrollTarget ?? undefined}
          />
        </TableContainer>
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 28px 32px;
  width: 100%;
  height: calc(100vh - var(--manager-gnb-height));
  overflow: auto;
  & .header {
    display: flex;
    flex-direction: row;
    gap: 4px;
  }
  & .body {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 16px;
  }
  & .footer {
    display: flex;
    flex-direction: row;
  }
`;

const Title = styled.span`
  ${fonts.Headline6};
`;

const DescriptionBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 96px;
  background-color: ${props => props.theme.colors['bg-gray-light']};
  padding: 16px 32px;
  gap: 2px;
  & > div {
    display: flex;
    flex-direction: row;
    gap: 6px;
    align-items: center;
  }
  & .description {
    ${fonts.Body2};
    color: ${props => props.theme.colors['text-gray-sub-dark']};
  }
  margin-bottom: 40px;
`;

const BatchRegister = styled.div`
  display: flex;
  flex-direction: column;
  width: 800px;
  height: 206px;
  padding: 0px 24px 50px;
  gap: 24px;
  & .title {
    ${fonts.Headline7};
  }
  & .type-select-box {
    display: flex;
    flex-direction: column;
    gap: 8px;
    & .type-select {
      display: flex;
      flex-direction: row;
      gap: 8px;
      align-items: center;
      ${fonts.Body2}
      & .description {
        margin-left: 4px;
        color: ${props => props.theme.colors['text-gray-sub-dark']};
      }
    }
  }
  & .file-upload {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    width: 630px;
    & .buttons {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
      button {
        ${fonts.Button3};
        padding: 8px 16px;

        &:disabled {
          border: none;
          color: ${({ theme }) => theme.colors['text-gray-light']};
          background-color: ${({ theme }) => theme.colors['bg-gray-dark']};
        }
      }
    }
  }
`;

const ExcelBtn = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  ${fonts.Button3};
`;

const TableContainer = styled.div`
  width: 800px;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 24px 40px;
`;

const TableInfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: -29px;
  left: 0;
  background-color: #fff;
  margin: 29px 0 12px;
  z-index: 1001;
  & .count-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }
  & .tablecell-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
  }
  & .total {
    ${fonts.Headline8};
    & .count {
      margin-left: 4px;
      color: ${props => props.theme.colors['text-purple-light']};
    }
  }
  & .err {
    ${fonts.Body2};
    color: ${props => props.theme.colors['text-gray-light']};
    & > span {
      display: inline-block;
      margin: 0 1px 0 4px;
    }
    & .err_num {
      color: ${props => props.theme.colors['text-red-light']};
    }
  }
  & .err-collect > .MuiFormControlLabel-root {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    margin: 0;
    & .MuiFormControlLabel-label {
      ${fonts.Body2};
      color: ${props => props.theme.colors['text-gray-light']};
    }
    & .MuiButtonBase-root.Mui-checked + .MuiFormControlLabel-label {
      color: ${props => props.theme.colors['text-gray-main']};
    }
  }
  & .tablecell-btns {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }
`;

const FileUpload = styled.input`
  display: none;
`;

const TableWrapper = styled.div``;

const CheckboxCell = styled(TableCell)`
  padding: 10px 12px;
  text-overflow: clip;
  border: 1px solid ${props => props.theme.colors['border-gray-w-lighter']};
  border-collapse: collapse;
  text-align: center;
`;

const StyledTableCell = styled(TableCell)`
  padding: 5px 8px 5px 4px;
  border: 1px solid ${props => props.theme.colors['border-gray-w-lighter']};
  border-collapse: collapse;
`;

const Textfield = styled(TextField, transientOptions)<{ $err: boolean }>`
  & .MuiFormControl-root {
    padding: 0;
  }
  & .MuiInputBase-root {
    height: 32px;
    width: 100%;
    border-radius: 2px;
    padding: 6px 12px;
    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      ${props =>
        props.$err &&
        css`
          border: 1px solid ${props.theme.colors['border-red-light']};
        `};
    }
    &:hover .MuiOutlinedInput-notchedOutline {
      ${props =>
        props.$err &&
        css`
          border: 1px solid ${props.theme.colors['border-red-light']};
        `};
    }
  }
  & .MuiInputBase-input {
    ${fonts.Body2};
    color: ${props => (props.$err ? props.theme.colors['text-red-light'] : props.theme.colors['text-gray-main'])};
    &::placeholder {
      color: ${props => (props.$err ? props.theme.colors['text-red-light'] : props.theme.colors['text-gray-light'])};
    }
  }
  & .MuiOutlinedInput-notchedOutline {
    border: ${props =>
      props.$err
        ? `1px solid ${props.theme.colors['border-red-light']}`
        : `1px solid ${props.theme.colors['border-gray-light']}`};
  }
`;

const StyledTextField = styled(TextField)`
  &.upload-field {
    height: 36px;
    .MuiInputBase-root {
      height: 36px;
    }
    .MuiInputBase-root.Mui-disabled {
      background: ${({ theme }) => theme.colors['bg-gray-lighter']};
      & .MuiOutlinedInput-notchedOutline {
        border: 1px solid ${({ theme }) => theme.colors['border-gray-light']};
      }
    }
  }
`;

const HeaderCell = styled(TableCell)`
  ${fonts.Headline9};
  border: 1px solid ${props => props.theme.colors['border-gray-w-lighter']};
  border-collapse: collapse;
  color: ${({ theme }) => theme.colors['text-gray-sub-darker']};
  span {
    display: inline-block;
    margin-top: -4px;
    vertical-align: text-top;
    color: ${({ theme }) => theme.colors['text-red-light']};
  }
`;

const StyledInputAdornment = styled(InputAdornment)`
  width: 20px;
  height: 20px;
  margin-right: 4px;
`;
