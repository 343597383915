import type { NodeDataType } from './graph/GraphType';
import type { Connection, Edge } from 'reactflow';
import { type Node } from 'reactflow';
import { makeAutoObservable, runInAction } from 'mobx';
import type { WorkflowActionCreateDTO, WorkflowCreateDTO } from '@repositories/workflowRepository/Types';
import type { WorkflowActionDTOModel } from '@models/workflow/WorkflowActionDTOModel';
import type { WorkflowExecModel } from '@models/workflow/WorkflowExecModel';
import type { WorkflowModel } from '@models/workflow/WorkflowModel';
import type { WorkflowTriggerDTOModel } from '@models/workflow/WorkflowTriggerDTOModel';
import {
  generateActionNode,
  generateConditionThenEdge,
  generateEndNode,
  generateExceptionEdge,
  generateGraph,
  generateNormalEdge,
  getLayoutedElements,
  traverseGraph,
} from './graph/GraphGenerator';

export type ToolbarView =
  | ''
  | 'workflowInfo'
  | 'triggerSelect'
  | 'triggerConfig'
  | 'triggerExec'
  | 'actionSelect'
  | 'actionConfig'
  | 'actionExec';

class WorkflowDetailStore {
  private _isEdit: boolean;
  private _toolbar: ToolbarView;
  private _createDTO: WorkflowCreateDTO;
  private _nodes: Node<NodeDataType>[];
  private _nodeMap: Map<string, Node<NodeDataType>>;
  private _edges: Edge[];
  private _curNodeId?: string;
  private _trigger?: WorkflowTriggerDTOModel;
  private _actions: WorkflowActionDTOModel[];
  private _lastActionNodeIndex: number;
  private _execution?: WorkflowExecModel;

  constructor() {
    this._isEdit = false;
    this._createDTO = {
      actions: [],
      name: '',
      trigger: {
        baseTriggerId: '',
      },
      type: 'CUSTOM',
      description: undefined,
      id: undefined,
      tenantId: '',
    };
    this._toolbar = '';
    this._nodes = [];
    this._nodeMap = new Map<string, Node>();
    this._edges = [];
    this._curNodeId = undefined;
    this._trigger = undefined;
    this._actions = [];
    this._lastActionNodeIndex = 0;
    this._execution = undefined;

    makeAutoObservable(this);
  }

  get isEdit() {
    return this._isEdit;
  }

  setIsEdit(isEdit: boolean) {
    this._isEdit = isEdit;
  }

  setTitle(title: string) {
    this._createDTO.name = title;
  }

  get title() {
    return this._createDTO.name;
  }

  get toolbar() {
    return this._toolbar;
  }

  setToolbarView(type: ToolbarView) {
    runInAction(() => {
      this._toolbar = type;
    });
  }

  get curNodeId(): string | undefined {
    return this._curNodeId;
  }

  set curNodeId(nodeId: string) {
    this._curNodeId = nodeId;
  }

  get nodes() {
    return this._nodes ?? [];
  }

  set nodes(nodes: Node<NodeDataType>[]) {
    this._nodes = nodes;

    this._nodeMap.clear();
    this.nodes.forEach(node => {
      this._nodeMap.set(node.id, node);
    });
  }

  get edges() {
    return this._edges ?? [];
  }

  set edges(edges: Edge[]) {
    this._edges = edges;
  }

  get triggerNode() {
    return this.nodes.find(node => node.data.type === 'trigger');
  }

  get actionNodes() {
    return this.nodes.filter(node => node.data.type === 'action');
  }

  get execution(): WorkflowExecModel | undefined {
    return this._execution;
  }

  findNode(nodeId: string) {
    return this._nodeMap.get(nodeId);
  }

  findEdgeBySource(souce: string, type?: string) {
    return this.edges.find(edge => edge.source === souce && (type ? type === edge.type : true));
  }

  checkEditable() {
    const isCreate = window.location.pathname.includes('create');
    return !(!isCreate && !this._isEdit);
  }

  reconstruct(nodes: Node[], edges: Edge[]) {
    runInAction(async () => {
      let actionIndex = 0;
      const newNodes: Node[] = [];
      // 노드 Map 생성
      const nodeMap = new Map(nodes.map(node => [node.id, node]));

      traverseGraph(nodes, edges, (node, nodeInfo) => {
        const updatedNode = node;
        const { incomingEdges, outgoingEdges } = nodeInfo;

        if (updatedNode.type === 'action') {
          // 액션 인덱스
          updatedNode.data.actionIndex = actionIndex;

          // ACTION 노드의 엣지가 없으면 해당 스텝 처리(-1)
          if (updatedNode.data.actionModel) {
            if (outgoingEdges.filter(edge => edge.type === 'normal').length === 0) {
              updatedNode.data.actionModel.nextStep = -1;
            }
            if (outgoingEdges.filter(edge => edge.type === 'conditionThen').length === 0) {
              updatedNode.data.actionModel.conditionThenStep = -1;
            }
            if (outgoingEdges.filter(edge => edge.type === 'exception').length === 0) {
              updatedNode.data.actionModel.exceptionCatchStep = -1;
            }
          }

          // ACTION 노드로 들어오는 모든 스텝 번호 수정
          incomingEdges.forEach(edge => {
            const sourceNode = nodeMap.get(edge.source);
            if (!sourceNode?.data.actionModel) return;

            switch (edge.type) {
              case 'conditionThen':
                sourceNode.data.actionModel.conditionThenStep = actionIndex;
                break;
              case 'exception':
                sourceNode.data.actionModel.exceptionCatchStep = actionIndex;
                break;
              case 'normal':
              default:
                sourceNode.data.actionModel.nextStep = actionIndex;
            }
          });

          actionIndex += 1;
        }

        // END 노드로 들어오는 모든 스텝 처리(-1)
        if (updatedNode.type === 'end') {
          incomingEdges.forEach(edge => {
            const sourceNode = nodeMap.get(edge.source);
            if (!sourceNode?.data.actionModel) return;
            sourceNode.data.actionModel.nextStep = -1;
          });
        }

        newNodes.push(updatedNode);
      });

      // 레이아웃 재구성
      const result = getLayoutedElements(newNodes, edges);
      this.nodes = result.nodes;
      this.edges = result.edges;
    });
  }

  removeActionNode(nodeId: string) {
    const incomingEdges = this.edges.filter(edge => edge.target === nodeId);
    const outgoingEdges = this.edges.filter(edge => edge.source === nodeId);

    const newEdges: Edge[] = [];

    incomingEdges.forEach(incomingEdge => {
      // 에외/조건 엣지 복구하지 않고 삭제
      if (incomingEdge.type === 'exception' || incomingEdge.type === 'conditionThen') return;

      outgoingEdges.forEach(outgoingEdge => {
        // 새로운 엣지 생성
        const newEdge: Edge = generateNormalEdge(incomingEdge.source, outgoingEdge.target);
        newEdges.push(newEdge);
      });
    });

    const updatedEdges = this.edges.filter(edge => edge.source !== nodeId && edge.target !== nodeId);
    const newNodes = this.nodes.filter(node => node.id !== nodeId);

    // 노드 재구성
    this.reconstruct(newNodes, [...updatedEdges, ...newEdges]);
  }

  addActionNode(prevNodeId: string, model?: WorkflowActionDTOModel): Node<NodeDataType> {
    // 새로운 노드의 고유 ID 생성
    const newActionIndex = this._lastActionNodeIndex + 1;

    // 새로운 액션 노드 생성
    const newActionNode = generateActionNode(newActionIndex, model);

    const newEdges: Edge[] = [];

    // 이전 노드에서 나가는 엣지 목록 조회
    const outgoingEdges = this.edges.filter(edge => edge.source === prevNodeId && edge.type === 'normal');

    // 이전 노드에서 나가는 엣지 제거
    const updatedEdges = this.edges.filter(edge => !(edge.source === prevNodeId && edge.type === 'normal'));

    // 이전 노드에서 새로운 노드로의 엣지를 생성
    const newEdgeToNewNode: Edge = generateNormalEdge(prevNodeId, newActionNode.id);
    newEdges.push(newEdgeToNewNode);

    // 기존에 이전 노드에서 나가던 엣지를 새로운 노드에서 타겟으로 연결
    const reconnectedEdges = outgoingEdges.map(edge => generateNormalEdge(newActionNode.id, edge.target));

    newEdges.push(...reconnectedEdges);

    // 노드 재구성
    // NOTE. 신규 노드 순서가 중요함. 다른 예외 노드보다 앞에 추가되어야만에 엣지가 교차되는 것을 방지할 수 있음.
    this.reconstruct([...this.nodes, newActionNode], [...newEdges, ...updatedEdges]);

    this._lastActionNodeIndex += 1;

    return newActionNode;
  }

  addExceptionActionNode(prevNodeId: string, model?: WorkflowActionDTOModel): Node<NodeDataType> {
    // 새로운 노드의 고유 ID 생성
    const newActionIndex = this._lastActionNodeIndex + 1;

    // 새로운 액션 노드 생성
    const newActionNode = generateActionNode(newActionIndex, model);
    if (newActionNode.data.actionModel) {
      newActionNode.data.actionModel.nextStep = -1;
    }
    const newExceptionEdge = generateExceptionEdge(prevNodeId, newActionNode.id);
    const newEndNode = generateEndNode(newActionIndex);
    const newNormalEdge = generateNormalEdge(newActionNode.id, newEndNode.id);

    // 노드 재구성
    this.reconstruct([...this.nodes, newActionNode, newEndNode], [...this.edges, newNormalEdge, newExceptionEdge]);

    this._lastActionNodeIndex += 1;

    return newActionNode;
  }

  addConditionThenActionNode(prevNodeId: string, model?: WorkflowActionDTOModel): Node<NodeDataType> {
    // 새로운 노드의 고유 ID 생성
    const newActionIndex = this._lastActionNodeIndex + 1;

    // 새로운 액션 노드 생성
    const newActionNode = generateActionNode(newActionIndex, model);
    if (newActionNode.data.actionModel) {
      newActionNode.data.actionModel.nextStep = -1;
    }
    const newCondtionThenEdge = generateConditionThenEdge(prevNodeId, newActionNode.id);
    const newEndNode = generateEndNode(newActionIndex);
    const newNormalEdge = generateNormalEdge(newActionNode.id, newEndNode.id);

    // 노드 재구성
    this.reconstruct([...this.nodes, newActionNode, newEndNode], [...this.edges, newNormalEdge, newCondtionThenEdge]);

    this._lastActionNodeIndex += 1;

    return newActionNode;
  }

  initNodes(isEditMode: boolean, model?: WorkflowModel, execModel?: WorkflowExecModel) {
    this._trigger = model?.trigger;
    this._actions = model?.actions ? [...model.actions] : [];
    this._execution = execModel;
    const initialElements = generateGraph({ trigger: this._trigger, actions: this._actions });
    this.nodes = initialElements.nodes;
    this.edges = initialElements.edges;

    this._lastActionNodeIndex = this._actions.length - 1;
  }

  connectNodes(connection: Connection) {
    const { source: sourceNodeId, target: targetNodeId, sourceHandle: edgeType } = connection;
    if (!sourceNodeId || !targetNodeId) return;

    // NOTE. 연결은 일반 엣지만 가능!!!
    if (edgeType !== 'normal') return;

    const existingEdge = this.edges.find(
      edge => edge.source === sourceNodeId && edge.target === targetNodeId && edge.type === edgeType,
    );

    if (existingEdge) return;

    // 기존의 동일 유형의 엣지 제거
    const updatedEdges = this.edges.filter(edge => !(edge.source === sourceNodeId && edge.type === edgeType));

    // 새 엣지 생성
    const newEdge = generateNormalEdge(sourceNodeId, targetNodeId);

    this.reconstruct(this.nodes, [newEdge, ...updatedEdges]);
  }

  toWorflowCreateDTO(tenantId: string): WorkflowCreateDTO | null {
    if (!this.triggerNode?.data.triggerModel) return null;

    // 액션 인덱스 재넘버링
    const actions: WorkflowActionCreateDTO[] = [];
    traverseGraph(this.nodes, this.edges, node => {
      if (node.type === 'action' && node.data.actionModel) {
        actions.push(node.data.actionModel);
      }
    });

    return {
      actions,
      description: '',
      name: workflowDetailStore.title,
      trigger: this.triggerNode.data.triggerModel,
      type: 'CUSTOM',
      tenantId,
    };
  }
}

const workflowDetailStore = new WorkflowDetailStore();

export default workflowDetailStore;
