import type {
  PlanScope,
  ProductPlan,
  ProductPlanCreateRq,
  ProductPlanOption,
  ProductPlanOptionCreateRq,
  ProductPlanUpdateRq,
  ProductPlanUsageMetric,
  ProductPlanUsageMetricCreateRq,
  Software,
} from '@repositories/softwareRepository';

export class ProductPlanModel implements ProductPlan {
  private _planId: string;

  private _planName: string;

  private _planType: 'MAIN' | 'ADD_ON';

  private _featureSet: string;

  private _description: string;

  private _pricingType:
    | 'Free'
    | 'FLAT_RATE'
    | 'USAGE_BASED'
    | 'USAGE_COMMITMENT'
    | 'PER_USER'
    | 'PER_ACTIVE_USER'
    | 'PER_CONCURRENT_USER'
    | 'BY_USAGE_RANGE'
    | 'CUSTOM';

  private _usageMetricList: ProductPlanUsageMetric[];

  private _planOptionList: ProductPlanOption[];

  private _currencyUnit: CurrencyUnit;

  private _isBasicPlan: boolean;

  private _planGrade: number;

  private _planScope: PlanScope;

  private _isInUseByTenant: boolean;

  constructor(dto?: ProductPlan) {
    this._planId = dto?.planId || '';
    this._planName = dto?.planName || '';
    this._planType = dto?.planType || 'MAIN';
    this._featureSet = dto?.featureSet || '';
    this._description = dto?.description || '';
    this._pricingType = dto?.pricingType || 'Free';
    this._usageMetricList = dto?.usageMetricList || [];
    this._planOptionList = dto?.planOptionList || [];
    this._currencyUnit = dto?.currencyUnit || 'USD';
    this._isBasicPlan = dto?.isBasicPlan || false;
    this._planGrade = dto?.planGrade || 0;
    this._planScope = dto?.planScope || 'SUBSCRIPTION';
    this._isInUseByTenant = dto?.isInUseByTenant || false;
  }
  product?: Software | undefined;

  get isInUseByTenant(): boolean {
    return this._isInUseByTenant;
  }
  public get planId(): string {
    return this._planId;
  }

  public get planName(): string {
    return this._planName;
  }

  public get planType(): 'MAIN' | 'ADD_ON' {
    return this._planType;
  }

  public get featureSet(): string {
    return this._featureSet;
  }

  public get description(): string {
    return this._description;
  }

  public get pricingType():
    | 'Free'
    | 'FLAT_RATE'
    | 'USAGE_BASED'
    | 'USAGE_COMMITMENT'
    | 'PER_USER'
    | 'PER_ACTIVE_USER'
    | 'PER_CONCURRENT_USER'
    | 'BY_USAGE_RANGE'
    | 'CUSTOM' {
    return this._pricingType;
  }

  public get isPricingByUser() {
    // unitPrice로 계산
    return (
      this.pricingType === 'PER_ACTIVE_USER' ||
      this.pricingType === 'PER_CONCURRENT_USER' ||
      this.pricingType === 'PER_USER'
    );
  }

  public get isPricingByUsage() {
    // usageMetricPrices로 계산
    return (
      this.pricingType === 'Free' ||
      this.pricingType === 'FLAT_RATE' ||
      this.pricingType === 'USAGE_BASED' ||
      this.pricingType === 'USAGE_COMMITMENT' ||
      this.pricingType === 'BY_USAGE_RANGE'
    );
  }

  get pricingTypeDisplayName(): string {
    if (this.isPricingByUser) return '사용자 수';
    return '';
  }

  public get usageMetricList(): ProductPlanUsageMetric[] {
    return this._usageMetricList;
  }

  public get planOptionList(): ProductPlanOption[] {
    return this._planOptionList.filter(({ optionName }) => !!optionName);
  }

  public get currencyUnit(): CurrencyUnit {
    return this._currencyUnit;
  }

  public get isBasicPlan(): boolean {
    return this._isBasicPlan;
  }

  public get planScope() {
    return this._planScope;
  }

  public get planGrade(): number {
    return this._planGrade;
  }

  public get usageMetrics() {
    return this.usageMetricList.map(({ usageMetric }) => usageMetric);
  }

  public createRq(softwareId: string): ProductPlanCreateRq {
    return {
      softwareId,
      planName: this._planName,
      planType: this._planType,
      planGrade: this._planGrade,
      description: this._description,
      featureSet: this._featureSet,
      pricingType: this._pricingType,
      usageMetricList: this.toProductPlanUsageMetricCreateList(),
      planOptionList: this.toPlanOptionCreateList(),
      currencyUnit: this._currencyUnit,
      isBasicPlan: this._isBasicPlan,
      planScope: this._planScope,
    };
  }

  public get updateRq(): ProductPlanUpdateRq {
    return {
      planId: this._planId,
      planName: this._planName,
      planType: this._planType,
      planGrade: this._planGrade,
      description: this._description,
      featureSet: this._featureSet,
      pricingType: this._pricingType,
      usageMetricList: this.toProductPlanUsageMetricCreateList(),
      planOptionList: this.toPlanOptionCreateList(),
      currencyUnit: this._currencyUnit,
      isBasicPlan: this._isBasicPlan,
      planScope: this._planScope,
    };
  }

  public get usageMetricCreateList() {
    return this.toProductPlanUsageMetricCreateList();
  }

  toProductPlanUsageMetricCreateList(): ProductPlanUsageMetricCreateRq[] {
    return this._usageMetricList.map(productPlanUsageMetric => ({
      usageMetricId: productPlanUsageMetric.usageMetric.usageMetricId,
      billableUsageExpression: productPlanUsageMetric.billableUsageExpression,
      costExpression: productPlanUsageMetric.costExpression,
      committedUsage: productPlanUsageMetric.committedUsage,
      details: productPlanUsageMetric.details,
      costCalculationType: productPlanUsageMetric.costCalculationType,
    }));
  }

  toPlanOptionCreateList(): ProductPlanOptionCreateRq[] {
    return this._planOptionList.map(planOption => ({
      planOptionId: planOption.planOptionId,
      optionName: planOption.optionName,
      paymentPeriod: planOption.paymentPeriod,
      paymentOption: planOption.paymentOption,
      billingCycle: planOption.billingCycle,
      billingPeriodType: planOption.billingPeriodType,
      commitmentPeriod: planOption.commitmentPeriod,
      customCommitmentPeriod: planOption.customCommitmentPeriod,
      unitPeriod: planOption.unitPeriod,
      unitPrice: planOption.unitPrice,
      usageMetricPrices: planOption.usageMetricPrices.map(usageMetricPrice => ({
        usageMetricId: usageMetricPrice.usageMetricId,
        usageFrom: usageMetricPrice.usageFrom,
        usageTo: usageMetricPrice.usageTo,
        usageUnitPrice: usageMetricPrice.usageUnitPrice,
        unitPeriod: usageMetricPrice.unitPeriod,
      })),
    }));
  }
}
