import type { Dayjs } from 'dayjs';
import { useEffect } from 'react';
import { Controller, SubmitHandler, useFormContext } from 'react-hook-form';
import { Trans } from 'react-i18next';
import styled from '@emotion/styled';
import { TextField } from '@mui/material';
import { t } from 'i18next';
import { DatePicker } from '@components/datePicker';
import { Button, Dropdown } from '@components/index';
import { CurrencyInput } from '@pages/customer/common/CurrencyInput';
import { useStore } from '@stores/RootStore';
import { fonts } from '@theme/fontsCustomer';
import { type MonthlyBillingInfoDTO } from '@repositories/userMonthlyBillingRepository/Types';
import { useGetExchangeRates } from '@queryHooks/useExchangeRate';
import { useGetTenant } from '@queryHooks/useTenant';
import { CurrencyUnits } from '@utils/commonVars';
import { dateFormat } from '@utils/dateUtil';
import type { SubscriptionModel } from '@models/SubscriptionModel';
import { GenerateMonthlyBillingForm } from '../validateSchema';

type Props = {
  selectedMonth: Dayjs;
  subscription: SubscriptionModel;
  billingInfo: MonthlyBillingInfoDTO;
  onNextStep: () => void;
  onClose: () => void;
};

export const BillingGenerateForm = ({ selectedMonth, subscription, billingInfo, onNextStep, onClose }: Props) => {
  const { authStore } = useStore();
  const { control, reset, setValue, watch, formState, trigger } = useFormContext<GenerateMonthlyBillingForm>();
  const [formPaymentDate, formCurrencyUnit] = watch(['paymentDate', 'currencyUnit']);

  const { data: tenant } = useGetTenant(authStore.managerTenantInfo.id);

  useEffect(() => {
    reset({
      paymentDate: billingInfo.paymentDate,
      currencyUnit: tenant?.currencyUnit,
      exchangeRate: 1,
    });
  }, [billingInfo, reset, subscription, tenant]);

  const { data: exchangeRates } = useGetExchangeRates(
    {
      dateFrom: dateFormat(formPaymentDate, undefined, 'YYYY-MM-DD'),
      dateTo: dateFormat(formPaymentDate, undefined, 'YYYY-MM-DD'),
      baseCurrencyUnit: subscription.currencyUnit,
      sort: ['createdDate.desc'],
    },
    {
      enabled: !!formPaymentDate,
    },
  );

  useEffect(() => {
    const exchangeRateModel = exchangeRates?.content.find(
      ({ targetCurrencyUnit }) => targetCurrencyUnit === formCurrencyUnit,
    );

    setValue('exchangeRate', exchangeRateModel?.exchangeRate ?? 1);
  }, [exchangeRates, formCurrencyUnit, setValue]);

  const handleDateOnChange = (onChange: UnknownFunction) => (value: string | null) => {
    onChange(value);
    trigger('paymentDate');
  };

  return (
    <>
      <Content>
        <DescriptionWrapper>
          <Description>
            <Trans
              i18nKey="Acc_Create_24"
              values={{ date: dateFormat(selectedMonth, '', 'YYYY.MM') }}
              components={{ b: <b /> }}
            />
          </Description>
        </DescriptionWrapper>
        <Field>
          <RequiredFieldKey htmlFor="payment-date">{t('Acc_Main_20')}</RequiredFieldKey>
          <Controller
            control={control}
            name="paymentDate"
            render={({ field: { value, ref, onChange } }) => (
              <DatePicker
                value={value}
                onChange={handleDateOnChange(onChange)}
                ref={ref}
                renderInput={params => <DatePicker.Input {...params} id="payment-date" fullWidth />}
              />
            )}
          />
          {formState.errors.paymentDate && <ErrorMessage>{formState.errors.paymentDate?.message}</ErrorMessage>}
        </Field>
        <Field>
          <RequiredFieldKey htmlFor="currency-unit">{t('Acc_Create_26')}</RequiredFieldKey>
          <Controller
            control={control}
            name="currencyUnit"
            render={({ field: { value, onChange, ref } }) => (
              <Dropdown id="currency-unit" ref={ref} value={value ?? ''} onChange={onChange} width="100%" size="large">
                {CurrencyUnits.map(currencyUnit => (
                  <Dropdown.Menu key={currencyUnit} value={currencyUnit}>
                    {currencyUnit}
                  </Dropdown.Menu>
                ))}
              </Dropdown>
            )}
          />
          {formState.errors.currencyUnit && <ErrorMessage>{formState.errors.currencyUnit?.message}</ErrorMessage>}
        </Field>
        <Field>
          <RequiredFieldKey htmlFor="exchange-rate">{t('Acc_Create_10')}</RequiredFieldKey>
          <Controller
            control={control}
            name="exchangeRate"
            render={({ field: { value, onChange, ref } }) => (
              <TextField
                id="exchange-rate"
                ref={ref}
                value={value}
                onChange={onChange}
                fullWidth
                InputProps={{ inputComponent: CurrencyInput }}
              />
            )}
          />
        </Field>
        {formState.errors.exchangeRate && <ErrorMessage>{formState.errors.exchangeRate?.message}</ErrorMessage>}
      </Content>
      <Footer>
        <Button type="submit" variant="contain" size="extraLarge" paddingHorizontal={99.5}>
          완료
        </Button>
        <Button variant="outline" size="extraLarge" paddingHorizontal={98.5} onClick={onClose}>
          {t('GNB_Profile_MyProfile_11')}
        </Button>
      </Footer>
    </>
  );
};

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 24px 28px 16px 28px;
`;

const Footer = styled.div`
  display: flex;
  padding: 24px 0px 28px;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-top: auto;
`;

const DescriptionWrapper = styled.div`
  margin-bottom: 28px;
`;

const Description = styled.p`
  ${fonts.Body2}
  white-space: pre-wrap;

  & + & {
    margin-top: 2px;
  }

  & > b {
    color: ${({ theme: { colors } }) => colors['text-purple']};
    ${fonts.Headline8}
  }
`;

const RequiredFieldKey = styled.label`
  display: block;
  ${fonts.Headline8}
  margin-bottom: 6px;
  &:after {
    content: '*';
    color: red;
    margin-left: 2px;
  }
  &.disabled {
    color: ${({ theme }) => theme.colors['text-gray-light']};
    &:after {
      color: ${({ theme }) => theme.colors['text-red-lighter']};
    }
  }
`;

const Field = styled.div`
  & + & {
    margin-top: 24px;
  }
`;

const ErrorMessage = styled.p`
  ${fonts.Body4}
  color: ${({ theme: { colors } }) => colors['text-red-light']};
  margin-top: 2px;
`;
