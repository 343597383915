import type { WorkflowOutletContext } from '../WorkflowDetail';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';
import {
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  useTheme,
} from '@mui/material';
import { Loader } from '@components/loader';
import { fonts } from '@theme/fontsCustomer';
import { ErrorIcon } from '@icons/ErrorIcon';
import type { WorkflowExecFilter } from '@repositories/workflowRepository/Types';
import { useIntersectionObserver } from '@customHooks/useIntersectionObserver';
import { useGetWorkflowExecutionsInfinite } from '@queryHooks/useWorkflow';
import { WorkflowExecRow } from './WorkflowExecRow';

export const WorkflowExecListPage = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { workflow } = useOutletContext<WorkflowOutletContext>();
  const [filter] = useState<WorkflowExecFilter>({});

  const {
    data: infiniteExecutions,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    isSuccess,
  } = useGetWorkflowExecutionsInfinite(workflow.id, { ...filter });
  const totalExecutions = infiniteExecutions?.pages[0].totalElements ?? 0;
  const executions = infiniteExecutions?.pages[0].content ?? [];

  const [ref] = useIntersectionObserver({
    onIntersect: entry => {
      if (entry.isIntersecting && !isFetchingNextPage && hasNextPage) {
        fetchNextPage();
      }
    },
  });

  if (!isSuccess) return <Loader />;

  return (
    <Container>
      <Body>
        <Toolbar>
          <strong className="toolbar-left">
            {t('Acc_Main_12')}
            <span className="count">{totalExecutions}</span>
          </strong>
          <div className="toolbar-right" />
        </Toolbar>
        <TableWrapper>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell width="72px" align="center">
                    {t('Workflow_Main_04')}
                  </StyledTableCell>
                  <TableCell width="180px">시작 시간</TableCell>
                  <TableCell width="100px">실행 시간</TableCell>
                  <TableCell width="120px">상태</TableCell>
                  <TableCell width="100%">상태 메시지</TableCell>
                  <StyledTableCell width="72px" align="center">
                    {t('Acc_Main_23')}
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {executions.length ? (
                  executions.map((exec, idx) => {
                    return <WorkflowExecRow data={exec} key={exec.id} idx={idx} />;
                  })
                ) : (
                  <NoDataRow>
                    <TableCell colSpan={8} rowSpan={5}>
                      <Empty>
                        <Avatar sx={{ backgroundColor: theme.colors['bg-purple-lighter'] }}>
                          <ErrorIcon color={theme.colors['ic-purple-light']} />
                        </Avatar>
                        <div className="main-text">워크플로우 실행 이력이 존재하지 않습니다.</div>
                      </Empty>
                    </TableCell>
                  </NoDataRow>
                )}
              </TableBody>
            </Table>
            {hasNextPage && <div ref={ref} />}
          </TableContainer>
        </TableWrapper>
      </Body>
    </Container>
  );
};

const NoDataRow = styled(TableRow)`
  &.MuiTableRow-root:hover {
    background-color: ${({ theme }) => theme.colors['bg-white']};
  }
`;

const Container = styled('div')`
  width: 100%;
  height: calc(100vh - var(--manager-gnb-height));
  overflow: auto;
  padding: 0px 32px;
`;

const Body = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 32px 0px;
  gap: 12px;
`;

const Toolbar = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 32px;

  & .toolbar-left {
    ${fonts.Headline7};
    color: ${({ theme: { colors } }) => colors['text-gray-main']};
    margin-top: 6px;
    margin-left: 8px;
    & .count {
      margin-left: 4px;
      color: ${({ theme: { colors } }) => colors['text-purple-light']};
    }
  }

  & .toolbar-right {
    display: flex;
    flex-direction: row;
    gap: 8px;
  }
`;

const TableWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 29px;
  table {
    display: table;
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    table-layout: fixed;
    border-collapse: collapse;
    border-radius: 5px;
    border-style: hidden;
    box-shadow: 0 0 0 1px ${({ theme }) => theme.colors['border-gray-lighter']};
    overflow: hidden;
  }
  & .MuiTableRow-root {
    cursor: pointer;
  }
  th {
    text-align: left;
    color: ${({ theme }) => theme.colors['text-gray-main']};
    border: 1px solid ${({ theme }) => theme.colors['border-gray-w-lighter']};
    background-color: ${({ theme }) => theme.colors['bg-gray-lighter']};
  }
  td {
    color: ${({ theme }) => theme.colors['text-gray-main']};
    border: 1px solid ${({ theme }) => theme.colors['border-gray-w-lighter']};
  }
`;

const Empty = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 28px 0px 30px 0px;
  align-items: center;
  & .main-text {
    ${fonts.Body2};
    color: ${({ theme: { colors } }) => colors['text-gray-main']};
    margin-top: 12px;
  }
  & .sub-text {
    ${fonts.Body2};
    color: ${({ theme: { colors } }) => colors['text-gray-sub-dark']};
  }
`;

const StyledTableCell = styled(TableCell)`
  &.MuiTableCell-root {
    text-align: center;
  }
`;
