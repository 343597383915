import { TenantList } from '@repositories/tenantRepository/Types';
import { CompanySimpleModel } from '@models/CompanySimpleModel';
import { TenantSimpleModel } from './TenantSimpleModel';

export class TenantListModel {
  private _id: string;
  private _tenantCode: string;
  private _description: string;
  private _name: string;
  private _parentTenant: TenantSimpleModel | undefined;
  private _level: number;
  private _memberCount: number;
  private _subTenantCount: number;
  private _subscriptionCount: number;
  private _rootTenant: TenantSimpleModel;
  private _company?: CompanySimpleModel;

  constructor(dto?: TenantList) {
    this._id = dto?.id || '';
    this._tenantCode = dto?.tenantCode || '';
    this._description = dto?.description || '';
    this._name = dto?.name || '';
    this._parentTenant = dto?.parentTenant ? new TenantSimpleModel(dto?.parentTenant) : undefined;
    this._level = dto?.level || -1;
    this._memberCount = dto?.memberCount || 0;
    this._subTenantCount = dto?.subTenantCount || 0;
    this._subscriptionCount = dto?.subscriptionCount || 0;
    this._rootTenant = new TenantSimpleModel(dto?.rootTenant);
    this._company = dto?.company ? new CompanySimpleModel(dto.company) : undefined;
  }

  get id(): string {
    return this._id;
  }

  get tenantCode(): string {
    return this._tenantCode;
  }

  get description(): string {
    return this._description;
  }

  get name(): string {
    return this._name;
  }

  get parentTenant(): TenantSimpleModel {
    if (this._parentTenant === undefined) {
      return new TenantSimpleModel();
    }
    return this._parentTenant;
  }

  hasParentTenant(): boolean {
    return !!this._parentTenant;
  }

  get level(): number {
    return this._level;
  }

  get memberCount(): number {
    return this._memberCount;
  }

  get subTenantCount(): number {
    return this._subTenantCount;
  }

  get subscriptionCount(): number {
    return this._subscriptionCount;
  }

  get rootTenant(): TenantSimpleModel {
    return this._rootTenant;
  }

  isCompanyTenant() {
    return !!this._company;
  }

  get company() {
    return this._company;
  }
}
