import { SubscriptionSimple, SubscriptionStatus, SubscriptionType } from '@repositories/subscriptionRepository/Types';
import dayjs, { Dayjs } from 'dayjs';
import { SoftwareSimpleModel } from './softwareModels';

export class SubscriptionSimpleModel {
  private _description: string;
  private _endDate: Dayjs;
  private _id: string;
  private _isQuantityContract: boolean;
  private _name: string;
  private _product: SoftwareSimpleModel;
  private _startDate: Dayjs;
  private _status: SubscriptionStatus;
  private _timeZone: string;
  private _type: SubscriptionType;
  private _version: number;

  constructor(dto?: SubscriptionSimple) {
    this._id = dto?.id || '';
    this._type = dto?.type || 'ON_DEMAND';
    this._name = dto?.name || '';
    this._description = dto?.description || '';
    this._timeZone = dto?.timeZone || '';
    this._version = dto?.version || 0;
    this._status = dto?.status || 'OPEN';
    this._startDate = dayjs(dto?.startDate);
    this._endDate = dayjs(dto?.endDate);
    this._isQuantityContract = dto?.isQuantityContract || false;
    this._product = new SoftwareSimpleModel(dto?.product);
  }

  get id(): string {
    return this._id;
  }

  get type(): SubscriptionType {
    return this._type;
  }

  get name(): string {
    return this._name;
  }

  get description(): string {
    return this._description;
  }

  get timeZone(): string {
    return this._timeZone;
  }

  get version(): number {
    return this._version;
  }

  get status(): SubscriptionStatus {
    return this._status;
  }

  get startDate(): Dayjs {
    if (this._startDate) return this._startDate;
    return dayjs();
  }

  get endDate(): Dayjs {
    if (this._endDate) return this._endDate;
    return dayjs();
  }

  get product(): SoftwareSimpleModel {
    return this._product;
  }
}
