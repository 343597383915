import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Skeleton, Table, TableBody, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import { TableHeaderCell, EmptyRow, SkeletonRows, Button } from '@components/index';
import { fonts } from '@theme/fontsCustomer';
import { QuestionIcon, CardColorIcon, ReceiptColorIcon } from '@icons/index';
import { useIntersectionObserver, useSearchParamsFilter } from '@customHooks/index';
import {
  useGetTenantBillingsInfinite,
  useGetMonthlyBillingListInfinite,
  useGetTenant,
  useGetTotalBilling,
} from '@queryHooks/index';
import { getSortOrder, currencyNumberFormat, getSortOrderBy } from '@utils/index';
import { SubscriptionBillingModel, TenantMonthlyBillingModel } from '@models/index';
import { MonthFilterTab } from './MonthFilterTab';
import { SubscriptionBillingRow } from './SubscriptionBillingRow';
import { TenantBillingRow } from './TenantBillingRow';
import { AddExistingBillingModal } from './addExistingBillingModal/AddExistingBillingModal';

type Filter = {
  sort?: SortString[];
  monthFrom?: DateTimeString | null;
  monthTo?: DateTimeString | null;
};

export const BillingList = () => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const { tenantId } = useParams();
  const [isAddModalOpen, setAddModalOpen] = useState(false);

  // 초기 날짜 설정
  const initialFilter = {
    sort: ['month.desc' as const],
    monthFrom: null,
    monthTo: null,
  };
  const [queries, setQueries] = useSearchParamsFilter<Filter>({
    defaultFilter: initialFilter,
    whiteList: ['sort'],
  });

  const {
    data: pagedTenantBillings,
    isLoading: isLoadingTenantBilling,
    isSuccess: isTenantBillingSuccess,
    isFetchingNextPage: isFetchingNextTenantBillingPage,
    hasNextPage: hasNextTenantBillingPage,
    fetchNextPage: fetchNextTenantBillingPage,
  } = useGetTenantBillingsInfinite({
    ...queries,
  });

  const tenantBillings = pagedTenantBillings?.pages.map(({ content }) => content).flat(1) ?? [];

  const [setLastTenantBilling] = useIntersectionObserver({
    onIntersect: entry => {
      if (entry.isIntersecting && !isFetchingNextTenantBillingPage && hasNextTenantBillingPage) {
        fetchNextTenantBillingPage();
      }
    },
  });

  const { data: tenant } = useGetTenant(tenantId ?? '');

  const {
    data: pagedSubscriptionBillings,
    isLoading: isLoadingSubscriptionBilling,
    isSuccess: isSubscriptionBillingSuccess,
    isFetchingNextPage: isFetchingNextSubscriptionBillingPage,
    hasNextPage: hasNextSubscriptionBillingPage,
    fetchNextPage: fetchNextSubscriptionBillingPage,
  } = useGetMonthlyBillingListInfinite({
    isIndividual: true,
    ...queries,
  });

  const subscriptionBillings = pagedSubscriptionBillings?.pages.map(({ content }) => content).flat(1) ?? [];

  const [setLastSubscriptionBilling] = useIntersectionObserver({
    onIntersect: entry => {
      if (entry.isIntersecting && !isFetchingNextSubscriptionBillingPage && hasNextSubscriptionBillingPage) {
        fetchNextSubscriptionBillingPage();
      }
    },
  });

  const { data: totalBilling } = useGetTotalBilling({
    monthFrom: queries.monthFrom,
    monthTo: queries.monthTo,
  });

  const isValidOrderBy = (key: string): key is 'month' | 'paymentDate' => ['month', 'paymentDate'].includes(key);

  const billingTableRowData =
    isTenantBillingSuccess && isSubscriptionBillingSuccess
      ? [...tenantBillings, ...subscriptionBillings].sort((a, b) => {
          const orderBy = getSortOrderBy(queries.sort);
          if (!!orderBy && isValidOrderBy(orderBy) && a[orderBy] !== b[orderBy]) {
            const sortOrder = getSortOrder(queries.sort, orderBy);
            const aValue = a[orderBy];
            const bValue = b[orderBy];
            if (!!aValue && !!bValue) {
              if (sortOrder === 'asc') {
                return aValue < bValue ? -1 : 1;
              }
              return aValue > bValue ? -1 : 1;
            }
          }
          return a instanceof TenantMonthlyBillingModel ? -1 : a instanceof SubscriptionBillingModel ? 1 : 0;
        })
      : [];

  const totalElements =
    isTenantBillingSuccess && isSubscriptionBillingSuccess
      ? pagedTenantBillings.pages[0].totalElements + pagedSubscriptionBillings.pages[0].totalElements
      : 0;

  const lastSubscriptionBillingIdx =
    subscriptionBillings.length > 0
      ? billingTableRowData.findLastIndex(billing => billing instanceof SubscriptionBillingModel)
      : -1;
  const lastTenantBillingIdx =
    tenantBillings.length > 0
      ? billingTableRowData.findLastIndex(billing => billing instanceof TenantMonthlyBillingModel)
      : -1;

  const setBillingRef = (idx: number) => (ref: HTMLTableRowElement) => {
    if (idx === lastTenantBillingIdx && hasNextTenantBillingPage) {
      setLastTenantBilling(ref);
    } else if (idx === lastSubscriptionBillingIdx && hasNextSubscriptionBillingPage) {
      setLastSubscriptionBilling(ref);
    }
  };

  const handleMonthChnage = (monthFrom: DateTimeString | null, monthTo: DateTimeString | null) => {
    setQueries(prev => ({ ...prev, monthFrom, monthTo }));
  };

  const handleSortChange = (orderBy: string, order?: 'asc' | 'desc') => {
    setQueries(prev => ({ ...prev, sort: order ? [`${orderBy}.${order}`] : [] }));
  };

  return (
    <>
      <Container>
        <Title>정산 내역 조회</Title>
        <SubtitleWrapper>
          <Subtitle>{t('Acc_Main_06')}</Subtitle>
          <Button variant="contain" size="small" paddingHorizontal={16} onClick={() => setAddModalOpen(true)}>
            {t('Acc_Create_01')}
          </Button>
        </SubtitleWrapper>

        <MonthFilterTab queries={queries} onMonthChange={handleMonthChnage} />

        <BillingListWrapper>
          <TotalBox>
            <TotalAmountBox>
              <CardColorIcon width={24} height={24} />
              <TotalText>정산 금액</TotalText>
              <Money>
                {tenant && totalBilling ? (
                  totalBilling.totalSettlement === undefined ? (
                    '-'
                  ) : (
                    currencyNumberFormat(totalBilling.totalSettlement, tenant.currencyUnit)
                  )
                ) : (
                  <Skeleton width={120} />
                )}
              </Money>
            </TotalAmountBox>
            <TotalAmountBox>
              <ReceiptColorIcon />
              <TotalText>지불 금액</TotalText>
              <Money>
                {tenant && totalBilling ? (
                  totalBilling.totalPayment === undefined ? (
                    '-'
                  ) : (
                    currencyNumberFormat(totalBilling.totalPayment, tenant?.currencyUnit)
                  )
                ) : (
                  <Skeleton width={120} />
                )}
              </Money>
            </TotalAmountBox>
          </TotalBox>

          <TotalElementsWrapper>
            {t('Acc_Main_12')} <TotalElements>{totalElements}</TotalElements>
          </TotalElementsWrapper>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableHeaderCell
                    width="100px"
                    orderBy="month"
                    order={getSortOrder(queries.sort, 'month')}
                    onSortChange={handleSortChange}
                  >
                    {t('Subscrib_Detail_Usage_05')}
                  </TableHeaderCell>
                  <TableHeaderCell width="262px">{t('Acc_Table_Column_02')}</TableHeaderCell>
                  <TableHeaderCell width="110px">{t('Acc_Table_Column_01')}</TableHeaderCell>
                  <TableHeaderCell width="230px">{t('Workflow_Create_64')}</TableHeaderCell>
                  <TableHeaderCell width="200px">{t('Acc_Main_18')}</TableHeaderCell>
                  <TableHeaderCell width="180px">
                    <CellWithIcon>
                      {t('Acc_Main_19')}
                      <Tooltip title="결제일 기준 환율을 적용한 예측 정산 금액입니다." arrow>
                        <QuestionIcon width={14} height={14} color={colors['ic-gray-light']} />
                      </Tooltip>
                    </CellWithIcon>
                  </TableHeaderCell>
                  <TableHeaderCell
                    width="140px"
                    orderBy="paymentDate"
                    order={getSortOrder(queries.sort, 'paymentDate')}
                    onSortChange={handleSortChange}
                  >
                    {t('Acc_Main_20')}
                  </TableHeaderCell>
                  <TableHeaderCell width="130px">{t('Workflow_Main_07')}</TableHeaderCell>
                  <TableHeaderCell width="120px">{t('Acc_Main_22')}</TableHeaderCell>
                  <TableHeaderCell width="72px" align="center">
                    {t('Acc_Main_23')}
                  </TableHeaderCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(isLoadingTenantBilling || isLoadingSubscriptionBilling) && <SkeletonRows rowNum={3} colNum={10} />}
                {isTenantBillingSuccess && isSubscriptionBillingSuccess && billingTableRowData.length === 0 && (
                  <EmptyRow colSpan={10} title="표시할 정산이 없습니다." />
                )}
                {billingTableRowData.map((rowData, idx) =>
                  rowData instanceof TenantMonthlyBillingModel ? (
                    <TenantBillingRow ref={setBillingRef(idx)} key={rowData.id} rowData={rowData} />
                  ) : (
                    <SubscriptionBillingRow ref={setBillingRef(idx)} key={rowData.id} rowData={rowData} />
                  ),
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </BillingListWrapper>
      </Container>
      <AddExistingBillingModal open={isAddModalOpen} onClose={() => setAddModalOpen(false)} />
    </>
  );
};

const Container = styled.section`
  padding: 28px 32px;
  width: 100%;
  height: calc(100vh - var(--manager-gnb-height));
  overflow-y: auto;
`;

const Title = styled.h2`
  ${fonts.Headline5}
  padding-bottom: 14px;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors['border-gray-lighter']};
  margin: 0px 8px;
`;

const TotalElementsWrapper = styled.span`
  display: inline-block;
  ${fonts.Headline8};
  margin: 0px 8px 10px;
`;

const SubtitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${fonts.Headline7}
  margin-top: 32px;
`;

const Subtitle = styled.h3`
  ${fonts.Headline7}
  margin: 0px 8px;
`;

const TotalElements = styled.b`
  color: ${({ theme: { colors } }) => colors['text-purple-light']};
`;

const TotalBox = styled.div`
  display: flex;
  margin-bottom: 48px;
  gap: 16px;
`;

const TotalAmountBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
  border: 1px solid ${({ theme: { colors } }) => colors['border-purple-light']};
  background-color: ${({ theme: { colors } }) => colors['bg-purple-lighter']};
  border-radius: 5px;
`;

const TotalText = styled.span`
  ${fonts.Headline8}
  color: ${({ theme: { colors } }) => colors['text-gray-sub-darker']};
  margin-left: 8px;
`;

const Money = styled.span`
  ${fonts.Headline5}
  color: ${({ theme: { colors } }) => colors['text-purple']};
  margin-left: 16px;
`;

const CellWithIcon = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
`;

const BillingListWrapper = styled.div`
  border: 1px solid ${({ theme: { colors } }) => colors['border-gray-lighter']};
  border-width: 0px 1px 1px 1px;
  border-radius: 0px 0px 7px 7px;
  padding: 48px 28px 28px;
`;
