import { useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import { HorizontalContainer } from '@components/container/HorizontalContainer';
import { Button } from '@components/index';
import { useUiStore } from '@stores/UiStore';
import { fonts } from '@theme/fontsCustomer';
import { TrashIcon } from '@icons/TrashIcon';
import type { MonthlyBillingUpdateDTO } from '@repositories/monthlyBillingRepository';
import { useFileDialog } from '@customHooks/useFileDialog';
import type { MonthlyBillingUpdateHookForm } from '@hookForms/MonthlyBillingUpdateHookForm';
import { useMonthlyBillingUpdateForm } from '@hookForms/MonthlyBillingUpdateHookForm';
import {
  useDeleteMonthlyBilling,
  useDeleteMonthlyBillingAttachment,
  useUpdateMonthlyBilling,
  useUploadMonthlyBillingAttachments,
} from '@queryHooks/useMonthlyBilling';
import { dateFormat } from '@utils/dateUtil';
import type { SubscriptionBillingModel } from '@models/SubscriptionBillingModel';
import type { SubscriptionModel } from '@models/SubscriptionModel';
import type { MetricUsageCostModel } from '@models/billing/MetricUsageCostModel';
import { type FileSimpleModel } from '@models/file/FileSimpleModel';
import { BillingAttachmentInfo } from '../../BillingAttachmentInfo';
import { BillingInfo } from './BillingInfo';
import { MetricCostDetail } from './MetricCostDetail';
import { SubscriptionInfoModal } from './subscriptionInfoModal/SubscriptionInfoModal';

type Props = {
  subscription: SubscriptionModel;
  subscriptionBilling: SubscriptionBillingModel;
  metricUsageCosts: MetricUsageCostModel[];
  PrintButton?: React.ReactNode;
};
export const SubscriptionBillingDetailView = ({
  subscription,
  subscriptionBilling: billing,
  metricUsageCosts,
  PrintButton,
}: Props) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const { alertStore, toastStore } = useUiStore();
  const navigate = useNavigate();
  const [isSubscriptionInfoModalVisible, setSubscriptionInfoModalVisible] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  const isPrintView = !PrintButton;

  const formMethods = useMonthlyBillingUpdateForm(billing);
  const { handleSubmit, reset } = formMethods;

  const { openFileDialog, dummyFiles, addDummy, setDummyFinished } = useFileDialog({
    fileLength: billing.attachments.length,
  });
  const { mutate: uploadFile } = useUploadMonthlyBillingAttachments();
  const { mutate: deleteFile } = useDeleteMonthlyBillingAttachment();
  const { mutate: deleteMonthlyBilling } = useDeleteMonthlyBilling();
  const { mutate: updateMonthlyBilling, isLoading: isUpdateLoading } = useUpdateMonthlyBilling();

  const validation = {
    success: async (data: MonthlyBillingUpdateHookForm) => {
      const rqData: MonthlyBillingUpdateDTO = {
        id: null,
        subscriptionId: billing.subscriptionId,
        subscriptionVersion: billing.version,
        month: billing.month,

        cost: data.cost,
        currencyUnit: billing.currencyUnit,
        originalCost: data.originalCost ?? null,
        originalCurrencyUnit: billing.originalCurrencyUnit,
        exchangeRate: data.exchangeRate ?? null,

        startDate: billing.startDate ?? null,
        endDate: billing.endDate ?? null,

        billingDate: billing.billingDate ?? null,
        paymentDate: data.paymentDate ? dayjs(data.paymentDate).format('YYYY-MM-DD') : null,

        moduleUsageCosts: data.moduleUsageCosts,

        status: billing.status ?? null,
      };
      await updateMonthlyBilling({ billingId: billing.id, rqData });

      setIsEditMode(false);
    },
  };

  const handleAttachFileClick = () => {
    if (billing.attachments.length >= 10) {
      alertStore.open({
        title: '파일 첨부 실패',
        message: '최대 10개의 파일을 첨부할 수 있습니다.\n첨부된 파일을 삭제 후 다시 시도해주세요.',
      });
    } else {
      openFileDialog(fileList => {
        const files = Array.from(fileList);
        if (billing.attachments.length + files.length > 10) {
          alertStore.open({
            title: '파일 첨부 실패',
            message: '최대 10개의 파일을 첨부할 수 있습니다.\n첨부된 파일을 삭제 후 다시 시도해주세요.',
          });
        }

        const validFileList = files.filter(({ size }) => size <= 5242880);
        if (validFileList.length !== files.length) {
          alertStore.open({ title: '파일 첨부', message: '5MB 이하의 파일만 첨부할 수 있습니다.' });
        }

        let resultCount = 0;
        validFileList.slice(0, 10 - billing.attachments.length).forEach(file => {
          const dummy = addDummy(file.name);
          uploadFile({
            billingId: billing.id,
            files: [file],
            config: {
              onUploadProgress: progress => {
                if (progress.progress === 1) {
                  setDummyFinished(dummy.fileId);
                  resultCount += 1;
                  if (resultCount === validFileList.length) {
                    toastStore.open('파일 첨부를 완료했습니다.');
                  }
                }
              },
            },
          });
        });
      });
    }
  };

  const handleDeleteFileClick = (attachment: FileSimpleModel) => {
    alertStore.open({
      title: '첨부 파일 삭제',
      message: '첨부 파일을 삭제합니다.\n해당 작업은 되돌릴 수 없습니다.',
      type: 'error',
      confirmName: '삭제',
      onConfirm: () => {
        deleteFile(
          { billingId: billing.id, fileId: attachment.fileId },
          {
            onSuccess: () => {
              toastStore.open('첨부 파일 삭제를 완료했습니다.');
            },
          },
        );
      },
      useCancel: true,
    });
  };

  const handleSubscriptionInfoClick = () => {
    setSubscriptionInfoModalVisible(true);
  };

  const handleSubscriptionInfoModalClose = () => {
    setSubscriptionInfoModalVisible(false);
  };

  const handleDeleteMonthlyBilling = () => {
    alertStore.open({
      title: t('Acc_Del_01'),
      message: t('Acc_Del_02'),
      confirmName: t('Subscrib_Detail_BuyRequest_12'),
      onConfirm: () => {
        deleteMonthlyBilling(billing.id, {
          onSuccess: () => {
            toastStore.open({ message: t('Acc_Del_03'), hideAction: true });
            navigate(-1);
          },
        });
      },
      useCancel: true,
      type: 'error',
    });
  };

  const handleClickEditBtn = () => {
    setIsEditMode(true);
  };

  const handleClickCancelBtn = () => {
    setIsEditMode(false);
    reset();
  };

  return (
    <FormProvider {...formMethods}>
      <Header>
        {billing?.subscription.software.iconUrl ? (
          <Logo alt="logo" src={`${billing?.subscription.software.iconUrl}`} width={56} height={56} />
        ) : null}
        <div>
          <SoftwareName>{billing?.subscription.software.name}</SoftwareName>
          <SubscriptionWrapper>
            <SubscriptionName>{billing?.subscription.name}</SubscriptionName>
            {!isPrintView && (
              <Button variant="outline" size="xsmall" paddingHorizontal={5} onClick={handleSubscriptionInfoClick}>
                구독 정보
              </Button>
            )}
          </SubscriptionWrapper>
        </div>
        {PrintButton}
        {!isPrintView && billing.isExternalSubscription && (
          <DeleteButton
            variant="outline"
            size="large"
            startIcon={<TrashIcon width={20} height={20} color={colors['ic-red-light']} />}
            paddingHorizontal={57.5}
            onClick={handleDeleteMonthlyBilling}
          >
            삭제
          </DeleteButton>
        )}
      </Header>
      {billing.isExternalSubscription ? (
        <Divider />
      ) : (
        <DescriptionGrid>
          <DescriptionItem>
            <dt>{t('Acc_Detail_01')}</dt>
            <dd>-</dd>
          </DescriptionItem>
          <DescriptionItem>
            <dt>{t('Acc_Detail_08')}</dt>
            <dd>{billing.provider?.name ?? '-'}</dd>
          </DescriptionItem>
          <DescriptionItem>
            <dt>{t('Acc_Detail_02')}</dt>
            <dd>{dateFormat(dayjs(billing.createDate), '-', t('DateFormat_YMD'))}</dd>
          </DescriptionItem>
          <DescriptionItem>
            <dt>{t('Acc_Detail_09')}</dt>
            <dd>{billing.provider?.businessRegistrationNumber ?? '-'}</dd>
          </DescriptionItem>
          <DescriptionItem>
            <dt>{t('Acc_Detail_03')}</dt>
            <dd>-</dd>
          </DescriptionItem>
          <DescriptionItem>
            <dt>{t('Acc_Detail_10')}</dt>
            <dd>{billing.provider?.address ?? '-'}</dd>
          </DescriptionItem>
          <DescriptionItem>
            <dt>{t('Acc_Detail_04')}</dt>
            <dd>
              {dateFormat(billing.startDate, '-', t('DateFormat_YMD'))} ~{' '}
              {dateFormat(billing.endDate, '-', t('DateFormat_YMD'))}
            </dd>
          </DescriptionItem>
          <DescriptionItem>
            <dt>{t('Acc_Detail_11')}</dt>
            <dd>{billing.provider?.representative ?? '-'}</dd>
          </DescriptionItem>
          <DescriptionItem>
            <dt>{t('Acc_Detail_05')}</dt>
            <dd>-</dd>
          </DescriptionItem>
          <DescriptionItem>
            <dt>{t('Acc_Detail_12')}</dt>
            <dd>-</dd>
          </DescriptionItem>
        </DescriptionGrid>
      )}

      {!isPrintView && (
        <BillingAttachmentInfo
          dummyFiles={dummyFiles}
          attachments={billing.attachments}
          onAttachFileClick={handleAttachFileClick}
          onDeleteFileClick={handleDeleteFileClick}
        />
      )}

      <MonthlyBillingUpdateForm id="monthlyBillingUpdateForm" onSubmit={handleSubmit(validation.success)}>
        <SubtitleWrapper>
          <Subtitle>{t('Acc_Detail_20')}</Subtitle>
          {!isEditMode ? (
            <Button
              disabled={!billing.isExternalSubscription || !PrintButton}
              variant="outline"
              size="small"
              paddingHorizontal={16}
              onClick={handleClickEditBtn}
            >
              {t('Edit')}
            </Button>
          ) : (
            <HorizontalContainer gap="5px">
              <Button
                form="monthlyBillingUpdateForm"
                type="submit"
                disabled={isUpdateLoading}
                variant="contain"
                size="small"
                paddingHorizontal={16}
              >
                {t('Save')}
              </Button>
              <Button variant="outline" size="small" paddingHorizontal={16} onClick={handleClickCancelBtn}>
                {t('Cancel')}
              </Button>
            </HorizontalContainer>
          )}
        </SubtitleWrapper>
        <BillingInfo
          subscription={subscription}
          billing={billing}
          isEditable={billing.isExternalSubscription && !!PrintButton}
          isEditMode={isEditMode}
        />

        <SubtitleWrapper>
          <Subtitle>{t('Acc_Detail_19')}</Subtitle>
        </SubtitleWrapper>
        <MetricCostDetail billing={billing} metricUsageCosts={metricUsageCosts} isEditMode={isEditMode} />
      </MonthlyBillingUpdateForm>
      {!isPrintView && (
        <SubscriptionInfoModal
          open={isSubscriptionInfoModalVisible}
          onClose={handleSubscriptionInfoModalClose}
          subscriptionId={billing.subscriptionId}
        />
      )}
    </FormProvider>
  );
};

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`;

const Logo = styled.img`
  width: 56px;
  height: 56px;
  margin-right: 16px;
`;

const SoftwareName = styled.div`
  ${fonts.Body1}
`;

const SubscriptionWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const SubscriptionName = styled.span`
  ${fonts.Headline4}
`;

const DescriptionGrid = styled.dl`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 40px;
  margin-bottom: 48px;
`;

const DescriptionItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 20px;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors['border-gray-w-lighter']};
  &:nth-of-type(-n + 2) {
    border-top: 1px solid ${({ theme: { colors } }) => colors['border-gray-dark']};
  }

  & > dt {
    ${fonts.Headline9}
    color: ${({ theme: { colors } }) => colors['text-gray-sub-dark']};
  }

  & > dd {
    ${fonts.Headline8}
    color: ${({ theme: { colors } }) => colors['text-gray-main']};
  }
`;

const Divider = styled.hr`
  margin: 0px 0px 32px 0px;
  border: 1px solid ${({ theme: { colors } }) => colors['border-gray-lighter']};
  border-bottom: 0px;
`;

const SubtitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const Subtitle = styled.h3`
  display: inline-block;
  ${fonts.Headline8}
  margin: 8px 0px 4px 8px;
`;

const DeleteButton = styled(Button)`
  margin-left: 12px;
  color: ${({ theme: { colors } }) => colors['text-red-light']};
  box-shadow: 0px 1px 6px 0px #0000000f;
`;

const MonthlyBillingUpdateForm = styled.form``;
