import { TotalBillingDTO } from '@repositories/monthlyBillingRepository';

export class TotalBillingModel {
  private dto: TotalBillingDTO;

  constructor(dto: TotalBillingDTO) {
    this.dto = dto;
  }

  get totalPayment() {
    return this.dto.totalPayment;
  }

  get totalSettlement() {
    return this.dto.totalSettlement;
  }

  get totalSettlementWithForecasted() {
    if (this.dto.totalSettlement === undefined || this.dto.totalForecastedSettlement === undefined) {
      return undefined;
    }

    return this.dto.totalSettlement + this.dto.totalForecastedSettlement;
  }
}
