import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import { Box, Button, FormControlLabel, IconButton, TextField, Typography, Checkbox, useTheme } from '@mui/material';
import { useGoogleLogin } from '@react-oauth/google';
import { AxiosError } from 'axios';
import { Button as CommonBtn } from '@components/index';
import { PageTemplate } from '@components/template';
import { useStore } from '@stores/RootStore';
import { fonts } from '@theme/fontsCustomer';
import { GoogleColorIcon } from '@icons/GoogleColorIcon';
import { CheckboxIcon, EyeIcon, EyeSplashIcon } from '@icons/index';
import { useGoogleSignIn } from '@queryHooks/useGoogleOauth';
import { useSignIn } from '@queryHooks/useUser';
import { useSignForm } from './validationSchema';

type Props = {
  isItAdminPage?: boolean;
};
export const SignIn = ({ isItAdminPage }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const { tenantId } = useParams();

  const {
    uiStore: { alertStore },
  } = useStore();
  const [isAuthenticationFailed, setAuthenticationFailed] = useState<boolean>(false);
  const [invalidTenant, setInvalidTenant] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useSignForm();

  const passwordValue = watch('password');

  const { mutate: login, isError } = useSignIn(false);
  const { mutate: googleSignIn } = useGoogleSignIn();

  const loginPostProcess = () => {
    if (location.state?.routeAfterLogin) {
      navigate(location.state.routeAfterLogin);
    } else {
      navigate(isItAdminPage ? `/manager/${tenantId}/home` : '/');
    }
  };

  const validationHandler = {
    success: (data: any) => {
      login(data, {
        onSuccess: loginPostProcess,
        onError: (err: AxiosError | Error) => {
          if (err instanceof AxiosError) {
            setAuthenticationFailed(true);
            setInvalidTenant(false);
          } else if (err.message === 'INVALID_TENANT') {
            setAuthenticationFailed(false);
            setInvalidTenant(true);
          }
        },
      });
    },
  };
  const handleClickShowPassword = () => setShowPassword(show => !show);
  const handleIntroduceInquiryClick = () => {
    navigate('/tenant-request');
  };

  useEffect(() => {
    setTimeout(() => {
      setAuthenticationFailed(false);
      setInvalidTenant(false);
    }, 5000);
  }, [isAuthenticationFailed]);

  const googleLogin = useGoogleLogin({
    onSuccess: codeResponse => {
      googleSignIn(
        { authCode: codeResponse.code as string },
        {
          onSuccess: loginPostProcess,
          onError: () => {
            alertStore.open({
              title: t('Sign_In_01'),
              message: t('Sign_In_02'),
              useCancel: false,
            });
          },
        },
      );
    },
    flow: 'auth-code',
  });

  const handleClickSearchPassword = () => {
    navigate('/search-password');
  };

  return (
    <PageTemplate>
      <Container $isItAdminPage={isItAdminPage}>
        <WrapperForm onSubmit={handleSubmit(validationHandler.success)}>
          <LoginTitleWrapper>
            <Typography variant="headline3">{t('GNB_Menu_05')}</Typography>
          </LoginTitleWrapper>
          <LoginInputsWrapper>
            <EmailField
              {...register('username', { required: t('Account_Validation_Check_02') })}
              size="large"
              fullWidth
              placeholder={t('Product_Inquiry_03')}
              helperText={errors.username?.message}
              error={!!errors.username?.message || isError}
              autoFocus
            />
            <PasswordField
              {...register('password', { required: t('Account_Validation_Check_03') })}
              className="password-field"
              size="large"
              fullWidth
              placeholder={t('GNB_Profile_MyProfile_07')}
              type={showPassword ? 'text' : 'password'}
              helperText={
                errors.password?.message ||
                (isAuthenticationFailed && t('Account_Validation_Check_01')) ||
                (invalidTenant && '접근 권한이 없는 계정입니다.')
              }
              error={!!errors.password?.message || isError}
              InputProps={{
                endAdornment: (
                  <PasswordToggleButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    className={passwordValue.length === 0 ? 'hide-toggle-btn' : undefined}
                    edge="end"
                  >
                    {showPassword ? (
                      <EyeIcon width={20} height={20} color={theme.colors['ic-gray-light']} />
                    ) : (
                      <EyeSplashIcon width={20} height={20} color={theme.colors['ic-gray-light']} />
                    )}
                  </PasswordToggleButton>
                ),
              }}
            />
          </LoginInputsWrapper>
          <PasswordSearchWrapper>
            <Button variant="text" onClick={handleClickSearchPassword}>
              <Typography component="span" variant="button4">
                {t('GNB_Profile_MyProfile_09')}
              </Typography>
            </Button>
          </PasswordSearchWrapper>
          <LoginButton
            variant="contained"
            size="2extraLarge"
            fullWidth
            sx={{ background: `${theme.colors['bg-gradient']}` }}
            type="submit"
          >
            <Typography component="span" variant="button2" color={theme.colors['text-white']}>
              {t('GNB_Menu_05')}
            </Typography>
          </LoginButton>
          <AutoLoginWrapper>
            <FormCheckLabel
              control={
                <Checkbox
                  size="small"
                  icon={<CheckboxIcon color={theme.colors['border-gray-light']} />}
                  checkedIcon={
                    <CheckboxIcon
                      width={16}
                      height={16}
                      bgColor={theme.colors['bg-purple']}
                      color={theme.colors['bg-white']}
                    />
                  }
                />
              }
              label={<CheckLabel variant="body2">{t('Login_Auto_Check')}</CheckLabel>}
            />
          </AutoLoginWrapper>
          <DividerText variant="headline9" color={theme.colors['text-gray-light']}>
            {t('Login_String_01')}
          </DividerText>
          <GoogleBtn size="2extraLarge" variant="contain" onClick={() => googleLogin()}>
            <GoogleColorIcon />
            <span>{t('Login_Google')}</span>
          </GoogleBtn>
          <QuestionsWrapper>
            {!isItAdminPage && (
              <Question>
                <span>{t('Login_String_02')}</span>
                <TextButton size="small" variant="text" onClick={handleIntroduceInquiryClick}>
                  {t('Sign_In_03')}
                </TextButton>
              </Question>
            )}
            <Question>
              <span>{t('Sign_In_04')}</span>
              <TextButton size="small" variant="text">
                {t('Subscrib_Detail_Overview_26')}
              </TextButton>
            </Question>
          </QuestionsWrapper>
        </WrapperForm>
      </Container>
    </PageTemplate>
  );
};
SignIn.displayName = 'Customer.SignIn';

const Container = styled.div<{ $isItAdminPage?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({ $isItAdminPage }) => ($isItAdminPage ? '194px' : '130px')} 0px 0px 0px;
  min-height: calc(100vh - 130px - var(--employee-gnb-height));
  height: 100%;
  background-color: ${({ theme }) => theme.colors['bg-white']};
`;

const WrapperForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 380px;
`;

const LoginTitleWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
`;

const LoginInputsWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  margin-bottom: 14px;
`;

const PasswordSearchWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  margin-bottom: 30px;
  & button {
    padding: 0;
    height: auto;
    & .MuiTouchRipple-root {
      display: none;
    }
    &:hover {
      & > span.MuiTypography-root {
        color: ${({ theme }) => theme.colors['state-purple-hover']};
      }
    }
  }
`;

const AutoLoginWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-top: 14px;
  margin-bottom: 56px;
  align-items: center;
  width: 100%;
`;

const QuestionsWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  margin-top: 40px;
`;

const Question = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ${fonts.Headline8};
  color: ${props => props.theme.colors['text-gray-sub-dark']};
`;

const EmailField = styled(TextField)`
  & .MuiInputBase-root {
    height: 54px;
    & .MuiInputBase-input {
      padding: 15px 0;
    }
  }
  & .MuiInputBase-input::placeholder {
    ${({ theme }) => theme.colors['text-gray-sub-dark']};
    ${fonts.Body1};
  }
`;

const PasswordField = styled(TextField)`
  & .MuiInputBase-input::placeholder {
    ${({ theme }) => theme.colors['text-gray-sub-dark']};
    ${fonts.Body1};
  }

  & .hide-toggle-btn {
    display: none;
  }
`;

const DividerText = styled(Typography)`
  position: relative;
  &:after,
  &:before {
    content: '';
    display: inline-block;
    position: absolute;
    top: 50%;
    width: 160px;
    height: 1px;
    background: ${({ theme }) => theme.colors['border-gray-light']};
  }
  &:after {
    transform: translate(-201px, -50%);
  }
  &:before {
    transform: translate(41px, -50%);
  }
`;

const LoginButton = styled(Button)`
  box-shadow: none;
  border: none;
  padding: 15px 24px;
  &:hover {
    box-shadow: none;
    border: none;
    background: ${({ theme }) => theme.colors['state-gradient-hover']};
  }
`;

const PasswordToggleButton = styled(IconButton)`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: cneter;
  justify-content: center;
  flex-shrink: 0;
  & .MuiTouchRipple-root {
    display: none;
  }
  &:hover {
    background: none;
    svg {
      path {
        fill: ${({ theme }) => theme.colors['ic-purple-light']};
      }
    }
  }
`;

const TextButton = styled(CommonBtn)`
  height: 20px;
  padding: 0;
  flex-shrink: 0;
  color: ${({ theme }) => theme.colors['text-purple-light']};
  & .MuiTouchRipple-root {
    display: none;
  }
  &:hover {
    background: none;
  }
`;

const FormCheckLabel = styled(FormControlLabel)`
  margin: 0;
`;

const CheckLabel = styled(Typography)`
  margin-left: 8px;
` as typeof Typography;

const GoogleBtn = styled(CommonBtn)`
  margin-top: 36px;
  width: 380px;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 15px 0;
  border: 1px solid ${props => props.theme.colors['border-gray-dark']};
  border-radius: 3px;
  background: ${props => props.theme.colors['bg-gray-lighter']};
  color: ${props => props.theme.colors['text-gray-main']};
  ${fonts.Button2}

  & > span {
    gap: 8px;
  }

  &:hover {
    background: ${props => props.theme.colors['bg-gray-lighter']};
  }
`;
