import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import { IconButton, Switch } from '@mui/material';
import dayjs from 'dayjs';
import { observer } from 'mobx-react';
import { Label } from '@components/Label';
import { HorizontalContainer } from '@components/container/HorizontalContainer';
import { FormItemContainer } from '@components/form/FormItemContainer';
import { FormLabel } from '@components/form/FormLabel';
import { FormVerticalContainer } from '@components/form/FormVerticalContainer';
import { DateText } from '@components/text/DateText';
import { MessageText } from '@components/text/MessageText';
import { NoDataText } from '@components/text/NoDataText';
import { DescriptionEditEndAdornment, DescriptionEditTextField } from '@components/textfield/DescriptionEditTextField';
import { useStore } from '@stores/RootStore';
import { fonts } from '@theme/fontsCustomer';
import { PencilIcon } from '@icons/PencilIcon';
import { useActiveWorkflow, useDeactiveWorkflow, useUpdateWorkflow } from '@queryHooks/useWorkflow';
import type { WorkflowModel } from '@models/workflow/WorkflowModel';
import { getErrorMessage } from '@locales/i18nErrorMessage';
import { InnerTrigger } from '../trigger/Trigger';

interface Props {
  workflow: WorkflowModel;
}

export const WorkflowBasicInfoView = observer(({ workflow }: Props) => {
  const { t } = useTranslation();
  const {
    workflowDetailStore,
    uiStore: { toastStore, alertStore },
  } = useStore();
  const params = useParams();
  const { workflowId, tenantId } = params;

  const [isDescriptionEdit, setIsDescriptionEdit] = useState<boolean>(false);
  const [description, setDescription] = useState<string>(workflow.description);

  const { mutate: updateWorkflow } = useUpdateWorkflow(workflowId ?? '');
  const { mutate: activeWorkflow } = useActiveWorkflow(workflowId ?? '');
  const { mutate: deactiveWorkflow } = useDeactiveWorkflow(workflowId ?? '');

  const handleChangeDescription = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(e.target.value);
  };

  const updateWorkflowHandler = {
    onSuccess: () => {
      setIsDescriptionEdit(false);
      toastStore.open(t('Workflow_Create_70'));
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: any) => {
      alertStore.open({
        title: t('Workflow_Create_72'),
        message: getErrorMessage(error).message,
      });
    },
  };

  const handleConfirmDescription = () => {
    const workflowCreateDTO = workflowDetailStore.toWorflowCreateDTO(tenantId ?? '');
    if (!workflowCreateDTO) return;
    updateWorkflow({ ...workflowCreateDTO, description }, updateWorkflowHandler);
  };

  const handleToggleStatus = (checked: boolean) => {
    if (checked) {
      activeWorkflow(undefined, updateWorkflowHandler);
    } else {
      deactiveWorkflow(undefined, updateWorkflowHandler);
    }
  };

  return (
    <Container>
      <ToolbarName>기본 정보</ToolbarName>
      <Body>
        <FormVerticalContainer>
          <FormItemContainer>
            <HorizontalContainer gap="5px">
              <FormLabel>설명</FormLabel>
              <IconButton
                onClick={() => {
                  setIsDescriptionEdit(true);
                }}
              >
                <PencilIcon width={14} height={14} />
              </IconButton>
            </HorizontalContainer>
            <DescriptionEditTextField
              value={description}
              onChange={handleChangeDescription}
              disabled={!isDescriptionEdit}
              multiline
              rows={2}
              InputProps={{
                endAdornment: isDescriptionEdit && (
                  <DescriptionEditEndAdornment
                    textLength={description.length}
                    maxTextLength={100}
                    onConfirm={handleConfirmDescription}
                    onCancel={() => setIsDescriptionEdit(false)}
                  />
                ),
              }}
            />
          </FormItemContainer>
          <FormItemContainer>
            <FormLabel>트리거</FormLabel>
            <InnerTrigger trigger={workflow.trigger.baseTrigger} />
          </FormItemContainer>
          <FormItemContainer>
            <FormLabel>상태</FormLabel>
            <HorizontalContainer gap="5px">
              <Switch checked={workflow.status === 'ACTIVE'} onChange={(_, checked) => handleToggleStatus(checked)} />
              <Label variant="solid" color={workflow.status === 'ACTIVE' ? 'green' : 'red'}>
                {workflow.status}
              </Label>
            </HorizontalContainer>
          </FormItemContainer>
          <FormItemContainer>
            <FormLabel>마지막 실행</FormLabel>

            {workflow.latestExecutionStatus ? (
              <>
                <HorizontalContainer gap="5px">
                  <Label variant="solid" color={workflow.isLatestExecutionSuccess ? 'green' : 'red'}>
                    {workflow.latestExecutionStatusStr}
                  </Label>
                  <DateText>
                    {workflow.latestExecutionTime
                      ? dayjs(workflow.latestExecutionTime).format('YYYY-MM-DD HH:mm:ss')
                      : '-'}
                  </DateText>
                </HorizontalContainer>
                {workflow.latestExecutionStatusMessage && (
                  <MessageText>{workflow.latestExecutionStatusMessage}</MessageText>
                )}
              </>
            ) : (
              <NoDataText>아직 실행되지 않음.</NoDataText>
            )}
          </FormItemContainer>
        </FormVerticalContainer>
      </Body>
    </Container>
  );
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  background: inherit;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: inherit;
  border: 0px;
  padding: 28px 0 20px;
  position: relative;
`;

const ToolbarName = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  ${fonts.Headline7};
  color: ${({ theme: { colors } }) => colors['text-gray-main']};
`;
