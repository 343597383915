import type { Software, ModuleUsagePrice } from '@repositories/softwareRepository';
import type { ContractCondition, SubscriptionSnapshot, SubscriptionType } from '@repositories/subscriptionRepository';
import type { RealtimeUsage } from '@repositories/usage';
import { ProductPlanModel } from './softwareModels/ProductPlanModel';

export class SubscriptionSnapshotModel {
  private _subscriptionId: string;
  private _subscriptionVersion: number;
  private _parentId: string;
  private _tenantId: string;
  private _software: Software;
  private _softwareContractId: string;
  private _softwareId: string;
  private _contractId: string;
  private _type: SubscriptionType;
  private _name: string;
  private _description: string;
  private _startDate: string;
  private _endDate: string;
  private _timeZone: string;
  private _contractConditions: ContractCondition[];
  private _productPlan: ProductPlanModel;
  private _moduleUsagePrices: ModuleUsagePrice[];
  private _currencyUnit: CurrencyUnit;
  private _properties: { [key: string]: string };
  private _realtimeUsage: RealtimeUsage;

  constructor(dto?: SubscriptionSnapshot) {
    this._subscriptionId = dto?.subscriptionId || '';
    this._subscriptionVersion = dto?.subscriptionVersion || 0;
    this._parentId = dto?.parentId || '';
    this._tenantId = dto?.tenantId || '';
    this._software = dto?.software ?? { id: '', productCode: '', name: '', planVisibilityScope: 'ALL_USERS' };
    this._softwareContractId = dto?.softwareContractId ?? '';
    this._softwareId = dto?.softwareId || '';
    this._contractId = dto?.contractId || '';
    this._type = dto?.type || 'ON_DEMAND';
    this._name = dto?.name || '';
    this._description = dto?.description || '';
    this._startDate = dto?.startDate || '';
    this._endDate = dto?.endDate || '';
    this._productPlan = new ProductPlanModel(dto?.productPlan);
    this._timeZone = dto?.timeZone || '';
    this._contractConditions = dto?.contractConditions || [];
    this._moduleUsagePrices = dto?.moduleUsagePrices || [];
    this._currencyUnit = dto?.currencyUnit || 'KRW';
    this._properties = dto?.properties || {};
    this._realtimeUsage = dto?.realtimeUsage || { dateTime: '', subscriptionId: '', usageList: [] };
  }

  get productPlan() {
    return this._productPlan;
  }

  get subscriptionId() {
    return this._subscriptionId;
  }

  get subscriptionVersion() {
    return this._subscriptionVersion;
  }

  get parentId() {
    return this._parentId;
  }

  get tenantId() {
    return this._tenantId;
  }

  get software() {
    return this._software;
  }

  get softwareContractId() {
    return this._softwareContractId;
  }

  get softwareId() {
    return this._softwareId;
  }

  get contractId() {
    return this._contractId;
  }

  get type() {
    return this._type;
  }

  get name() {
    return this._name;
  }

  get description() {
    return this._description;
  }

  get startDate() {
    return this._startDate;
  }

  get endDate() {
    return this._endDate;
  }

  get timeZone() {
    return this._timeZone;
  }

  get contractConditions() {
    return this._contractConditions;
  }

  get moduleUsagePrices() {
    return this._moduleUsagePrices;
  }

  get currencyUnit() {
    return this._currencyUnit;
  }

  get properties() {
    return this._properties;
  }

  get realtimeUsage() {
    return this._realtimeUsage;
  }

  get isExternalSubscription() {
    return this._type === 'EXTERNAL';
  }
}
