import { useLayoutEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import type { Connection, NodeTypes } from 'reactflow';
import ReactFlow from 'reactflow';
import { useTheme, keyframes, css } from '@emotion/react';
import styled from '@emotion/styled';
import { observer } from 'mobx-react';
import { Label } from '@components/Label';
import { useStore } from '@stores/RootStore';
import { fonts } from '@theme/fontsCustomer';
import DotBG from '@images/workflow_BG.png';
import type { WorkflowExecStatus } from '@repositories/workflowRepository/Types';
import type { WorkflowExecModel } from '@models/workflow/WorkflowExecModel';
import type { WorkflowModel } from '@models/workflow/WorkflowModel';
import { ActionNode } from './ActionNode';
import { ConditionThenEdge } from './ConditionThenEdge';
import { EndNode } from './EndNode';
import { ExceptionEdge } from './ExceptionEdge';
import { NormalEdge } from './NormalEdge';
import { TriggerNode } from './TriggerNode';

const edgeTypes = {
  normal: NormalEdge,
  exception: ExceptionEdge,
  conditionThen: ConditionThenEdge,
};

const nodeTypes: NodeTypes = {
  trigger: TriggerNode,
  action: ActionNode,
  end: EndNode,
};

interface Props {
  workflow?: WorkflowModel;
  execution?: WorkflowExecModel;
}

const isBlinkingStatus = (status: WorkflowExecStatus) => {
  return status === 'READY' || status === 'RUNNING' || status === 'STOP_REQUESTED';
};

export const WorkflowViewer = observer(({ workflow, execution }: Props) => {
  const theme = useTheme();
  const location = useLocation();
  const isCreate = location.pathname.includes('create');
  const workflowViewerRef = useRef<HTMLDivElement | null>(null);

  const { workflowDetailStore } = useStore();
  const isEdit = workflowDetailStore.isEdit;
  const isEditMode = workflowDetailStore.checkEditable();
  const nodes = workflowDetailStore.nodes.map(node => ({
    ...node,
    position: { x: (workflowViewerRef?.current?.offsetWidth ?? 0) / 3 - 196 + node.position.x, y: node.position.y },
  }));

  useLayoutEffect(() => {
    if (!isCreate && !workflow) return;

    workflowDetailStore.initNodes(isCreate || isEdit, workflow, execution);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, workflow]);

  const handleConnect = (connection: Connection) => {
    workflowDetailStore.connectNodes(connection);
  };

  const handlePaneClick = () => {
    if (!isEditMode && !workflowDetailStore.execution) {
      workflowDetailStore.curNodeId = '';
      if (workflowDetailStore.toolbar === '') {
        workflowDetailStore.setToolbarView('workflowInfo');
      } else {
        workflowDetailStore.setToolbarView('');
      }
    }
  };

  return (
    <Viewer ref={workflowViewerRef}>
      <StyledReactFlow
        nodes={nodes}
        edges={workflowDetailStore.edges.map(edge => ({
          ...edge,
          style: { stroke: theme.colors['border-gray-light'] },
        }))}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        onConnect={handleConnect}
        onPaneClick={handlePaneClick}
      />
      {(isCreate || isEdit) && (
        <EditModeLabel variant="outline" color="purple">
          수정 모드
        </EditModeLabel>
      )}
      {execution && (
        <ExecutionStatusLabel variant="outline" color="purple" $isBlinking={isBlinkingStatus(execution.status)}>
          {execution.status}
        </ExecutionStatusLabel>
      )}
    </Viewer>
  );
});

const Viewer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  height: 100%;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  background: url(${DotBG}) ${props => props.theme.colors['bg-gray-lighter']};
  background-size: 1500px;
  background-repeat: repeat;
  padding: 44px 0px 28px 0px;
  position: relative;
`;

const StyledReactFlow = styled(ReactFlow)`
  .react-flow__handle-top {
    top: -5px;
  }
  .react-flow__handle-bottom {
    bottom: -5px;
  }
`;

const EditModeLabel = styled(Label)`
  ${fonts.Headline9};
  position: absolute;
  top: 12px;
  left: 12px;
  padding: 1px 10px;
  border-radius: 3px;
`;

const blink = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0.4; }
  100% { opacity: 1; }
`;

const ExecutionStatusLabel = styled(Label)<{ $isBlinking: boolean }>`
  ${fonts.Headline9};
  position: absolute;
  top: 12px;
  left: 12px;
  padding: 1px 10px;
  border-radius: 3px;
  animation: ${props =>
    props.$isBlinking
      ? css`
          ${blink} 1.5s infinite
        `
      : 'none'};
`;
