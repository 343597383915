import { ExchangeRateDTO } from '@repositories/exchangeRateRepository/Types';

export class ExchangeRateModel {
  private dto: ExchangeRateDTO;

  constructor(dto: ExchangeRateDTO) {
    this.dto = dto;
  }

  get baseCurrencyUnit() {
    return this.dto.baseCurrencyUnit;
  }

  get createdBy() {
    return this.dto.createdBy;
  }

  get createdDate() {
    return this.dto.createdDate;
  }

  get creatorID() {
    return this.dto.creator?.id ?? '';
  }

  get creatorName() {
    return this.dto.creator?.name ?? '';
  }

  get creatorUserName() {
    return this.dto.creator?.username ?? '';
  }

  get date() {
    return this.dto.date;
  }

  get exchangeRate() {
    return this.dto.exchangeRate;
  }

  get id() {
    return this.dto.id;
  }

  get source() {
    return this.dto.source;
  }

  get targetCurrencyUnit() {
    return this.dto.targetCurrencyUnit;
  }

  get updateBy() {
    return this.dto.updateBy;
  }

  get updatedDate() {
    return this.dto.updatedDate;
  }

  get updaterID() {
    return this.dto.updater?.id ?? '';
  }

  get updaterName() {
    return this.dto.updater?.name ?? '';
  }

  get updaterUserName() {
    return this.dto.updater?.username ?? '';
  }
}
