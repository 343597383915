import type { Dayjs } from 'dayjs';
import { ChangeEvent, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { TextField, css } from '@mui/material';
import dayjs from 'dayjs';
import { DatePicker } from '@components/datePicker';
import { Button, Dropdown } from '@components/index';
import { CurrencyInput } from '@pages/customer/common/CurrencyInput';
import { useStore } from '@stores/RootStore';
import { fonts } from '@theme/fontsCustomer';
import { BulletIcon } from '@icons/BulletIcon';
import { DashIcon } from '@icons/index';
import { useGetExchangeRates } from '@queryHooks/useExchangeRate';
import { useGetTenant } from '@queryHooks/useTenant';
import { useGetMonthlyBillingInfo } from '@queryHooks/useUserMonthlyBilling';
import { CurrencyUnits } from '@utils/commonVars';
import { dateFormat } from '@utils/dateUtil';
import { SubscriptionModel } from '@models/SubscriptionModel';
import { AddMonthlyBillingForm } from '../validateSchema';

type Props = {
  selectedMonth: Dayjs;
  subscription: SubscriptionModel;
  onClose: () => void;
  onNextStep: () => void;
};
export const BillingCreateForm = ({ selectedMonth, subscription, onClose, onNextStep }: Props) => {
  const { t } = useTranslation();
  const { authStore } = useStore();
  const { colors } = useTheme();

  const { control, formState, watch, setValue, trigger, reset } = useFormContext<AddMonthlyBillingForm>();
  const [formExchangeRate, formOriginalCost, formPaymentDate, formCurrencyUnit] = watch([
    'exchangeRate',
    'originalCost',
    'paymentDate',
    'currencyUnit',
  ]);

  const { data: tenant } = useGetTenant(authStore.managerTenantInfo.id);
  const { data: exchangeRates } = useGetExchangeRates(
    {
      dateFrom: dateFormat(formPaymentDate, undefined, 'YYYY-MM-DD'),
      dateTo: dateFormat(formPaymentDate, undefined, 'YYYY-MM-DD'),
      baseCurrencyUnit: subscription.currencyUnit,
      sort: ['createdDate.desc'],
    },
    {
      enabled: !!formPaymentDate,
    },
  );

  useEffect(() => {
    if (formPaymentDate && formCurrencyUnit) {
      const exchangeRateModel = exchangeRates?.content.find(
        ({ targetCurrencyUnit }) => targetCurrencyUnit === formCurrencyUnit,
      );

      setValue('exchangeRate', exchangeRateModel?.exchangeRate ?? 0);
    }
  }, [exchangeRates, formCurrencyUnit, formPaymentDate, setValue]);

  const { data: billingInfo } = useGetMonthlyBillingInfo({
    subscriptionId: subscription.id,
    month: selectedMonth.format('YYYY-MM'),
  });

  useEffect(() => {
    reset({
      startDate: billingInfo?.startDate ?? null,
      endDate: billingInfo?.endDate ?? null,
      currencyUnit: tenant?.currencyUnit,
      paymentDate: billingInfo?.paymentDate ?? null,
    });
  }, [billingInfo, reset, tenant]);

  const handleValueChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const numberValue = parseFloat(e.target.value);
    if (!Number.isNaN(numberValue)) {
      switch (e.target.name) {
        case 'originalCost':
          if (formExchangeRate) {
            setValue('cost', numberValue * parseFloat(formExchangeRate as unknown as string));
          }
          break;
        case 'cost':
          setValue('exchangeRate', numberValue / parseFloat(formOriginalCost as unknown as string));
          break;
        case 'exchangeRate':
          setValue('cost', parseFloat(formOriginalCost as unknown as string) * numberValue);
          break;
      }
    }
  };

  const handleDateOnChange =
    (onChange: UnknownFunction, type: 'startDate' | 'endDate' | 'paymentDate') => (value: string | null) => {
      onChange(value);
      if (type) {
        trigger(type);
      }
    };

  const handleNextStep = () => {
    const nextStepIfValid = async () => {
      const valid = await trigger([
        'startDate',
        'endDate',
        'paymentDate',
        'originalCost',
        'currencyUnit',
        'exchangeRate',
        'cost',
      ]);
      if (valid) {
        onNextStep();
      }
    };
    nextStepIfValid();
  };

  return (
    <>
      <Content>
        <PeriodLabel>
          <p>
            <Trans
              i18nKey="Acc_Create_16_01"
              values={{ Date: dateFormat(selectedMonth, '-', 'YYYY.MM') }}
              components={{ b: <b /> }}
            />
          </p>
          <p>{t('Acc_Create_16_02')}</p>
        </PeriodLabel>
        <Field>
          <FieldKey $required>{t('Acc_Create_09')}</FieldKey>
          <DatePickerWrapper>
            <Controller
              control={control}
              name="startDate"
              render={({ field: { value, ref, onChange } }) => (
                <DatePicker
                  inputFormat={t('DateFormat_YMD')}
                  value={value}
                  onChange={handleDateOnChange(onChange, 'startDate')}
                  ref={ref}
                  renderInput={params => <DatePicker.Input {...params} sx={{ width: '260px' }} />}
                  showDaysOutsideCurrentMonth
                />
              )}
            />
            <DashIcon width={10} height={10} color={colors['ic-gray-dark']} />
            <Controller
              control={control}
              name="endDate"
              render={({ field: { value, ref, onChange } }) => (
                <DatePicker
                  inputFormat={t('DateFormat_YMD')}
                  value={value}
                  onChange={handleDateOnChange(onChange, 'endDate')}
                  ref={ref}
                  renderInput={params => <DatePicker.Input {...params} sx={{ width: '260px' }} />}
                  showDaysOutsideCurrentMonth
                />
              )}
            />
          </DatePickerWrapper>
        </Field>
        <Field>
          <FieldKey $required htmlFor="payment-date">
            {t('Acc_Main_20')}
          </FieldKey>
          <Controller
            control={control}
            name="paymentDate"
            render={({ field: { value, ref, onChange } }) => (
              <DatePicker
                inputFormat={t('DateFormat_YMD')}
                value={value}
                onChange={handleDateOnChange(onChange, 'paymentDate')}
                ref={ref}
                renderInput={params => <DatePicker.Input {...params} id="payment-date" fullWidth />}
              />
            )}
          />
          {dayjs(formPaymentDate).isValid() === false && formState.errors.paymentDate && (
            <ErrorMessage>{formState.errors.paymentDate?.message}</ErrorMessage>
          )}
        </Field>
        <Field>
          <FieldKey $required htmlFor="original-cost">
            {t('Acc_Main_18')}
          </FieldKey>
          <FlexBox>
            <CurrencyUnit>{subscription.currencyUnit}</CurrencyUnit>
            <div>
              <Controller
                control={control}
                name="originalCost"
                render={({ field: { value, ref, onChange, name } }) => (
                  <TextField
                    id="original-cost"
                    ref={ref}
                    name={name}
                    value={value}
                    onChange={e => {
                      handleValueChange(e);
                      onChange(e);
                    }}
                    sx={{ width: '398px' }}
                    placeholder={t('Acc_Create_28')}
                    InputProps={{ inputComponent: CurrencyInput }}
                  />
                )}
              />
              {formState.errors.originalCost && <ErrorMessage>{formState.errors.originalCost?.message}</ErrorMessage>}
            </div>
          </FlexBox>
        </Field>
        <Field>
          <FieldKey $required htmlFor="currency-unit">
            {t('Acc_Create_26')}
          </FieldKey>
          <Controller
            control={control}
            name="currencyUnit"
            render={({ field: { value, onChange, ref } }) => (
              <Dropdown id="currency-unit" ref={ref} value={value ?? ''} onChange={onChange} width="100%" size="large">
                {CurrencyUnits.map(currencyUnit => (
                  <Dropdown.Menu key={currencyUnit} value={currencyUnit}>
                    {currencyUnit}
                  </Dropdown.Menu>
                ))}
              </Dropdown>
            )}
          />
          {formState.errors.currencyUnit && <ErrorMessage>{formState.errors.currencyUnit?.message}</ErrorMessage>}
        </Field>
        <Field>
          <FieldKey $required>{t('Acc_Create_10')}</FieldKey>
          <Controller
            control={control}
            name="exchangeRate"
            render={({ field: { value, ref, onChange, name } }) => (
              <TextField
                ref={ref}
                name={name}
                value={value}
                onChange={e => {
                  handleValueChange(e);
                  onChange(e);
                }}
                fullWidth
                placeholder="정산 환율을 입력해주세요."
                InputProps={{ inputComponent: CurrencyInput }}
              />
            )}
          />
          {formState.errors.exchangeRate && <ErrorMessage>{formState.errors.exchangeRate?.message}</ErrorMessage>}
        </Field>
        <Field>
          <FieldKey $required>{t('Acc_Main_19')}</FieldKey>
          <Controller
            control={control}
            name="cost"
            render={({ field: { value, ref, onChange, name } }) => (
              <TextField
                ref={ref}
                name={name}
                value={value}
                onChange={e => {
                  handleValueChange(e);
                  onChange(e);
                }}
                fullWidth
                placeholder={t('Acc_Create_27')}
                InputProps={{ inputComponent: CurrencyInput }}
              />
            )}
          />
          {formState.errors.cost && <ErrorMessage>{formState.errors.cost?.message}</ErrorMessage>}
        </Field>

        <Info>
          <BulletIcon width={6} height={6} color={colors['ic-gray-lighter']} />
          {t('Acc_Create_12')}
        </Info>
      </Content>
      <Footer>
        <Button type="submit" variant="contain" size="extraLarge" paddingHorizontal={99.5}>
          완료
        </Button>
        <Button variant="outline" size="extraLarge" paddingHorizontal={98.5} onClick={onClose}>
          {t('GNB_Profile_MyProfile_11')}
        </Button>
      </Footer>
    </>
  );
};

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 24px 28px 0px 28px;
`;

const Footer = styled.div`
  display: flex;
  padding: 24px 0px 28px;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

const PeriodLabel = styled.div`
  ${fonts.Body2}
  margin-bottom: 18px;

  & b {
    ${fonts.Headline8}
    color: ${({ theme: { colors } }) => colors['text-purple']}
  }

  & > p + p {
    margin-top: 2px;
  }
`;

const DatePickerWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 7px;
`;

const Field = styled.div`
  & + & {
    margin-top: 24px;
  }
`;

const FieldKey = styled.label<{ $required?: boolean }>`
  display: block;
  ${fonts.Headline8}
  margin-bottom: 6px;
  ${({ $required }) =>
    $required
      ? css`
          &:after {
            content: '*';
            color: red;
            margin-left: 2px;
          }
        `
      : ''}

  &.disabled {
    color: ${({ theme }) => theme.colors['text-gray-light']};
    &:after {
      color: ${({ theme }) => theme.colors['text-red-lighter']};
    }
  }
`;

const ErrorMessage = styled.p`
  ${fonts.Body4}
  color: ${({ theme: { colors } }) => colors['text-red-light']};
  margin-top: 2px;
`;

const CurrencyUnit = styled.div`
  display: inline-block;
  width: 140px;
  height: 40px;
  padding: 9px 12px;
  ${fonts.Body2}
  background-color: ${({ theme: { colors } }) => colors['bg-gray-light']};
  border: 1px solid ${({ theme: { colors } }) => colors['border-gray-light']};
  margin-right: 6px;
`;

const FlexBox = styled.div`
  display: flex;
`;

const Info = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
  padding: 10px 16px;
  border-radius: 5px;
  background-color: ${({ theme: { colors } }) => colors['bg-gray-light-50']};
  gap: 6px;
  ${fonts.Body2}
  color: ${({ theme: { colors } }) => colors['text-gray-sub-dark']};
`;
