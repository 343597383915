import type {
  SubscriptionUseRequestDetail,
  SubscriptionUseRequestStatus,
} from '@repositories/subscriptionUseRequestRepository/Types';
import { SubscriptionUseRequestHistoryModel } from './SubscriptionUseRequestHistoryModel';

export class SubscriptionUseRequestDetailModel {
  private _createdDate: string;
  private _endDate: string;
  private _histories: SubscriptionUseRequestHistoryModel[];
  private _id: string;
  private _inquiry: string;
  private _planName: string;
  private _productId: string;
  private _productName: string;
  private _productPlanId: string;
  private _seq: number;
  private _startDate: string;
  private _status: SubscriptionUseRequestStatus;
  private _isInUseByTenant: boolean;
  private _timeZone: string;
  private _updateDate: string;
  private _categoryId: string;
  private _userGroup: {
    name?: string;
    userGroupId?: string;
  }[];
  private _userName: string;

  constructor(dto?: SubscriptionUseRequestDetail) {
    this._createdDate = dto?.createdDate || '';
    this._id = dto?.id || '';
    this._planName = dto?.planName || '';
    this._productName = dto?.productName || '';
    this._seq = dto?.seq || 0;
    this._status = dto?.status || 'CREATED';
    this._userName = dto?.userName || '';
    this._endDate = dto?.endDate || '';
    this._inquiry = dto?.inquiry || '';
    this._startDate = dto?.startDate || '';
    this._isInUseByTenant = dto?.isInUseByTenant ?? false;
    this._userGroup = dto?.userGroup || [{ name: '', userGroupId: '' }];
    this._userName = dto?.userName || '';
    this._updateDate = dto?.updateDate || '';
    this._categoryId = dto?.categoryId || '';
    this._histories =
      dto?.histories
        .map(history => new SubscriptionUseRequestHistoryModel(history))
        .sort((a, b) => (a.createdDate > b.createdDate ? 1 : -1)) || [];
    this._productId = dto?.productId || '';
    this._productPlanId = dto?.productPlanId || '';
    this._timeZone = dto?.timeZone || '';
  }

  get productId(): string {
    return this._productId;
  }

  get productPlanId(): string {
    return this._productPlanId;
  }

  get timeZone(): string {
    return this._timeZone;
  }

  get histories(): SubscriptionUseRequestHistoryModel[] {
    return this.status === 'CREATED'
      ? this._histories.filter(({ status }) => status === 'CREATED')
      : this._histories.filter(({ status }) => status !== 'CREATED');
  }

  get updateDate(): string {
    return this._updateDate;
  }

  get createdDate(): string {
    return this._createdDate;
  }

  get endDate(): string {
    return this._endDate;
  }

  get startDate(): string {
    return this._startDate;
  }

  get isInUseByTenant(): boolean {
    return this._isInUseByTenant;
  }

  get userGroup() {
    return this._userGroup;
  }

  get inquiry(): string {
    return this._inquiry;
  }

  get id(): string {
    return this._id;
  }

  get planName(): string {
    return this._planName;
  }

  get productName(): string {
    return this._productName;
  }

  get seq(): number {
    return this._seq;
  }

  get status(): SubscriptionUseRequestStatus {
    return this._status;
  }

  get userName(): string {
    return this._userName;
  }

  get categoryId(): string {
    return this._categoryId;
  }

  get isCompleted(): boolean {
    return this.status === 'APPROVED' || this.status === 'REJECTED';
  }

  /**
   * histories 중 완료(APPROVED) 상태인 history만 찾아서 반환하는 getter.
   * 순서에 상관없이 어차피 APPROVED 상태가 마지막 history이니 status로 찾아서 반환하도록 함.
   */
  get completeHistory(): SubscriptionUseRequestHistoryModel | undefined {
    return this.histories.find(({ status }) => status === 'APPROVED');
  }
}
