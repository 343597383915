import { SubscriptionCountStat, SubscriptionCountStatKey } from '@repositories/SubscriptionCountStatRepository';
import i18next from 'i18next';

export class SubscriptionCountStatModel {
  private dto: SubscriptionCountStat;

  constructor(dto: SubscriptionCountStat) {
    this.dto = dto;
  }

  get endSubscriptionCount(): number {
    return this.dto.endSubscriptionCount || 0;
  }

  get monthlyEndingCount(): number {
    return this.dto.monthlyEndingCount || 0;
  }

  get monthlyIncrement(): number {
    return this.dto.monthlyIncrement || 0;
  }

  get monthlyStartingCount(): number {
    return this.dto.monthlyStartingCount || 0;
  }

  get requestedSubscriptionCount(): number {
    return this.dto.requestedSubscriptionCount || 0;
  }

  get subscriptionCount(): number {
    return this.dto.subscriptionCount || 0;
  }

  get totalCount(): number {
    return this.dto.totalCount || 0;
  }

  get errorSubscriptionCount(): number {
    return this.dto.errorSubscriptionCount || 0;
  }

  get subscriptionTypeName(): { [key in SubscriptionCountStatKey]: string } {
    return {
      totalCount: i18next.t('Acc_Main_12'),
      subscriptionCount: i18next.t('Subscrib_Main_02'),
      monthlyStartingCount: i18next.t('Subscrib_Main_03'),
      monthlyEndingCount: i18next.t('Subscrib_Main_04'),
      endSubscriptionCount: i18next.t('Subscrib_Main_05'),
      errorSubscriptionCount: i18next.t('Subscrib_Main_06'),
    };
  }
}
