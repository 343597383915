import { ProductPlanListDTO } from '@repositories/softwareRepository';

export class ProductPlanListModel {
  private _currencyUnit: CurrencyUnit;
  private _description: string;
  private _featureSet: string;
  private _isBasicPlan: boolean;
  private _planGrade: number;
  private _planId: string;
  private _planName: string;
  private _isInUseByTenant: boolean;
  private _planType: 'ADD_ON' | 'MAIN';
  private _pricingType:
    | 'Free'
    | 'FLAT_RATE'
    | 'USAGE_BASED'
    | 'USAGE_COMMITMENT'
    | 'PER_USER'
    | 'PER_ACTIVE_USER'
    | 'PER_CONCURRENT_USER'
    | 'BY_USAGE_RANGE'
    | 'CUSTOM';

  constructor(dto: ProductPlanListDTO) {
    this._currencyUnit = dto.currencyUnit ?? 'KRW';
    this._description = dto.description ?? '';
    this._featureSet = dto.featureSet ?? '';
    this._isBasicPlan = dto.isBasicPlan ?? false;
    this._planGrade = dto.planGrade ?? -1;
    this._planId = dto.planId;
    this._planName = dto.planName ?? '';
    this._planType = dto.planType ?? 'ADD_ON';
    this._pricingType = dto.pricingType ?? 'CUSTOM';
    this._isInUseByTenant = dto.isInUseByTenant ?? false;
  }

  get isInUseByTenant() {
    return this._isInUseByTenant;
  }

  get currencyUnit() {
    return this._currencyUnit;
  }

  get description() {
    return this._description;
  }

  get featureSet() {
    return this._featureSet;
  }

  get isBasicPlan() {
    return this._isBasicPlan;
  }

  get planGrade() {
    return this._planGrade;
  }

  get planId() {
    return this._planId;
  }

  get planName() {
    return this._planName;
  }

  get planType() {
    return this._planType;
  }

  get pricingType() {
    return this._pricingType;
  }
}
