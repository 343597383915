import { UseFormProps, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import i18next from 'i18next';
import * as Yup from 'yup';

export type FormType = {
  username: string;
  password: string;
};

export const useSignForm = () => {
  const { t } = useTranslation();
  const validationSchema = Yup.object().shape({
    username: Yup.string().required(t('Member_Manager_14')).email(t('Member_Manager_17')),
    password: Yup.string().required(t('Account_Validation_Check_03')).min(8, t('Sign_In_05')).max(20, t('Sign_In_06')),
  });
  const formOptions: UseFormProps<FormType> = {
    resolver: yupResolver(validationSchema),
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    shouldFocusError: true,
    defaultValues: {
      password: '',
    },
  };
  return useForm<FormType>(formOptions);
};
