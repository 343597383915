import { ConnectorResp } from '@repositories/subscriptionConnectionRepository/Types';
import { ConnectorActionModel } from './ConnectorActionModel';

export class ConnectorRespModel {
  private _addUserSchema?: string;
  private _actions: ConnectorActionModel[];
  private _id: string;
  private _name: string;
  private _productCode: string;
  private _targetClass: string;
  private _type: 'JAVA' | 'JAVASCRIPT';
  private _connectionInfoSchema: string;

  constructor(dto: ConnectorResp) {
    this._addUserSchema = dto.addUserSchema;
    this._actions = dto.actions?.length ? dto.actions.map(action => new ConnectorActionModel(action)) : [];
    this._id = dto.id || '';
    this._name = dto.name || '';
    this._productCode = dto.productCode || '';
    this._targetClass = dto.productCode || '';
    this._type = dto.type || 'JAVASCRIPT';
    this._connectionInfoSchema = dto.connectionInfoSchema || '';
  }

  get addUserSchema() {
    return this._addUserSchema;
  }

  get connectionInfoSchema() {
    return this._connectionInfoSchema;
  }

  get connectionInfoSchemaJson() {
    return JSON.parse(this._connectionInfoSchema);
  }

  get actions() {
    return this._actions;
  }

  get id() {
    return this._id;
  }

  get name() {
    return this._name;
  }

  get productCode() {
    return this._productCode;
  }

  get targetClass() {
    return this._targetClass;
  }

  get type() {
    return this._type;
  }
}
