import { CartProductModel } from '@models/softwareModels/CartProductModel';
import { makeAutoObservable } from 'mobx';

export class OrderModifyModalStore {
  private _visible: boolean;
  private _product?: CartProductModel;
  private _idx?: number;
  private _onChangeChartItems?: () => void;

  constructor() {
    this._visible = false;
    this._product = undefined;
    this._idx = undefined;
    this._onChangeChartItems = undefined;
    makeAutoObservable(this);
  }

  get visible(): boolean {
    return this._visible;
  }

  open = (product: CartProductModel, idx: number, onChangeChartItems: () => void) => {
    this._visible = true;
    this._idx = idx;
    this._product = product;
    this._onChangeChartItems = onChangeChartItems;
  };

  close = () => {
    if (this._onChangeChartItems) this._onChangeChartItems();
    this.clear();
  };

  private clear = () => {
    this._visible = false;
  };

  get product(): CartProductModel {
    if (this._product) return this._product;
    throw new Error('no Product');
  }

  get idx(): number {
    if (this._idx !== undefined) return this._idx;
    throw new Error('no idx');
  }
}
