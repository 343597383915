import { Dialog } from '@components/Dialog';
import { Autocomplete, TextField, useTheme } from '@mui/material';
import { Controller, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Button } from '@components/index';
import debounce from 'lodash.debounce';
import { DomUtil } from '@utils/DomUtil';
import { useGetTenantMembersInfinite } from '@queryHooks/useTenant';
import { useStore } from '@stores/RootStore';
import { TenantMemberSimpleModel } from '@models/TenantMemberSimpleModel';
import { useEffect, useState } from 'react';
import { useAddSubscriptionUser } from '@queryHooks/useSubscription';
import { SubscriptionUserRole } from '@repositories/subscriptionRepository';
import { TenantMemberFilter } from '@repositories/tenantRepository';
import { ErrorIcon } from '@icons/ErrorIcon';
import styled from '@emotion/styled';
import { fonts } from '@theme/fontsCustomer';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import { AddUserForm, useAddUserForm } from './validationSchema';
import * as S from './LinkUserDialogStyles';

type Props = {
  subscriptionId: string;
  open: boolean;
  onClose: () => void;
};

export type AutocompleteOption = Pick<TenantMemberSimpleModel, 'id' | 'name' | 'email'>;

export const AddUserDialog = ({ subscriptionId, open, onClose }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const {
    authStore,
    uiStore: { toastStore },
  } = useStore();
  const [memberFilter, setMemberFilter] = useState<Omit<TenantMemberFilter, 'page'>>({ size: 100, keyword: undefined });

  const hookForm = useAddUserForm();

  useEffect(() => {
    if (open) {
      hookForm.reset({ userRole: 'USER' });
    }
  }, [hookForm, open]);

  const { mutate: addSubscriptionUser } = useAddSubscriptionUser();

  const handleValid: SubmitHandler<AddUserForm> = formData => {
    addSubscriptionUser(
      {
        subscriptionId,
        userStatus: 'ACTIVE',
        userEmail: formData.user.email,
        userLoginId: formData.userLoginId,
        userRole: formData.userRole as SubscriptionUserRole,
        userId: formData.user.id,
        userName: formData.user.name,
      },
      {
        onSuccess: () => {
          toastStore.open(`구독 사용자 추가를 완료했습니다.`);
          onClose();
        },
      },
    );
  };

  const {
    isSuccess: isUserListSuccess,
    data: userListPage,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useGetTenantMembersInfinite(authStore.managerTenantInfo.id, { ...memberFilter });

  const options: AutocompleteOption[] = isUserListSuccess
    ? userListPage.pages.reduce(
        (accum, cur) => [...accum, ...cur.content.map(({ id, name, email }) => ({ id, name, email }))],
        [] as AutocompleteOption[],
      )
    : [];

  const handleAutocompleteScroll = debounce((event: React.UIEvent<HTMLUListElement>) => {
    if (DomUtil.isScrollBottom(event.target as HTMLUListElement) && !isFetchingNextPage && hasNextPage) {
      fetchNextPage();
    }
  }, 100);

  const handleMemberSearchChange = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
    setMemberFilter(prev => ({ ...prev, keyword: e.target.value }));
  }, 1000);

  return (
    <Dialog open={open} onClose={onClose} title={t('Subscrib_Detail_User_18')} height={600}>
      <FormProvider {...hookForm}>
        <S.Form onSubmit={hookForm.handleSubmit(handleValid)}>
          <S.Field>
            <S.FieldName>
              {t('Workflow_Main_05')}
              <strong>*</strong>
            </S.FieldName>
            <Controller
              control={hookForm.control}
              name="user"
              render={({ field: { ref, value, onChange } }) => (
                <Autocomplete
                  value={value}
                  onChange={(event, value) => onChange(value)}
                  options={options}
                  getOptionLabel={option => `${option.name} (${option.email})`}
                  renderInput={params => (
                    <TextField
                      ref={ref}
                      placeholder={t('Subscrib_Detail_User_23')}
                      variant="outlined"
                      onChange={handleMemberSearchChange}
                      {...params}
                      sx={{
                        '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                          borderRadius: '2px 2px 0 0',
                        },
                        '& .MuiInputBase-input.Mui-disabled': {
                          color: `${theme.colors['text-gray-light']}`,
                        },
                      }}
                    />
                  )}
                  ListboxProps={{ onScroll: handleAutocompleteScroll, role: 'list-box' }}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  noOptionsText={<AutocompleteNoOptions />}
                  sx={{
                    '& .MuiInputBase-root': {
                      padding: '10px 12px',
                    },
                    '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
                      padding: 0,
                    },
                  }}
                  componentsProps={{
                    paper: {
                      sx: {
                        width: '100%',
                        maxHeight: '328px',
                        marginTop: 0,
                        borderRadius: '0 0 2px 2px',
                        boxShadow: '0',
                        border: `1px solid ${theme.colors['border-gray-light']}`,
                        borderWidth: '0 1px 1px 1px',
                        overflow: 'hidden',
                        '& .MuiAutocomplete-listbox': {
                          height: '328px',
                          '& .MuiAutocomplete-option': {
                            display: 'block',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          },
                          '& .MuiAutocomplete-option[aria-selected="true"]': {
                            background: `${theme.colors['state-white-hover']}`,
                            color: `${theme.colors['text-purple-light']}`,
                          },
                        },
                        '& .MuiList-root': {
                          border: 'none',
                          padding: '4px 0',
                          '& .MuiMenuItem-root': {
                            margin: '0 5px',
                          },
                        },
                      },
                    },
                  }}
                />
              )}
            />
          </S.Field>
          <S.Field>
            <S.FieldName>
              {t('Subscrib_Detail_User_11')}
              <strong>*</strong>
            </S.FieldName>
            <TextField {...hookForm.register('userLoginId')} fullWidth placeholder={t('Subscrib_Detail_User_24')} />
          </S.Field>
          <S.Field>
            <S.FieldName>{t('Member_User_17')}</S.FieldName>
            <TextField
              {...hookForm.register('userRole')}
              fullWidth
              placeholder={t('Add_User_Dialog_Authority_Selection')}
            />
          </S.Field>
          <Dialog.Footer>
            <Button variant="contain" size="extraLarge" type="submit">
              {t('Member_Manager_11')}
            </Button>
            <Button variant="outline" size="extraLarge" type="button" onClick={onClose}>
              {t('Subscrib_Detail_BuyRequest_13')}
            </Button>
          </Dialog.Footer>
        </S.Form>
      </FormProvider>
    </Dialog>
  );
};

const AutocompleteNoOptions = () => {
  return (
    <AutocompleteNoOptionsContainer>
      <ErrorIconWrapper>
        <ErrorIcon width={22} height={22} />
      </ErrorIconWrapper>
      <p>{t('Subscrib_Edit_11')}</p>
    </AutocompleteNoOptionsContainer>
  );
};

const AutocompleteNoOptionsContainer = styled.div`
  padding: 16px 0px;
  ${fonts.Body2}
  text-align: center;
`;

const ErrorIconWrapper = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: rgba(245, 240, 255, 1);
  margin: 0 auto 12px;
  padding: 7px;
`;
