import React, { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Box, TextField } from '@mui/material';
import dayjs from 'dayjs';
import { Button } from '@components/index';
import { fonts } from '@theme/fontsCustomer';
import { SearchIcon } from '@icons/SearchIcon';
import { SubscriptionCountStatKey } from '@repositories/SubscriptionCountStatRepository';
import { Filters } from '@repositories/subscriptionRepository';
import { useLocalStorage } from '@customHooks/useLocalStorage';
import { useSearchParamsFilter } from '@customHooks/useSearchParamsFilter';
import { useGetSubscriptionListInfinite, useSubscriptionCountStat } from '@queryHooks/index';
import { ObjectUtil } from '@utils/ObjectUtil';
import { SubscriptionListModel } from '@models/SubscriptionListModel';
import { SubscriptionFilterCard } from './SubscriptionFilterCard';
import { SubscriptionTable } from './subscriptionTable';
import { AddExistingSubscriptionModal } from './subscriptionTable/addExistingSubscriptionModal';

export const SubscriptionList: React.FC = () => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const [scrollTarget, setScrollTarget] = useState<HTMLElement | null>(null);

  /**
   * TODO: 맥북 Sonoma에서 dayjs.tz.guess()가 undefined를 반환하는 버그가 있어서 임시로 처리한 코드
   * 참고: https://github.com/iamkun/dayjs/issues/2448
   */
  const [customTZ] = useLocalStorage('customTimezone', 'Asia/Seoul');

  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const handleAddModal = {
    open: () => {
      setAddModalOpen(true);
    },
    close: () => {
      setAddModalOpen(false);
    },
  };
  const [clickedType, setClickedType] = useState<SubscriptionCountStatKey>('totalCount');

  const [queries, setQueries, clearQueries] = useSearchParamsFilter<Filters>({
    defaultFilter: { keyword: undefined, sort: ['hasError.desc'] },
    whiteList: ['sort', 'keyword', 'softwareId', 'startDateFrom', 'startDateTo', 'endDateFrom', 'endDateTo'],
  });

  const { data: subscriptionCountStat, isSuccess } = useSubscriptionCountStat(dayjs.tz.guess() ?? customTZ);

  const {
    isLoading: isSubscriptionListPageLoading,
    data: subscriptionListPage,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useGetSubscriptionListInfinite({
    ...queries,
    status:
      queries.status === 'ALL'
        ? 'OPEN,REQUESTED,ACTIVE,INACTIVE'
        : queries.status === 'OPEN'
        ? 'OPEN,REQUESTED'
        : queries.status,
  });

  const handleFilterClick = (typeName: SubscriptionCountStatKey) => () => {
    // isAfter: 특정날짜보다 이전 인지
    // isBefore : 특정날짜보다 이후 인지
    // isSame : 특정 날짜와 일치한지
    if (typeName === 'totalCount') {
      // '전체'
      clearQueries();
    } else if (typeName === 'subscriptionCount') {
      // '구독 중'
      setQueries({
        startDateTo: dayjs().format('YYYY-MM-DD'),
        startDateFrom: undefined,
        endDateTo: undefined,
        endDateFrom: dayjs().format('YYYY-MM-DD'),
        status: undefined,
        hasOnDemand: true,
      });
    } else if (typeName === 'monthlyStartingCount') {
      // '구독 시작 예정'
      setQueries({
        startDateTo: dayjs().add(1, 'month').format('YYYY-MM-DD'),
        startDateFrom: dayjs().add(1, 'day').format('YYYY-MM-DD'),
        endDateTo: undefined,
        endDateFrom: undefined,
        status: undefined,
        hasOnDemand: false,
      });
    } else if (typeName === 'monthlyEndingCount') {
      // '구독 종료 예정'
      setQueries({
        startDateTo: undefined,
        startDateFrom: undefined,
        endDateTo: dayjs().add(1, 'month').format('YYYY-MM-DD'),
        endDateFrom: dayjs().add(1, 'day').format('YYYY-MM-DD'),
        status: undefined,
        hasOnDemand: false,
      });
    } else if (typeName === 'endSubscriptionCount') {
      // '구독 종료'
      setQueries({
        startDateTo: undefined,
        startDateFrom: undefined,
        endDateTo: dayjs().add(1, 'day').format('YYYY-MM-DD'),
        endDateFrom: undefined,
        status: undefined,
        hasOnDemand: false,
      });
    }
    setClickedType(typeName);
  };

  const handleTableFilterUpdate = (filter: Partial<Filters>) => {
    setClickedType('totalCount');

    setQueries(prev => ({ ...prev, ...filter }));
  };

  const subscriptionList: SubscriptionListModel[] =
    subscriptionListPage?.pages.map(({ content }) => content).flat(1) ?? [];

  const handleEndReached = () => {
    if (!isFetchingNextPage && hasNextPage) {
      fetchNextPage();
    }
  };
  const handleKeywordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setQueries(prev => ({ ...prev, keyword: event.target.value }));
  };

  const subscriptionStatusKeys = isSuccess ? ObjectUtil.keys(subscriptionCountStat.subscriptionTypeName) : [];

  return (
    <>
      <Container ref={setScrollTarget}>
        <Content>
          <SectionTitle>현황 조회</SectionTitle>
          <CardContainer>
            {isSuccess
              ? subscriptionStatusKeys.map((statusKey, index) => (
                  <SubscriptionFilterCard
                    key={statusKey}
                    status={statusKey}
                    statusName={subscriptionCountStat.subscriptionTypeName[statusKey]}
                    count={subscriptionCountStat[statusKey]}
                    monthlyIncrementRate={subscriptionCountStat.monthlyIncrement}
                    onClick={statusKey === 'errorSubscriptionCount' ? undefined : handleFilterClick(statusKey)}
                    // onMouseEnter={handleMouseEnter(typeName)}
                    className={
                      clickedType === statusKey
                        ? 'clicked'
                        : subscriptionStatusKeys.findIndex(statusKey => clickedType === statusKey) - 1 === index
                        ? 'prevButton'
                        : ''
                    }
                  />
                ))
              : null}
          </CardContainer>
          <HeaderContainer>
            <NumOfSubscription>
              {t('Acc_Main_12')}
              <span>{subscriptionListPage?.pages[0].totalElements}</span>
            </NumOfSubscription>
            <div className="right-wrapper">
              <TextField
                size="small"
                placeholder={t('Subscrib_Connect_Create_01')}
                value={queries.keyword}
                onChange={handleKeywordChange}
                InputProps={{
                  startAdornment: (
                    <IconWrapper>
                      <SearchIcon width={16} height={16} color={colors['ic-gray-light']} />
                    </IconWrapper>
                  ),
                }}
              />
              <Button variant="contain" size="small" paddingHorizontal={16} onClick={handleAddModal.open}>
                {t('Subscrib_Create_01')}
              </Button>
            </div>
          </HeaderContainer>
          <SubscriptionTable
            isLoading={isSubscriptionListPageLoading}
            filter={queries}
            subscriptionList={subscriptionList}
            scrollTarget={scrollTarget}
            onUpdateFilter={handleTableFilterUpdate}
            endReached={handleEndReached}
          />
        </Content>
      </Container>
      <AddExistingSubscriptionModal open={isAddModalOpen} onClose={handleAddModal.close} />
    </>
  );
};

const Container = styled.section`
  width: 100%;
  height: calc(100vh - var(--manager-gnb-height));
  overflow: auto;
  padding: 28px 32px;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
`;
const SectionTitle = styled('strong')`
  ${fonts.Headline5};
  margin: 0px 0px 14px 8px;
`;
const CardContainer = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  overflow: auto;
  margin-bottom: 48px;
`;
const HeaderContainer = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;

  & > .right-wrapper {
    display: flex;
    gap: 8px;
  }
`;
const NumOfSubscription = styled('strong')`
  margin-left: 8px;
  margin-top: 6px;
  ${fonts.Headline7}
  color: ${({ theme }) => theme.colors['text-gray-main']};

  & span {
    margin-left: 4px;
    color: ${({ theme }) => theme.colors['text-purple-light']};
  }
`;
const IconWrapper = styled('div')`
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
`;
