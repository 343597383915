import { CategorySimple } from '@repositories/softwareCategoryRepository';

export class CategorySimpleModel {
  private _id: string;
  private _code: string;
  private _name: string;
  private _description?: string;
  private _iconUrl: string;

  constructor(dto?: CategorySimple) {
    this._id = dto?.id || '';
    this._code = dto?.code || '';
    this._name = dto?.name || '';
    this._description = dto?.description || '';
    this._iconUrl = dto?.iconUrl || '';
  }

  get id() {
    return this._id;
  }

  get code() {
    return this._code;
  }

  get name() {
    return this._name;
  }

  get description() {
    return this._description;
  }

  get iconUrl() {
    return this._iconUrl;
  }
}
