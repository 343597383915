import { useLocation } from 'react-router-dom';
import * as S from './LnbStyles';

type Menu = {
  name: string;
  icon?: React.ReactNode;
  selectedIcon?: React.ReactNode;
} & (
  | {
      to: string;
      subMenu?: never;
    }
  | {
      to?: never;
      subMenu: Menu[];
    }
);

type LnbProps = {
  menuItem: Menu[];
};
export const LNB = ({ menuItem }: LnbProps) => {
  return (
    <S.Nav>
      {menuItem.map(menu => (
        <MenuItem key={menu.name} menu={menu} menuItem={menuItem} />
      ))}
    </S.Nav>
  );
};

type MenuItemProps = {
  menu: Menu;
  menuItem: Menu[];
};
const MenuItem = ({ menu: { name, to, icon, selectedIcon, subMenu }, menuItem }: MenuItemProps) => {
  const location = useLocation();
  const curMenuUrl = location.pathname.split('/')[4] ?? '';

  const recursiveFlatMenu = (items: Menu[]) => {
    const result: Menu[] = [];

    items.forEach(item => {
      if (typeof item.to === 'string') {
        result.push(item);
      } else if (item.subMenu.length > 0) {
        result.push(...recursiveFlatMenu(item.subMenu));
      }
    });

    return result;
  };
  const flatMenuItem = recursiveFlatMenu(menuItem);
  const isValidMenu = !!flatMenuItem.find(({ to }) => to === curMenuUrl);
  const curMenuName = isValidMenu ? curMenuUrl : '';

  return (
    <>
      <S.MenuItemTitle className="menu-item">
        {curMenuName === to ? selectedIcon || icon : icon}
        <span>{name}</span>
      </S.MenuItemTitle>

      {subMenu ? (
        <S.SubMenuWrap>
          {subMenu.map(menu => (
            <S.SubMenuiItem key={menu.name}>
              <S.MenuLink
                to={curMenuName === menu.to ? { pathname: menu.to, search: location.search } : menu.to ?? ''}
                className={curMenuName === menu.to ? 'selected' : ''}
              >
                <MenuItem menu={menu} menuItem={menuItem} />
              </S.MenuLink>
            </S.SubMenuiItem>
          ))}
        </S.SubMenuWrap>
      ) : null}
    </>
  );
};
