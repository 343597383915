import { makeAutoObservable, runInAction } from 'mobx';
import APIs from '@repositories/APIs';
import type { Filters, SoftwareModule, SoftwareCreateRq, Software } from '@repositories/softwareRepository/Types';
import { Provider, ProviderCreateRq, ProviderFilter, ProviderParams } from '@type/Provider';
import { SoftwareModel } from '@models/softwareModels';

class SoftwareStore {
  private _softwares: Map<string, SoftwareModel>;

  private _currSoftware: SoftwareModel;

  private _modules: Map<ModuleCode, SoftwareModule>;

  private _providers: Map<ProviderId, Provider>;

  private _provider: Provider | null;

  private _providerTotalElements: number;

  recommendationIdList: SoftwareId[];

  constructor() {
    this._softwares = new Map<string, SoftwareModel>();
    this._currSoftware = new SoftwareModel();
    this._modules = new Map<ModuleCode, SoftwareModule>();
    this._providers = new Map<ProviderId, Provider>();
    this._provider = null;
    this._providerTotalElements = 0;
    this.recommendationIdList = [];

    makeAutoObservable<SoftwareStore>(this);
  }

  async fetchProviders(queries?: ProviderFilter) {
    const result = await APIs.Provider.getList(queries);
    runInAction(() => {
      this._providers.clear();
      result.content.forEach(provider => this._providers.set(provider.id, provider));
      this._providerTotalElements = result.totalElements;
    });
    return result.totalElements;
  }

  async createProvider(data: ProviderCreateRq) {
    const result = await APIs.Provider.create(data);
    runInAction(() => {
      this._providers.set(result.id, result);
    });
    return result;
  }

  async updateProvider(params: ProviderParams, data: ProviderCreateRq) {
    const result = await APIs.Provider.update(params, data);
    runInAction(() => {
      this._provider = result;
      this._providers.set(result.id, result);
    });
    return this._provider;
  }

  /**
   * @deprecated
   */
  async fetchSoftwares(queries?: Filters) {
    const result = await APIs.Software.getList(queries);
    runInAction(() => {
      this._softwares.clear();
      result.content.map(sw => this._softwares.set(sw.id, new SoftwareModel(sw)));
    });
    return this._softwares;
  }

  /**
   * @deprecated
   */
  async fetchSoftware(id: string) {
    const result = await APIs.Software.getOne(id);
    runInAction(() => {
      this._currSoftware = new SoftwareModel(result);
      this._modules.clear();
      result.modules?.forEach(module => {
        this._modules.set(module.moduleCode, module);
      });
      if (result.productCode === 'SUMO_LOGIC') {
        this._modules.set('TOTAL_CREDIT', { moduleCode: 'TOTAL_CREDIT', name: 'Total Credit' });
        this._modules.set('TOTAL_ACC_CREDIT', { moduleCode: 'TOTAL_ACC_CREDIT', name: 'Total Acc Credit' });
      }
    });
    return this._currSoftware as Software;
  }

  async updateSoftware(data: SoftwareCreateRq, id: string, iconImage?: Blob, thumbnail?: Blob): Promise<Software> {
    const result = await APIs.Software.update(data, id, iconImage, thumbnail);
    runInAction(() => {
      this._softwares?.set(result.id, new SoftwareModel(result));
      this._currSoftware = new SoftwareModel(result);
    });
    return result;
  }

  async deleteSoftware(id: string) {
    await APIs.Software.delete(id);
  }

  // 검색 붙이기 이전에 임시 method
  async fetchAllSoftwares() {
    const result = await APIs.Software.getList();
    runInAction(() => {
      this._softwares.clear();
      result.content.map(sw => this._softwares.set(sw.id, new SoftwareModel(sw)));
    });
    return result.content;
  }

  /**
   * @deprecated
   */
  get softwares(): SoftwareModel[] {
    return Array.from(this._softwares.values());
  }

  getSoftware(id: string): SoftwareModel {
    return this._softwares.get(id) as SoftwareModel;
  }

  /**
   * @deprecated
   */
  get currSoftware(): SoftwareModel {
    return this._currSoftware as SoftwareModel;
  }

  get modules(): SoftwareModule[] {
    return Array.from(this._modules.values());
  }

  get providerTotalElements(): number {
    return this._providerTotalElements;
  }

  get providers(): Provider[] {
    return Array.from(this._providers.values());
  }

  getProvider(id: string): Provider {
    return this._providers.get(id) as Provider;
  }
}

const softwareStore = new SoftwareStore();

export default softwareStore;
