import type { WorkflowBaseTriggerFieldDTO } from '@repositories/workflowBaseTriggerRepository/Types';

export class WorkflowBaseTriggerFieldDTOModel {
  private _dataType: DataType;
  private _defaultValue: string;
  private _description: string;
  private _displayName: string;
  private _isConditionValue: boolean;
  private _name: string;
  private _referencePath: string;
  private _resourceType: string;

  constructor(dto: WorkflowBaseTriggerFieldDTO) {
    this._dataType = dto.dataType || 'STRING';
    this._defaultValue = dto.defaultValue || '';
    this._description = dto.description || '';
    this._displayName = dto.displayName || '';
    this._isConditionValue = dto.isConditionValue || false;
    this._name = dto.name || '';
    this._referencePath = dto.referencePath || '';
    this._resourceType = dto.resourceType || '';
  }

  get dataType() {
    return this._dataType;
  }

  get defaultValue() {
    return this._defaultValue;
  }

  get description() {
    return this._description;
  }

  get displayName() {
    return this._displayName;
  }

  get isConditionValue() {
    return this._isConditionValue;
  }

  get name() {
    return this._name;
  }

  get referencePath() {
    return this._referencePath;
  }

  get resourceType() {
    return this._resourceType;
  }
}
