import { CostOptimizationPoint } from '@repositories/subscriptionBillingStatRepository/Types';

export class CostOptimizationPointModel {
  private dto: CostOptimizationPoint;

  constructor(dto: CostOptimizationPoint) {
    this.dto = dto;
  }

  get type() {
    return this.dto.type;
  }

  get isUserOptimzationPoint() {
    return (
      this.dto.type === 'DEACTIVATED_USER' ||
      this.dto.type === 'FORMER_TENANT_MEMBER' ||
      this.dto.type === 'MONTHLY_INACTIVE_USER' ||
      this.dto.type === 'NON_TENANT_MEMBER'
    );
  }

  get isPlanOptimizationPoint() {
    return false;
  }

  get description() {
    switch (this.type) {
      case 'DEACTIVATED_USER':
        return '비활성 상태인 과금 사용자가 <b>{{usage}}명</b> 존재합니다.';
      case 'MONTHLY_INACTIVE_USER':
        return '한달 이상 접속하지 않은 사용자가 <b>{{usage}}명</b> 존재합니다.';
      case 'FORMER_TENANT_MEMBER':
        return '과금 사용자 중 퇴사한 사용자가 <b>{{usage}}명</b> 존재합니다.';
      case 'NON_TENANT_MEMBER':
        return '과금 사용자 중 구성원이 아닌 사용자가 <b>{{usage}}명</b> 존재합니다.';
      case 'CUSTOM':
      default:
        return this.dto.description;
    }
  }

  get usage() {
    return this.dto.usage;
  }

  get cost() {
    // return this.dto.cost;
    return this.dto.usage;
  }
}
