import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { CalculateIcon, DepthIcon } from '@icons/index';
import { withLogin } from '@hoc/withLogin';
import { LNB } from '../../common/lnb';

export const Billing = withLogin(() => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Container>
      <LNB
        menuItem={[
          {
            name: '비용',
            icon: <CalculateIcon width={20} height={20} />,
            subMenu: [
              {
                name: '진단',
                icon: <DepthIcon width={16} height={16} color={theme.colors['ic-gray-main']} />,
                selectedIcon: <DepthIcon width={16} height={16} color={theme.colors['ic-purple-light']} />,
                to: 'cost',
              },
              {
                name: '현황 조회',
                icon: <DepthIcon width={16} height={16} color={theme.colors['ic-gray-main']} />,
                selectedIcon: <DepthIcon width={16} height={16} color={theme.colors['ic-purple-light']} />,
                to: 'dashboard',
              },
              {
                name: '정산 내역 조회',
                icon: <DepthIcon width={16} height={16} color={theme.colors['ic-gray-main']} />,
                selectedIcon: <DepthIcon width={16} height={16} color={theme.colors['ic-purple-light']} />,
                to: 'history',
              },
            ],
          },
        ]}
      />
      <Outlet />
    </Container>
  );
});

const Container = styled('div')`
  width: 100%;
  display: flex;
`;
