import i18next from 'i18next';
import type {
  MemberStatus,
  Permission,
  TenantMemberSimple,
  TenantMemberStatus,
} from '@repositories/userRepository/Types';

export class TenantMemberSimpleModel {
  private _createdDate: string;
  private _updatedDate: string;
  private _deleted: boolean;
  private _deletedDate: string;
  private _email: string;
  private _id: string;
  private _lastLoginedAt: string;
  private _memberRole: Permission;
  private _name: string;
  private _phone: string;
  private _status: MemberStatus;
  private _tenantMemberStatus: TenantMemberStatus;
  private _userGroups: string[];
  private _username: string;
  private _subscriptionCount: number;

  constructor(dto?: TenantMemberSimple) {
    this._createdDate = dto?.createdDate || '';
    this._updatedDate = dto?.updatedDate || '';
    this._deleted = dto?.deleted || false;
    this._deletedDate = dto?.deletedDate || '';
    this._email = dto?.email || '-';
    this._id = dto?.id || '-';
    this._lastLoginedAt = dto?.lastLoginedAt || '-';
    this._memberRole = dto?.memberRole || 'USER';
    this._name = dto?.name || '-';
    this._phone = dto?.phone || '-';
    this._username = dto?.username || '-';
    this._userGroups = dto?.userGroups?.split(',') || [];
    this._status = dto?.status || 'CREATED';
    this._tenantMemberStatus = dto?.tenantMemberStatus || 'BLOCKED';
    this._subscriptionCount = dto?.subscriptionCount || 0;
  }

  get deleted() {
    return this._deleted;
  }

  get deletedDate() {
    return this._deletedDate;
  }

  get createdDate() {
    return this._createdDate;
  }

  get updatedDate() {
    return this._updatedDate;
  }

  get subscriptionCount() {
    return this._subscriptionCount;
  }

  get tenantMemberStatus() {
    return this._tenantMemberStatus;
  }
  get status() {
    return this._status;
  }

  get email() {
    return this._email;
  }

  get id() {
    return this._id;
  }

  get lastLoginedAt() {
    return this._lastLoginedAt;
  }

  get memberRole() {
    return this._memberRole;
  }

  get name() {
    return this._name;
  }

  get phone() {
    return this._phone;
  }

  get username() {
    return this._username;
  }

  get usergroups() {
    return this._userGroups;
  }

  get isAccountActive() {
    return this._status === 'ACTIVE';
  }

  get isAccountInactive() {
    return this._status === 'CREATED';
  }

  get isActive() {
    return this._tenantMemberStatus === 'ACTIVE';
  }

  get isStop() {
    return this._tenantMemberStatus === 'BLOCKED';
  }

  get isWait() {
    return this._tenantMemberStatus === 'ACTIVE' && this.isAccountInactive;
  }

  get isManager() {
    return (
      this._memberRole === 'GENERAL_ADMIN' ||
      this._memberRole === 'SUPER_ADMIN' ||
      this._memberRole === 'EXTERNAL_ADMIN'
    );
  }

  get managerStr() {
    switch (this._memberRole) {
      case 'GENERAL_ADMIN':
        return i18next.t('Member_Manager_07');
      case 'SUPER_ADMIN':
        return i18next.t('Member_Manager_06');
      case 'EXTERNAL_ADMIN':
        return i18next.t('Member_Manager_08');
      case 'USER':
        return i18next.t('Member_Group_07');
      default:
        return '-';
    }
  }
}
