import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import i18next from 'i18next';
import type {
  ActiveStatus,
  BaseUserDTO,
  ExecutionErrCode,
  ExecutionStatus,
  WorkflowDTO,
  WorkflowType,
} from '@repositories/workflowRepository/Types';
import { TenantSimpleModel } from '@models/tenantModels';
import { WorkflowActionDTOModel } from './WorkflowActionDTOModel';
import { WorkflowRespModel } from './WorkflowRespModel';
import { WorkflowTriggerDTOModel } from './WorkflowTriggerDTOModel';

export class WorkflowModel {
  private _creator: BaseUserDTO;
  private _actions: WorkflowActionDTOModel[];
  private _description: string;
  private _id: string;
  private _latestExecutionStatus?: ExecutionStatus;
  private _latestExecutionStatusMessage: string;
  private _latestExecutionTime: Dayjs | null;
  private _latestErrorCode: ExecutionErrCode | null;
  private _name: string;
  private _remoteId: string;
  private _remoteWorkflow: WorkflowRespModel;
  private _status: ActiveStatus;
  private _tenant: TenantSimpleModel;
  private _trigger: WorkflowTriggerDTOModel;
  private _type: WorkflowType;
  private _createdBy: string;
  private _createdDate: Dayjs;

  constructor(dto: WorkflowDTO) {
    this._creator = dto.creator || { id: '', name: '-', username: '' };
    this._actions = dto.actions?.length ? dto.actions.map(action => new WorkflowActionDTOModel(action)) : [];
    this._description = dto.description || '';
    this._id = dto.id || '';
    this._latestExecutionStatus = dto.latestExecutionStatus;
    this._latestExecutionStatusMessage = dto.latestExecutionStatusMessage || '';
    this._latestExecutionTime = dto.latestExecutionTime ? dayjs(dto.latestExecutionTime) : null;
    this._name = dto.name || '';
    this._remoteId = dto.remoteId || '';
    this._remoteWorkflow = new WorkflowRespModel(dto.remoteWorkflow || {});
    this._status = dto.status || 'INACTIVE';
    this._tenant = new TenantSimpleModel(dto.tenant);
    this._trigger = new WorkflowTriggerDTOModel(dto.trigger || {});
    this._type = dto.type || 'CUSTOM';
    this._createdBy = dto.createdBy || '';
    this._createdDate = dayjs(dto.createdDate);
    this._latestErrorCode = null;
  }

  get errorCode() {
    return this._latestErrorCode;
  }

  get creator() {
    return this._creator;
  }

  get actions() {
    return this._actions;
  }

  get description() {
    return this._description;
  }

  get id() {
    return this._id;
  }

  get latestExecutionStatus() {
    return this._latestExecutionStatus;
  }

  get latestExecutionStatusMessage() {
    return this._latestExecutionStatusMessage;
  }

  get latestExecutionTime() {
    return this._latestExecutionTime;
  }

  get name() {
    return this._name;
  }

  get remoteId() {
    return this._remoteId;
  }

  get remoteWorkflow() {
    return this._remoteWorkflow;
  }

  get status() {
    return this._status;
  }

  get tenant() {
    return this._tenant;
  }

  get trigger() {
    return this._trigger;
  }

  get type() {
    return this._type;
  }

  get createdBy() {
    return this._createdBy;
  }

  get createdDate() {
    return this._createdDate;
  }

  get isLatestExecutionSuccess() {
    switch (this._latestExecutionStatus) {
      case 'COMPLETED':
      case 'READY':
      case 'RUNNING':
        return true;
      case 'FAILED':
      case 'STOPPED':
      case 'STOP_REQUESTED':
      default:
        return false;
    }
  }

  get latestExecutionStatusStr() {
    return this.isLatestExecutionSuccess ? i18next.t('Workflow_Main_12') : i18next.t('Workflow_Main_13');
  }
}
