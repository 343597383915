import { useTranslation } from 'react-i18next';
import { Navigate, Outlet, Route, Routes, useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import { Box, Typography, useTheme } from '@mui/material';
import { observer } from 'mobx-react';
import { RoutingTabs } from '@components/tab';
import { useStore } from '@stores/RootStore';
import { BulletIcon } from '@icons/BulletIcon';
import { useGetTenantMembersInfinite } from '@queryHooks/useTenant';
import ActiveUsers from './ActiveUsers';
import StopUsers from './StopUsers';
import { UserIndividualRegister } from './userAdd';
import { UserEdit } from './userEdit';

export const UserMgmt = observer(() => {
  const { t } = useTranslation();
  const {
    authStore,
    uiStore: { userIndividualRegisterStore: userRegisterModal, userEditStore: userEditModal },
  } = useStore();
  const theme = useTheme();
  const params = useParams();

  const { data: pagedActiveMembers } = useGetTenantMembersInfinite(authStore.curTenant.id, {
    tenantMemberStatus: 'ACTIVE',
  });
  const { data: pagedBlockedMembers } = useGetTenantMembersInfinite(authStore.curTenant.id, {
    tenantMemberStatus: 'BLOCKED',
    isDeleted: false,
  });
  const activeMemberCount = pagedActiveMembers?.pages[0].totalElements ?? 0;
  const blockMemberCount = pagedBlockedMembers?.pages[0].totalElements ?? 0;

  return (
    <Container>
      <HeaderWrapper>
        <Typography component="strong" variant="headline5">
          {t('Member_LNB_05')}
        </Typography>
      </HeaderWrapper>
      <TableInfo>
        <TableInfoItem>
          <BulletIcon width={6} height={6} color={theme.colors['ic-gray-lighter']} />
          <Typography component="span" variant="body4" color={theme.colors['text-gray-sub-dark']}>
            {t('Member_User_01')}
          </Typography>
        </TableInfoItem>
        <TableInfoItem>
          <BulletIcon width={6} height={6} color={theme.colors['ic-gray-lighter']} />
          <Typography component="span" variant="body4" color={theme.colors['text-gray-sub-dark']}>
            {t('Member_User_02')}
          </Typography>
        </TableInfoItem>
        <TableInfoItem>
          <BulletIcon width={6} height={6} color={theme.colors['ic-gray-lighter']} />
          <Typography component="span" variant="body4" color={theme.colors['text-gray-sub-dark']}>
            {t('Member_User_03')}
          </Typography>
        </TableInfoItem>
      </TableInfo>
      <Body>
        <RoutingTabs
          tabList={[
            {
              name: t('Member_User_04', {
                number: activeMemberCount === 0 ? '' : `(${activeMemberCount})`,
              }),
              path: 'active',
              replace: true,
            },
            {
              name: t('Member_User_06', {
                number: blockMemberCount === 0 ? '' : `(${blockMemberCount})`,
              }),
              path: 'stop',
              replace: true,
            },
          ]}
          variant="contain"
        />
        <Outlet />
        {userRegisterModal.visible && <UserIndividualRegister />}
        {userEditModal.visible && <UserEdit />}
      </Body>
    </Container>
  );
});

const Container = styled(Box)`
  width: 100%;
  height: calc(100vh - var(--manager-gnb-height));
  overflow: auto;
  padding: 0 32px 32px;

  background-color: ${props => props.theme.colors['bg-white']};
`;

const HeaderWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 28px 8px 14px;
`;
const TableInfo = styled('ul')`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: cneter;
  align-items: flex-start;
  gap: 2px;
  padding: 16px 32px;
  margin-bottom: 32px;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.colors['bg-gray-light']};
`;

const TableInfoItem = styled('li')`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
`;
const Body = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;
