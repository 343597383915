import { TenantSimpleModel } from '@models/tenantModels';
import { UserGroupBaseModel } from '@models/userGroupModels/UserGroupBaseModel';
import { Profile, ProfileUpdateRq } from '@repositories/userRepository';
import dayjs from 'dayjs';

export class ProfileModel {
  private _address: string;
  private _email: string;
  private _id: string;
  private _lastLoginedAt: DateTimeString;
  private _lastPasswordChangeDate: DateTimeString;
  private _name: string;
  private _phone: string;
  private _roles: string[];
  private _company: TenantSimpleModel[];
  private _userGroups: UserGroupBaseModel[];
  private _username: string;

  constructor(dto?: Profile) {
    this._address = dto?.address || '';
    this._email = dto?.email || '';
    this._id = dto?.id || '';
    this._lastLoginedAt = dayjs(dto?.lastLoginedAt).format('YYYY-MM-DD');

    const lastPasswordChangeDate = dayjs(dto?.lastPasswordChangeDate).format('YYYY-MM-DD');
    this._lastPasswordChangeDate = lastPasswordChangeDate === 'Invalid Date' ? '-' : lastPasswordChangeDate;
    this._name = dto?.name || '';
    this._phone = dto?.phone || '';
    this._username = dto?.username || '';
    this._roles = dto?.roles?.split(',') || [];
    this._company = dto?.tenants?.map(tenant => new TenantSimpleModel(tenant)) || [];
    this._userGroups = dto?.userGroups?.map(usergroup => new UserGroupBaseModel(usergroup)) || [];
  }

  get address() {
    return this._address;
  }

  get email() {
    return this._email;
  }

  get id() {
    return this._id;
  }

  get lastLoginedAt() {
    return this._lastLoginedAt;
  }

  get lastPasswordChangeDate() {
    return this._lastPasswordChangeDate;
  }

  get name() {
    return this._name;
  }

  get phone() {
    return this._phone;
  }

  get roles() {
    return this._roles;
  }

  get company() {
    return this._company;
  }

  get companyNames() {
    return this.company.map(({ name }) => name).join(', ');
  }

  get userGroups(): UserGroupBaseModel[] {
    return this._userGroups;
  }

  get userGroupNames() {
    return this.userGroups.map(({ name }) => name).join(', ');
  }

  getTenantUserGroups(tenantId: string) {
    return this.userGroups.filter(({ tenantId: tid }) => tid === tenantId);
  }

  get username() {
    return this._username;
  }

  get userUpdateRq(): ProfileUpdateRq {
    return {
      name: this._name,
      phone: this._phone,
      address: this._address,
    };
  }
}
