import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Divider } from '@mui/material';
import { observer } from 'mobx-react';
import { IconButton } from '@components/buttons';
import { ProfileModal } from '@pages/customer/common/modals';
import { useStore } from '@stores/RootStore';
import { fonts } from '@theme/fontsCustomer';
import { PencilIcon } from '@icons/PencilIcon';
import { useSubscriptionModel } from '@customHooks/useSubscriptionModel';
import { dateFormat } from '@utils/dateUtil';
import { SubscriptionEditDialog } from '../../../subscriptionEditDialog/SubscriptionEditDialog';

export const SubscriptionInfo = observer(() => {
  const { t } = useTranslation();
  const [isEditOpen, setEditOpen] = useState(false);
  const [ownerProfileAnchorEl, setOwnerProfileAnchorEl] = useState<HTMLElement | null>(null);

  const model = useSubscriptionModel();
  const { categoryStore } = useStore();
  const categoryId = model.softwareCategory?.id;
  const rootCategory = categoryId ? categoryStore.findRootCategory(categoryId) : undefined;

  return (
    <>
      <SubscriptionInfoContainer>
        <Header>
          {t('Subscrib_Detail_Overview_09')}
          <IconButton variant="outline" size="extraSmall" onClick={() => setEditOpen(true)}>
            <PencilIcon />
          </IconButton>
        </Header>
        <dl>
          <SubscriptionInfoField>
            <Key>{t('Subscrib_Detail_UseRequest_06')}</Key>
            <Value>{rootCategory?.name}</Value>
          </SubscriptionInfoField>

          <SubscriptionInfoField>
            <Key>{t('Subscrib_Main_12')}</Key>
            <Value>{model.plan.planName}</Value>
          </SubscriptionInfoField>

          <SubscriptionInfoField>
            <Key>{t('Subscrib_Edit_01')}</Key>
            <Value>{`${model.commitmentPeriod} / ${model.paymentPeriod}`}</Value>
          </SubscriptionInfoField>

          <SubscriptionInfoField>
            <Key>{t('Subscrib_Detail_Overview_13')}</Key>
            <Value>
              <div>
                {dateFormat(model.startDate, '-', t('DateFormat_YMD'))} ~{' '}
                {dateFormat(model.endDate, '-', t('DateFormat_YMD'))}
              </div>
              <Timezone>{`(${model.timeZone})`}</Timezone>
            </Value>
          </SubscriptionInfoField>

          <SubscriptionInfoField>
            <Key>{t('Acc_Main_22')}</Key>
            <Value>{model.isExternalSubscription ? t('Subscrib_Main_19') : '-'}</Value>
          </SubscriptionInfoField>
        </dl>
        <DashedDivider />
        <dl>
          <UserInfoField>
            <Key>{t('Subscrib_Edit_08')}</Key>
            <Value>
              {model.owner.name ? (
                <OwnerNameButton
                  type="button"
                  onClick={event => setOwnerProfileAnchorEl(event.currentTarget)}
                  className={ownerProfileAnchorEl ? 'clicked' : ''}
                >
                  {model.owner.name}
                </OwnerNameButton>
              ) : (
                '-'
              )}
            </Value>
          </UserInfoField>

          <UserInfoField>
            <Key>{t('Subscrib_Main_16')}</Key>
            <Value>{model.userGroupName}</Value>
          </UserInfoField>
        </dl>
        <DashedDivider />
        <dl>
          <UserInfoField>
            <Key>{t('Subscrib_Detail_Overview_14')}</Key>
            <Value>{model.salesManager.name || '-'}</Value>
          </UserInfoField>

          <UserInfoField>
            <Key>{t('Subscrib_Detail_Overview_15')}</Key>
            <Value>{model.technicalSupportManager.name || '-'}</Value>
          </UserInfoField>
        </dl>
      </SubscriptionInfoContainer>

      <SubscriptionEditDialog
        open={isEditOpen}
        onClose={() => setEditOpen(false)}
        subscriptionId={model.id}
        softwareId={model.software.id}
      />

      <ProfileModal
        open={!!ownerProfileAnchorEl}
        anchorEl={ownerProfileAnchorEl}
        onClose={() => setOwnerProfileAnchorEl(null)}
        userId={model.owner.id}
      />
    </>
  );
});

const SubscriptionInfoContainer = styled.div`
  padding: 32px 40px 44px 32px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  ${fonts.Headline7}
  margin-bottom: 20px;
`;

const SubscriptionInfoField = styled.div`
  margin-bottom: 7px;
  &:last-of-type {
    margin-bottom: 0px;
  }
`;

const Key = styled.dt`
  ${fonts.Body2}
  color: ${({ theme: { colors } }) => colors['text-gray-sub-dark']};
  float: left;
`;

const Value = styled.dd`
  ${fonts.Headline8}
  text-align: right;
  padding-left: 100px;
`;

const Timezone = styled.small`
  ${fonts.Body4}
  color: ${({ theme: { colors } }) => colors['text-gray-light']};
`;

const DashedDivider = styled(Divider)`
  margin: 12px 0px;
  border-bottom: 1px dashed ${({ theme: { colors } }) => colors['border-gray-lighter']};
`;

const UserInfoField = styled.div`
  margin-bottom: 6px;
  &:last-of-type {
    margin-bottom: 0px;
  }
`;

const OwnerNameButton = styled.button`
  all: unset;
  cursor: pointer;
  color: ${({ theme: { colors } }) => colors['text-blue-light']};
  ${fonts.Underline3}

  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  &.clicked {
    text-decoration: underline;
  }
`;
