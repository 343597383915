import { Company, CompanyType } from '@repositories/companyRepository';
import dayjs, { Dayjs } from 'dayjs';

export class CompanySimpleModel {
  private _id: CompanyId;
  private _name: string;
  private _type: CompanyType;
  private _email?: string;
  private _phone?: string;
  private _url?: string;
  private _address?: string;
  private _businessRegistrationNumber?: string;
  private _corporateRegistrationNumber?: string;
  private _industry?: string;
  private _shippingAddress?: string;
  private _companyRepresentative?: string;

  private _createdDate?: Dayjs;
  private _updatedDate?: Dayjs;

  constructor(dto?: Company) {
    this._id = dto?.id ?? -1;
    this._name = dto?.name || '';
    this._type = dto?.type || 'CO_CORPORATION';
    this._email = dto?.email;
    this._phone = dto?.phone;
    this._url = dto?.url;
    this._address = dto?.address;
    this._businessRegistrationNumber = dto?.businessRegistrationNumber;
    this._corporateRegistrationNumber = dto?.corporateRegistrationNumber;
    this._industry = dto?.industry;
    this._shippingAddress = dto?.shippingAddress;
    this._companyRepresentative = dto?.companyRepresentative;

    this._createdDate = dto?.createdDate ? dayjs(dto?.createdDate) : undefined;
    this._updatedDate = dto?.updatedDate ? dayjs(dto?.updatedDate) : undefined;
  }

  get id() {
    return this._id;
  }

  get name() {
    return this._name;
  }

  get type() {
    return this._type;
  }

  get email() {
    return this._email;
  }

  get phone() {
    return this._phone;
  }

  get url() {
    return this._url;
  }

  get address() {
    return this._address;
  }

  get businessRegistrationNumber() {
    return this._businessRegistrationNumber;
  }

  get corporateRegistrationNumber() {
    return this._corporateRegistrationNumber;
  }

  get industry() {
    return this._industry;
  }

  get shippingAddress() {
    return this._shippingAddress;
  }

  get companyRepresentative() {
    return this._companyRepresentative;
  }

  get createdAt() {
    return this._createdDate?.format('YYYY-MM-DD HH:mm');
  }

  get updatedAt() {
    return this._updatedDate?.format('YYYY-MM-DD HH:mm');
  }
}
