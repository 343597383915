import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Accordion, AccordionDetails, AccordionSummary, useTheme } from '@mui/material';
import { useStore } from '@stores/RootStore';
import { fonts } from '@theme/fontsCustomer';
import { ChevronIcon } from '@icons/ChevronIcon';

export const ControlAction = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const { workflowDetailStore } = useStore();

  const handleClickIfActionNode = () => {
    if (!workflowDetailStore.checkEditable()) return;

    const actionNode = workflowDetailStore.findNode(workflowDetailStore.curNodeId ?? '');
    if (actionNode) {
      actionNode.data.actionModel = {
        ...actionNode.data.actionModel,
        type: 'IF_ACTION',
        actionArguments: [
          {
            argumentName: 'expr',
            expression: '',
            expressionType: 'SIMPLE',
          },
        ],
        actionMethod: '',
        actionName: '조건 분기',
        connectionId: '',
      };

      actionNode.data.connectionModel = undefined;
      actionNode.data.isSmpAction = false;
      workflowDetailStore.setToolbarView('actionConfig');
    }
  };

  return (
    <StyledAccordion>
      <StyledAccordionSummary
        expandIcon={<ChevronIcon width={20} height={20} rotateNum={180} color={theme.colors['ic-gray-light']} />}
      >
        <ConnectionInfo>
          <ConnectionMeta>
            <ConnectionAppName>{t('Workflow_Create_ControlAction')}</ConnectionAppName>
          </ConnectionMeta>
        </ConnectionInfo>
      </StyledAccordionSummary>
      <StyledAccordionDetails>
        <ActionCandidate onClick={handleClickIfActionNode}>
          <strong className="action-name">{t('Workflow_Create_IfAction')}</strong>
          <p className="action-desc">{t('Workflow_Create_IfActionDesc')}</p>
        </ActionCandidate>
      </StyledAccordionDetails>
    </StyledAccordion>
  );
};

const StyledAccordion = styled(Accordion)`
  border: 1px solid ${({ theme: { colors } }) => colors['border-gray-lighter']};
  border-radius: 5px;
  box-shadow: none;

  padding: 0;
  margin: 0;
  &:first-of-type {
    border-radius: 5px;
  }
  &::before {
    content: none;
  }
  & .MuiButtonBase-root {
    padding: 17px 29px;
  }
  &.Mui-expanded {
    margin: 0;
  }
`;

const StyledAccordionSummary = styled(AccordionSummary)`
  height: 84px;
  & .MuiAccordionSummary-content {
    margin: 0;
    padding: 0;
    &.Mui-expanded {
      margin: 0;
    }
  }
`;

const ConnectionInfo = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 12px;
  align-items: center;
`;

const ConnectionMeta = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const ConnectionAppName = styled.strong`
  ${fonts.Headline9};
  color: ${({ theme: { colors } }) => colors['text-gray-sub-dark']};
`;

const StyledAccordionDetails = styled(AccordionDetails)`
  padding: 0;
  border-top: 1px solid ${({ theme }) => theme.colors['border-gray-w-lighter']};
  background-color: ${props => props.theme.colors['bg-gray-lighter']};
`;

const ActionCandidate = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;

  padding: 11px 28px;
  border: 1px solid transparent;
  cursor: pointer;

  :not(:last-of-type) {
    border-bottom: 1px solid ${props => props.theme.colors['border-gray-w-lighter']};
  }
  & .action-name {
    ${fonts.Headline9};
    color: ${({ theme: { colors } }) => colors['text-gray-main']};
  }
  & .action-desc {
    ${fonts.Body4};
    color: ${({ theme: { colors } }) => colors['text-gray-sub-dark']};
  }
  :hover {
    border: 1px solid ${({ theme: { colors } }) => colors['border-purple-light']};
    background-color: ${({ theme: { colors } }) => colors['state-white-purple-hover']};
  }
`;
