import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { DepthIcon } from '@icons/DepthIcon';
import { SubscriptionIcon } from '@icons/index';
import { withLogin } from '@hoc/withLogin';
import { LNB } from '../../common/lnb';

export const Subscription: React.FC = withLogin(() => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Container>
      <LNB
        menuItem={[
          {
            name: '구독 관리',
            icon: <SubscriptionIcon width={20} height={20} color={theme.colors['ic-gray-main']} />,
            subMenu: [
              {
                name: '현황 조회',
                icon: <DepthIcon width={16} height={16} color={theme.colors['ic-gray-main']} />,
                selectedIcon: <DepthIcon width={16} height={16} color={theme.colors['ic-purple-light']} />,
                to: 'list',
              },
              {
                name: '사용자 관리',
                icon: <DepthIcon width={16} height={16} color={theme.colors['ic-gray-main']} />,
                selectedIcon: <DepthIcon width={16} height={16} color={theme.colors['ic-purple-light']} />,
                to: 'user-mgmt',
              },
              {
                name: t('Subscrib_Main_23'),
                icon: <DepthIcon width={16} height={16} color={theme.colors['ic-gray-main']} />,
                selectedIcon: <DepthIcon width={16} height={16} color={theme.colors['ic-purple-light']} />,
                to: 'connections',
              },
              {
                name: '사용 신청 관리',
                icon: <DepthIcon width={16} height={16} color={theme.colors['ic-gray-main']} />,
                selectedIcon: <DepthIcon width={16} height={16} color={theme.colors['ic-purple-light']} />,
                to: 'usage-registers',
              },
              {
                name: '기술지원 문의 내역',
                icon: <DepthIcon width={16} height={16} color={theme.colors['ic-gray-main']} />,
                selectedIcon: <DepthIcon width={16} height={16} color={theme.colors['ic-purple-light']} />,
                to: 'technical-support',
              },
            ],
          },
        ]}
      />
      <Outlet />
    </Container>
  );
});

const Container = styled('div')`
  width: 100%;
  height: 100%;
  min-height: 100%;
  display: flex;
`;
