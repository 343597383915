import { SoftwareBilling } from '@repositories/tenantMonthlyBillingRepository/Types';
import { SoftwareModel } from './softwareModels';

export class SoftwareBillingModel {
  private _cost: number;
  private _month: string;
  private _originalCost: number;
  private _software: SoftwareModel;

  constructor(dto?: SoftwareBilling) {
    this._cost = dto?.cost || 0;
    this._month = dto?.month || '0';
    this._originalCost = dto?.originalCost || 0;
    this._software = new SoftwareModel(dto?.software);
  }

  get cost() {
    return this._cost;
  }

  get month() {
    return this._month;
  }

  get originalCost() {
    return this._originalCost;
  }

  get software() {
    return this._software;
  }
}
