import { UserGroupSimple, UserGroupUpdateRq } from '@repositories/userGroupsRepository/Types';

export class UserGroupSimpleModel {
  private dto?: UserGroupSimple;

  constructor(dto?: UserGroupSimple) {
    this.dto = dto;
  }

  get totalMemberCount() {
    return this.dto?.totalMemberCount ?? 0;
  }

  get name() {
    return this.dto?.name ?? '';
  }

  get subUserGroupCount() {
    return this.dto?.subUserGroupCount ?? 0;
  }

  get totalSubscriptionCount() {
    return this.dto?.totalSubscriptionCount ?? 0;
  }

  get tenantId() {
    return this.dto?.tenantId ?? '';
  }

  get userGroupCode() {
    return this.dto?.userGroupCode;
  }

  get userGroupId() {
    return this.dto?.userGroupId ?? '';
  }

  get isDeleted() {
    return this.dto?.isDeleted ?? false;
  }

  getUpdateDTO(updateValue: Partial<UserGroupUpdateRq>): UserGroupUpdateRq {
    return {
      description: this.dto?.description,
      name: this.dto?.name ?? '',
      parentUserGroupId: this.dto?.parentUserGroupId,
      // properties?: Properties;
      userGroupId: this.dto?.userGroupId ?? '',
      userGroupCode: this.dto?.userGroupCode,
      ...updateValue,
    };
  }
}
