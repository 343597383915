import { ActiveStatus, WorkflowResp } from '@repositories/workflowRepository/Types';
import { WorkflowActionModel } from './WorkflowActionModel';
import { WorkflowTriggerModel } from './WorkflowTriggerModel';

export class WorkflowRespModel {
  private _actions: WorkflowActionModel[];
  private _id: string;
  private _name: string;
  private _status: ActiveStatus;
  private _trigger: WorkflowTriggerModel;
  private _triggerJobId: string;

  constructor(dto: WorkflowResp) {
    this._actions = dto.actions?.length ? dto.actions.map(action => new WorkflowActionModel(action)) : [];
    this._id = dto.id || '';
    this._name = dto.name || '';
    this._status = dto.status || 'INACTIVE';
    this._trigger = new WorkflowTriggerModel(dto.trigger || {});
    this._triggerJobId = dto.triggerJobId || '';
  }

  get actions() {
    return this._actions;
  }

  get id() {
    return this._id;
  }

  get name() {
    return this._name;
  }

  get status() {
    return this._status;
  }

  get trigger() {
    return this._trigger;
  }

  get triggerJobId() {
    return this._triggerJobId;
  }
}
