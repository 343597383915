import dayjs from 'dayjs';
import { MonthlySubscriptionUserDTO } from '@repositories/subscriptionRepository';

export class MonthlySubscriptionUserModel {
  private _createdBy: string;
  private _createdDate: DateTimeString;
  // private _creator: User;
  private _month: DateTimeString;
  private _monthlyActiveUserCount: number;
  private _subscriptionId: string;
  private _totalUserCount: number;
  private _updatedBy: string;
  private _updatedDate: DateTimeString;
  // private _updater: User;

  constructor(dto: MonthlySubscriptionUserDTO) {
    this._createdBy = dto.createdBy ?? '';
    this._createdDate = dto.createdDate ?? '';
    this._month = dto.month ?? '';
    this._monthlyActiveUserCount = dto.monthlyActiveUserCount ?? 0;
    this._subscriptionId = dto.subscriptionId;
    this._totalUserCount = dto.totalUserCount ?? 0;
    this._updatedBy = dto.updatedBy ?? '';
    this._updatedDate = dto.updatedDate ?? '';
  }

  get createdBy() {
    return this._createdBy;
  }

  get createdDate() {
    return this._createdDate;
  }

  get month() {
    const month = dayjs(this._month).format('YYYY.MM.DD');
    return month === 'Invalid Date' ? '' : month;
  }

  get monthlyActiveUserCount() {
    return this._monthlyActiveUserCount;
  }

  get subscriptionId() {
    return this._subscriptionId;
  }

  get totalUserCount() {
    return this._totalUserCount;
  }

  get updatedBy() {
    return this._updatedBy;
  }

  get updatedDate() {
    return this._updatedDate;
  }
}
