import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

type Item = { name: string; path: string };
type Props = {
  itemList: Item[];
};

export const useSelectedLocation = ({ itemList }: Props) => {
  const location = useLocation();

  /**
   * 현재 경로(location.pathname)와 tabList의 path들을 비교하여 선택한 Tab을 판단하는 함수
   */
  const getSelectedTab = useCallback(() => {
    /**
     * 1. 절대 경로, 상대 경로를 혼합해서 사용하는 경우 에러 발생 (TODO: 가능하다면 type으로 제한하기)
     */
    const isMixedPath =
      itemList.length === 1
        ? false
        : itemList.every(({ path }, idx) => {
            if (idx === 0) {
              return true;
            }
            return itemList[0].path[0] === '/' ? path[0] !== '/' : path[0] === '/';
          });
    if (isMixedPath) {
      throw Error('path에 상대 경로와 절대 경로를 혼합하여 사용할 수 없습니다.');
    }

    /**
     * 2. 경로가 정확히 일치하는 경우 해당 Tab 반환
     */
    const matchExact = itemList.find(({ path }) => path === location.pathname);
    if (matchExact) {
      return matchExact;
    }

    /**
     * 3. 절대 경로, 상대 경로에 따라 분기 처리
     * 절대 경로와 상대 경로를 혼합해서 사용하지 않기 때문에 tabList의 0번째 path를 이용해 절대 경로인지 상대 경로인지 구분
     */
    const isTabListAbsolutePath = itemList[0].path.startsWith('/');

    /**
     * 3.1. 절대 경로인 경우 path 중에서 해당 경로로 시작하는 Tab 반환
     */
    if (isTabListAbsolutePath) {
      return itemList
        .filter(({ path }) => location.pathname.startsWith(path))
        .sort((a, b) => b.path.length - a.path.length)[0];
    }

    /**
     * 3.2. 상대 경로인 경우 path 중에서 해당 경로를 포함하는 Tab 반환
     * 빈 path가 포함된 Tab이 있는 경우, 일단 넘기고 나머지 Tab에서 찾지 못하면 해당 Tab이 선택됐다고 판단
     */
    let emptyPathIndex = -1;
    const selectedTab = itemList.find(({ path }, idx) => {
      if (path === '') {
        emptyPathIndex = idx;
        return false;
      }
      return location.pathname.includes(path);
    });

    return selectedTab ?? itemList[emptyPathIndex];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const [curTab, setCurTab] = useState<Item | undefined>(() => {
    const selectedTab = getSelectedTab();
    return selectedTab;
  });

  useEffect(() => {
    setCurTab(getSelectedTab());
  }, [getSelectedTab]);

  return [curTab];
};
