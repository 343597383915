import type { WorkflowActionCreateDTO, WorkflowActionDTO } from '@repositories/workflowRepository/Types';
import { SubscriptionConnectionModel } from '@models/connection/SubscriptionConnectionModel';
import { WorkflowActionArgumentModel } from './WorkflowActionArgumentModel';

export class WorkflowActionDTOModel {
  private _dto: WorkflowActionDTO;
  private _actionArguments?: WorkflowActionArgumentModel[];
  private _connection?: SubscriptionConnectionModel;

  constructor(dto: WorkflowActionDTO) {
    this._dto = dto;
    this._actionArguments = dto.actionArguments?.length
      ? dto.actionArguments.map(arg => new WorkflowActionArgumentModel(arg))
      : [];
    this._connection = dto.connection ? new SubscriptionConnectionModel(dto.connection) : undefined;
  }

  get description() {
    return this._dto.actionDescription;
  }

  get actionArguments() {
    return this._actionArguments;
  }

  get actionMethod() {
    return this._dto.actionMethod;
  }

  get actionName() {
    return this._dto.actionName;
  }

  get actionStep() {
    return this._dto.actionStep;
  }

  get conditionThenStep() {
    return this._dto.conditionThenStep;
  }

  get connection() {
    return this._connection;
  }

  get exceptionCatchStep() {
    return this._dto.exceptionCatchStep;
  }

  get id() {
    return this._dto.id;
  }

  get nextStep() {
    return this._dto.nextStep;
  }

  get type() {
    return this._dto.type;
  }

  toCreateDTO(): WorkflowActionCreateDTO {
    return {
      actionArguments:
        this._actionArguments?.map(actionArg => ({
          argumentName: actionArg.argumentName,
          expression: actionArg.expression || '',
          expressionType: actionArg.expressionType || 'CUSTOM',
        })) ?? [],
      actionMethod: this.actionMethod ?? '',
      actionName: this.actionName ?? '',
      conditionThenStep: this.conditionThenStep,
      connectionId: this._connection?.connectionId ?? '',
      exceptionCatchStep: this.exceptionCatchStep,
      nextStep: this.nextStep,
      type: this.type,
    };
  }
}
