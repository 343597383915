import dayjs from 'dayjs';
import type { AdditionalCommitmentDTO, LicenseDTO, LicenseUpdateDTO } from '@repositories/licenseRepository';
import type { ProductPlanOption } from '@repositories/softwareRepository';
import { SubscriptionSimpleModel } from './SubscriptionSimpleModel';
import { SubscriptionUserModel } from './SubscriptionUserModel';
import { ProductPlanModel } from './softwareModels/ProductPlanModel';

export class LicenseModel {
  private dto: LicenseDTO;
  private _additionalCommitments: AdditionalCommitmentDTO[];
  private _plan: ProductPlanModel;
  private _subscription: SubscriptionSimpleModel;
  private _subscriptionUsers: SubscriptionUserModel[];

  private _planOption: ProductPlanOption;

  constructor(dto: LicenseDTO) {
    this.dto = dto;

    this._additionalCommitments = dto.additionalCommitments ?? [];
    this._plan = new ProductPlanModel(dto.plan);
    this._subscription = new SubscriptionSimpleModel(dto.subscription);
    this._subscriptionUsers =
      dto.subscriptionUsers?.map(userDto => new SubscriptionUserModel(userDto, this._subscription.id)) ?? [];

    this._planOption = dto.planOption ?? {
      optionName: '',
      paymentOption: 'PREPAID',
      paymentPeriod: 'ONCE',
      billingCycle: 'MONTH',
      billingPeriodType: 'CALENDAR_MONTH',
      planOptionId: '',
      productPlanId: '',
      usageMetricPrices: [],
      unitPeriod: 'MONTH',
    };
  }

  get additionalCommitments() {
    return this._additionalCommitments;
  }

  get additionalProperties() {
    return this.dto.additionalProperties ?? {};
  }

  get contractUnitPrice() {
    return this.dto.contractUnitPrice;
  }

  get currencyUnit() {
    return this.dto.currencyUnit;
  }

  get endDate() {
    return this.dto.endDate;
  }

  get licenseId() {
    return this.dto.licenseId;
  }

  get licenseStatus() {
    return this.dto.licenseStatus;
  }

  get licenseUserType() {
    return this.dto.licenseUserType;
  }

  get plan() {
    return this._plan;
  }

  get planOption() {
    return this._planOption;
  }

  get createdDate() {
    return this.dto.createdDate ?? '';
  }

  get startDate() {
    return this.dto.startDate;
  }

  get statusMessage() {
    return this.dto.statusMessage;
  }

  get subscription() {
    return this._subscription;
  }

  get subscriptionUsers() {
    return this._subscriptionUsers;
  }

  get subscriptionUserCount() {
    return this.dto.subscriptionUserCount ?? 0;
  }

  get timeZone() {
    return this.dto.timeZone;
  }

  get usageCommitmentBased() {
    return this.dto.usageCommitmentBased;
  }

  get licenseCode() {
    return this.dto.licenseCode;
  }

  get renewalDate() {
    return this.dto.renewalDate;
  }

  get isActive() {
    const status = this.dto.licenseStatus;
    if (status === 'EXPIRED') {
      return false;
    }
    const today = dayjs();
    const startDate = this.startDate ? dayjs(this.startDate) : null;
    const endDate = this.endDate ? dayjs(this.endDate) : null;
    let isActiveCheck = false;

    if (startDate === null && endDate !== null) {
      isActiveCheck = today.isBefore(endDate);
    } else if (startDate !== null) {
      if (endDate) {
        isActiveCheck = today.isAfter(startDate) && today.isBefore(endDate);
      } else {
        isActiveCheck = today.isAfter(startDate);
      }
    }

    return isActiveCheck;
  }

  get licenseUserCount() {
    return this.dto.licenseUserCount;
  }

  getLicenseUpdateDTO(updateValue: Partial<LicenseUpdateDTO>): LicenseUpdateDTO {
    const updatedAdditionalCommitments =
      this.dto.additionalCommitments?.map(item => {
        return {
          committedUsage: item.committedUsage,
          discountRate: item.discountRate,
          type: item.type,
          usageMetricId: item.usageMetric ? item.usageMetric.usageMetricId : undefined,
          usageUnitPrice: item.usageUnitPrice,
          usageUnitPriceWhenExceed: item.usageUnitPriceWhenExceed,
        };
      }) ?? [];

    const updatedLicense: LicenseUpdateDTO = {
      additionalCommitments: updatedAdditionalCommitments,
      additionalProperties: this.dto.additionalProperties,
      endDate: this.dto.endDate,
      licenseCode: this.dto.licenseCode,
      licenseId: this.dto.licenseId,
      licenseStatus: this.dto.licenseStatus,
      planId: this.dto.plan.planId,
      planOptionId: this.dto.planOption.planOptionId,
      startDate: this.dto.startDate,
      statusMessage: this.dto.statusMessage,
      timeZone: this.dto.timeZone,
      userLoginId: this.dto.userLoginId,
      renewalDate: this.dto.renewalDate,
      ...updateValue,
    };

    return updatedLicense;
  }
}
