import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { useMediaQuery } from '@mui/material';
import { Label } from '@components/index';
import { Popover, PopoverContainer, PopoverRow } from '@components/popover';
import { fonts } from '@theme/fontsCustomer';
import { ChevronIcon } from '@icons/ChevronIcon';
import { PencilIcon } from '@icons/PencilIcon';
import { TriangleIcon } from '@icons/TriangleIcon';
import type { LicenseStatus } from '@repositories/subscriptionUserRepository/Types';
import { useSubscriptionModel } from '@customHooks/useSubscriptionModel';
import { useGetLicense, useLicenseStatusUpdate } from '@queryHooks/useLicense';
import { dateFormat } from '@utils/dateUtil';
import { currencyTextFormat } from '@utils/numberFormat';
import { LicenseEditorModal } from '../LicenseEditorModal';
import { LicenseMultiUserListPopup } from '../LicenseMultiUserListPopup';
import { LicenseUserInfoPopup } from '../LicenseUserInfoPopup';
import { AdditionalCommitmentList } from './AdditionalCommitmentList/AdditionalCommitmentList';

export const LicenseDetail = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { licenseId } = useParams();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [isUpdateLicenseOpen, setIsUpdateLicenseOpen] = useState<boolean>(false);
  const [isMultiUsersAnchorEl, setIsMultiUsersAnchorEl] = useState<HTMLDataElement | null>(null);
  const [isStatusSelect, setIsStatusSelect] = useState<boolean>(false);
  const isScreenWidthReduceCheck = useMediaQuery(theme.breakpoints.down('xxl'));

  const { data: licenseDetail } = useGetLicense(licenseId ?? '');
  const { mutate: licenseStatusUpdate } = useLicenseStatusUpdate();

  const subscription = useSubscriptionModel();

  const handleOpenUserInfo = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (licenseDetail && licenseDetail.licenseUserType !== 'INDIVIDUAL' && licenseDetail.subscriptionUsers.length > 1) {
      setIsMultiUsersAnchorEl(event.currentTarget);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleCloseUserInfo = () => {
    if (licenseDetail && licenseDetail.licenseUserType !== 'INDIVIDUAL' && licenseDetail.subscriptionUsers.length > 1) {
      setIsMultiUsersAnchorEl(null);
    } else {
      setAnchorEl(null);
    }
  };

  const handleLicenseUpdateOpen = () => {
    setIsUpdateLicenseOpen(true);
  };

  const handleLicenseUpdateClose = () => {
    setIsUpdateLicenseOpen(false);
  };

  const licenseStatusCheck: () => LicenseStatus = () => {
    if (!licenseDetail?.isActive) {
      return 'EXPIRED';
    }
    return licenseDetail.licenseStatus;
  };

  const handleClickStatusBox = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    setIsStatusSelect(!isStatusSelect);
  };

  const handleCloseStatusBox = () => {
    setIsStatusSelect(false);
  };

  const handleClickLiceseStatusUpdate = (updateStatus: 'ACTIVE' | 'INACTIVE') => {
    if (updateStatus === licenseDetail?.licenseStatus) return null;
    if (licenseDetail) {
      licenseStatusUpdate({ licenseId: licenseDetail?.licenseId, data: updateStatus });
    }
    return null;
  };

  return (
    <Container>
      <Header onClick={() => navigate(-1)}>
        <ChevronIcon width={20} height={20} rotateNum={270} />
        <HeaderText>{t('Subscrib_Detail_License_15')}</HeaderText>
      </Header>
      <Body>
        <BodyTitle>
          <TitleText>{t('Subscrib_Detail_License_16')}</TitleText>
          {subscription.isExternalSubscription && (
            <IconBox onClick={handleLicenseUpdateOpen}>
              <PencilIcon width={14} height={14} />
            </IconBox>
          )}
        </BodyTitle>
        <BillingInfoGrid>
          <BillingInfoItem>
            <dt>{t('Subscrib_Detail_License_01')}</dt>
            <dd>{licenseDetail?.licenseCode ? licenseDetail.licenseCode : '-'}</dd>
          </BillingInfoItem>
          <BillingInfoItem>
            <dt>{t('Acc_Detail_05')}</dt>
            <dd>
              {dateFormat(licenseDetail?.startDate, '-', t('DateFormat_YMD'))} ~{' '}
              {dateFormat(licenseDetail?.endDate, '-', t('DateFormat_YMD'))}
            </dd>
          </BillingInfoItem>
          <BillingInfoItem>
            <dt>갱신 날짜</dt>
            <dd>{dateFormat(licenseDetail?.renewalDate, '-', t('DateFormat_YMD'))}</dd>
          </BillingInfoItem>
          <BillingInfoItem licenseStatus isStatusSelect={isStatusSelect}>
            <dt>상태</dt>
            <dd>
              {(() => {
                switch (licenseStatusCheck()) {
                  case 'ACTIVE':
                    return (
                      <Popover
                        open={isStatusSelect}
                        onClose={handleCloseStatusBox}
                        popoverContent={
                          <PopoverContainer>
                            <PopoverRow
                              onClick={() => handleClickLiceseStatusUpdate('ACTIVE')}
                              selected={licenseDetail?.licenseStatus === 'ACTIVE'}
                            >
                              <Label variant="solid" color="blue">
                                활성
                              </Label>
                            </PopoverRow>
                            <PopoverRow
                              onClick={() => handleClickLiceseStatusUpdate('INACTIVE')}
                              selected={licenseDetail?.licenseStatus === 'INACTIVE'}
                            >
                              <Label variant="solid" color="gray">
                                비활성
                              </Label>
                            </PopoverRow>
                          </PopoverContainer>
                        }
                      >
                        {isStatusSelect ? (
                          <StatusBoxInput
                            isStatusSelect={isStatusSelect}
                            onClick={event => handleClickStatusBox(event)}
                          >
                            <Label variant="solid" color="blue">
                              활성
                            </Label>
                            <TriangleIcon width={16} height={16} rotateNum={isStatusSelect ? 0 : 180} />
                          </StatusBoxInput>
                        ) : (
                          <StatusBox isStatusSelect={isStatusSelect} onClick={event => handleClickStatusBox(event)}>
                            <Label variant="solid" color="blue">
                              활성
                            </Label>
                            <TriangleIcon width={16} height={16} rotateNum={isStatusSelect ? 0 : 180} />
                          </StatusBox>
                        )}
                      </Popover>
                    );
                  case 'EXPIRED':
                    return (
                      <StatusBox>
                        <Label variant="solid" color="white">
                          만료
                        </Label>
                      </StatusBox>
                    );
                  case 'INACTIVE':
                    return (
                      <Popover
                        open={isStatusSelect}
                        onClose={handleCloseStatusBox}
                        popoverContent={
                          <PopoverContainer>
                            <PopoverRow
                              onClick={() => handleClickLiceseStatusUpdate('ACTIVE')}
                              selected={licenseDetail?.licenseStatus === 'ACTIVE'}
                            >
                              <Label variant="solid" color="blue">
                                활성
                              </Label>
                            </PopoverRow>
                            <PopoverRow
                              onClick={() => handleClickLiceseStatusUpdate('INACTIVE')}
                              selected={licenseDetail?.licenseStatus === 'INACTIVE'}
                            >
                              <Label variant="solid" color="gray">
                                비활성
                              </Label>
                            </PopoverRow>
                          </PopoverContainer>
                        }
                      >
                        {isStatusSelect ? (
                          <StatusBoxInput
                            isStatusSelect={isStatusSelect}
                            onClick={event => handleClickStatusBox(event)}
                          >
                            <Label variant="solid" color="gray">
                              비활성
                            </Label>
                            <TriangleIcon width={16} height={16} rotateNum={isStatusSelect ? 0 : 180} />
                          </StatusBoxInput>
                        ) : (
                          <StatusBox isStatusSelect={isStatusSelect} onClick={event => handleClickStatusBox(event)}>
                            <Label variant="solid" color="gray">
                              비활성
                            </Label>
                            <TriangleIcon width={16} height={16} rotateNum={isStatusSelect ? 0 : 180} />
                          </StatusBox>
                        )}
                      </Popover>
                    );
                  default:
                    return null;
                }
              })()}
            </dd>
          </BillingInfoItem>
          <BillingInfoItem>
            <dt>{t('Subscrib_Main_12')}</dt>
            <dd>{licenseDetail?.plan.planName}</dd>
          </BillingInfoItem>
          <BillingInfoItem>
            <dt>{t('Subscrib_Edit_01')}</dt>
            <dd>{licenseDetail?.planOption.optionName}</dd>
          </BillingInfoItem>
          <BillingInfoItem>
            <dt>계약 단가</dt>
            <dd>
              {currencyTextFormat(
                licenseDetail?.contractUnitPrice,
                licenseDetail?.currencyUnit ?? licenseDetail?.plan.currencyUnit,
              )}
            </dd>
          </BillingInfoItem>
          <BillingInfoItem>
            <dt>{t('Subscrib_Detail_Overview_16')}</dt>
            {licenseDetail?.subscriptionUsers && licenseDetail.subscriptionUsers.length > 0 ? (
              <StyleDd
                onClick={handleOpenUserInfo}
                isOpenUserPopup={licenseDetail.subscriptionUsers.length > 1 ? isMultiUsersAnchorEl : anchorEl}
              >
                {licenseDetail.subscriptionUsers[0].user?.name
                  ? licenseDetail.subscriptionUsers[0].user.name
                  : licenseDetail.subscriptionUsers[0].userName}{' '}
                {licenseDetail.subscriptionUsers.length > 1
                  ? `${t('GNB_Profile_MyProfile_05')} ${licenseDetail.subscriptionUserCount - 1} ${t('Home_People')}`
                  : ''}
              </StyleDd>
            ) : (
              <dd>-</dd>
            )}
          </BillingInfoItem>
        </BillingInfoGrid>
      </Body>
      <Footer>
        <AdditionalCommitmentList />
      </Footer>
      {licenseDetail && licenseDetail?.subscriptionUsers.length > 0 && (
        <LicenseUserInfoPopup
          anchorEl={anchorEl}
          onClose={handleCloseUserInfo}
          isScreenWidthReduceCheck={isScreenWidthReduceCheck}
          subscriptionId={subscription.id}
          userLoginId={licenseDetail?.subscriptionUsers[0].userLoginId}
        />
      )}
      {licenseDetail && licenseDetail?.subscriptionUsers.length > 1 && (
        <LicenseMultiUserListPopup
          anchorEl={isMultiUsersAnchorEl}
          licneseId={licenseDetail?.licenseId}
          onClose={handleCloseUserInfo}
          isScreenWidthReduceCheck={isScreenWidthReduceCheck}
        />
      )}
      <LicenseEditorModal
        subscription={subscription}
        selectLicense={licenseDetail}
        dialogType="update"
        open={isUpdateLicenseOpen}
        onClose={handleLicenseUpdateClose}
      />
    </Container>
  );
};

const Container = styled.div`
  margin-top: 12px;
  padding: 40px 16px 28px 16px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 28px;
  cursor: pointer;
  gap: 4px;
`;

const HeaderText = styled.span`
  ${fonts.Headline7}
  color: ${({ theme: { colors } }) => colors['text-gray-main']};
`;

const Body = styled.div`
  margin-bottom: 8px;
`;

const BodyTitle = styled.div`
  display: flex;
  align-items: center;
  padding: 6px 0px 6px 8px;
`;

const TitleText = styled.span`
  ${fonts.Headline8}
  color: ${({ theme: { colors } }) => colors['text-gray-main']};
`;

const IconBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  padding: 5px 5px 5px 5px;
  margin-left: 8px;
  border: 1px solid ${({ theme: { colors } }) => colors['border-gray-light']};
  cursor: pointer;
`;

const BillingInfoGrid = styled.dl`
  border: 1px solid ${({ theme: { colors } }) => colors['border-gray-lighter']};
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 1px;
  column-gap: 1px;
  background-color: ${({ theme: { colors } }) => colors['border-gray-w-lighter']};
  margin-bottom: 72px;
  border-radius: 5px;
  box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.02), 0px 0px 3px 0px rgba(0, 0, 0, 0.07);
  overflow: hidden;
  margin-top: 8px;
`;

const BillingInfoItem = styled.div<{ licenseStatus?: boolean; isStatusSelect?: boolean }>`
  padding: ${({ licenseStatus }) => (licenseStatus ? '15px 6px 0px' : '15px 20px')};
  background-color: ${({ theme: { colors } }) => colors['bg-gray-lighter']};

  & > dt {
    ${fonts.Headline9}
    color: ${({ theme: { colors } }) => colors['text-gray-sub-dark']};
    margin-bottom: ${({ isStatusSelect }) => (isStatusSelect ? '0px' : '2px')};
    padding: ${({ licenseStatus }) => (licenseStatus ? '0px 14px' : '0px 0px')};
  }

  & > dd {
    ${fonts.Headline8}
    display: flex;
    width: fit-content;
    color: ${({ theme: { colors } }) => colors['text-gray-main']};
  }
`;

const StyleDd = styled.dd<{ isOpenUserPopup: HTMLButtonElement | HTMLDataElement | null }>`
  display: inline-block;
  ${fonts.Headline8}
  color: ${({ theme: { colors } }) => colors['text-blue-light']} !important;
  padding-right: 8px;
  cursor: pointer;
  text-decoration: ${props => (props.isOpenUserPopup ? 'underline' : undefined)};
  &:hover {
    text-decoration: underline;
  }
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StatusBox = styled.div<{ isStatusSelect?: boolean }>`
  padding: 3px 0px 5px 14px;
  width: 108px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`;

const StatusBoxInput = styled.div<{ isStatusSelect?: boolean }>`
  padding: 5px 8px 6px 13px;
  box-sizing: border-box;
  width: 116px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: ${({ isStatusSelect, theme }) =>
    isStatusSelect ? `1px solid ${theme.colors['border-gray-light']}` : undefined};
  border-radius: 2px;
`;
