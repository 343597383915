import { PaymentStatType, TenantPaymentStatDTO } from '@repositories/tenantBillingStatRepository/Types';

export class TenantPaymentStatDTOModel {
  private _dto: TenantPaymentStatDTO;

  constructor(dto: TenantPaymentStatDTO) {
    this._dto = dto;
  }

  get dto(): TenantPaymentStatDTO {
    return this._dto;
  }

  get cost(): number {
    return this._dto.cost ?? 0;
  }

  get name(): string {
    return this._dto.name;
  }

  get type(): PaymentStatType {
    return this._dto.type;
  }

  get currencyUnit(): CurrencyUnit {
    return this._dto.currencyUnit;
  }

  get iconUrl(): string {
    return this._dto.iconUrl ?? '';
  }
}
