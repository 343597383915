import { SubscriptionSimpleModel } from '@models/SubscriptionSimpleModel';
import { TenantSimpleModel } from '@models/tenantModels';
import { UserGroupDTO } from '@repositories/userGroupsRepository/Types';
import dayjs, { Dayjs } from 'dayjs';
import { UserGroupSimpleModel } from './UserGroupSimpleModel';

export class UserGroupModel {
  private _deleted: boolean;
  private _description: string;
  private _name: string;
  private _parentUserGroup: UserGroupSimpleModel;
  private _properties: Properties;
  private _subUserGroups: UserGroupSimpleModel[];
  private _subscriptions: SubscriptionSimpleModel[];
  private _tenant: TenantSimpleModel;
  private _userGroupId: string;
  private _createdBy: string;
  private _createdDate: Dayjs;
  private _updatedBy: string;
  private _updatedDate: Dayjs;
  private _userGroupCode: string;

  constructor(dto?: UserGroupDTO) {
    this._deleted = dto?.deleted || false;
    this._description = dto?.description || '';
    this._name = dto?.name || '';
    this._parentUserGroup = new UserGroupSimpleModel(dto?.parentUserGroup);
    this._properties = dto?.properties || {};
    this._subUserGroups = dto?.subUserGroups?.map(sub => new UserGroupSimpleModel(sub)) || [];
    this._subscriptions = dto?.subscriptions?.map(subscription => new SubscriptionSimpleModel(subscription)) || [];
    this._tenant = new TenantSimpleModel(dto?.tenant);
    this._userGroupId = dto?.userGroupId || '';
    this._createdBy = dto?.createdBy || '';
    this._createdDate = dayjs(dto?.createdDate);
    this._updatedBy = dto?.updatedBy || '';
    this._updatedDate = dayjs(dto?.updatedDate);
    this._userGroupCode = dto?.userGroupCode || '';
  }

  get deleted() {
    return this._deleted;
  }

  get description() {
    return this._description;
  }

  get name() {
    return this._name;
  }

  get parentUserGroup() {
    return this._parentUserGroup;
  }

  get properties() {
    return this._properties;
  }

  get subUserGroups() {
    return this._subUserGroups;
  }

  get subscriptions() {
    return this._subscriptions;
  }

  get tenant() {
    return this._tenant;
  }

  get userGroupId() {
    return this._userGroupId;
  }

  get createdBy() {
    return this._createdBy;
  }

  get createdDate() {
    return this._createdDate;
  }

  get updatedBy() {
    return this._updatedBy;
  }

  get updatedDate() {
    return this._updatedDate;
  }

  get userGroupCode() {
    return this._userGroupCode;
  }
}
