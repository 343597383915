import type {
  MonthlyBillingDTO,
  BillingStatus,
  MonthlyBillingUpdateDTO,
  StatusUpdateRq,
} from '@repositories/monthlyBillingRepository';
import type { PlanScope } from '@repositories/softwareRepository';
import { SoftwareProviderModel } from './SoftwareProviderModel';
import { SubscriptionSnapshotModel } from './SubscriptionSnapshotModel';
import { FileSimpleModel } from './file/FileSimpleModel';

export class SubscriptionBillingModel {
  private _dto: MonthlyBillingDTO;
  private _subscription: SubscriptionSnapshotModel;
  private _subSubscriptionBillings: SubscriptionBillingModel[];
  private _attachments: FileSimpleModel[];
  private _provider?: SoftwareProviderModel;

  constructor(dto: MonthlyBillingDTO) {
    this._dto = dto;
    this._subscription = new SubscriptionSnapshotModel(dto.subscription);
    this._subSubscriptionBillings = dto?.subSubscriptionBillings?.length
      ? dto?.subSubscriptionBillings.map(sub => new SubscriptionBillingModel(sub))
      : [];
    this._attachments = dto.attachments ? dto.attachments.map(dto => new FileSimpleModel(dto)) : [];
    this._provider = dto.provider ? new SoftwareProviderModel(dto.provider) : undefined;
  }

  get billingDate() {
    return this._dto.billingDate;
  }

  get cost() {
    return this._dto.cost;
  }

  get costDiff() {
    return this._dto.costDiff;
  }

  get createDate() {
    return this._dto.createDate;
  }

  get updatedDate() {
    return this._dto.updatedDate;
  }

  get currencyUnit() {
    return this._dto.currencyUnit;
  }

  get exchangeRate() {
    return this._dto.exchangeRate;
  }

  get id() {
    return this._dto.id;
  }

  get moduleUsageCosts() {
    return this._dto.moduleUsageCosts;
  }

  get month() {
    return this._dto.month;
  }

  get originalCost() {
    return this._dto.originalCost;
  }

  get originalCostDiff() {
    return this._dto.originalCostDiff;
  }

  get originalCurrencyUnit() {
    return this._dto.originalCurrencyUnit;
  }

  get paymentDate() {
    return this._dto.paymentDate;
  }

  get provider(): SoftwareProviderModel | undefined {
    return this._provider ?? undefined;
  }

  get status() {
    return this._dto.status;
  }

  get subscription() {
    return this._subscription;
  }

  get usage() {
    return this._dto.usage;
  }

  get startDate() {
    return this._dto.startDate;
  }

  get endDate() {
    return this._dto.endDate;
  }

  get subSubscriptionBillings() {
    return this._subSubscriptionBillings;
  }

  get version() {
    return this._dto.version;
  }

  get isPayment(): boolean {
    return this._dto.status === 'PAID';
  }

  get isManuallyEdited(): boolean {
    return !!this._dto.updatedBy && this._dto.updatedBy !== '00000000-0000-0000-0000-000000000000';
  }

  get isExternalSubscription() {
    return this.subscription.isExternalSubscription;
  }

  get isLatest(): boolean {
    return this._dto.isLatest;
  }

  get subscriptionId(): string {
    return this._dto.subscriptionId;
  }

  get isForecasted(): boolean {
    return this.status === 'FORECASTED';
  }

  get attachments() {
    return this._attachments;
  }

  get isConsolidatedBilling(): boolean {
    return !!this._dto.tenantBillingId || this.planScope === 'TENANT';
  }

  get planScope(): PlanScope {
    return this._subscription?.productPlan?.planScope || 'SUBSCRIPTION';
  }

  getUpdateDTO(updateValue: Partial<MonthlyBillingUpdateDTO>): MonthlyBillingUpdateDTO {
    return {
      billingDate: this._dto.billingDate ?? null,
      cost: this._dto.cost,
      currencyUnit: this._dto.currencyUnit,
      endDate: this._dto.endDate ?? null,
      exchangeRate: this._dto.exchangeRate ?? null,
      id: this._dto.id,
      moduleUsageCosts: this._dto.moduleUsageCosts ?? [],
      month: this._dto.month,
      originalCost: this._dto.originalCost,
      originalCurrencyUnit: this._dto.originalCurrencyUnit,
      paymentDate: this._dto.paymentDate ?? null,
      startDate: this._dto.startDate ?? null,
      status: this._dto.status ?? null,
      subscriptionId: this._dto.subscription.subscriptionId,
      subscriptionVersion: this._dto.subscription.subscriptionVersion ?? null,
      ...updateValue,
    };
  }

  getUpdateStatusDTO(status: BillingStatus): StatusUpdateRq {
    return {
      id: this._dto.id,
      status,
    };
  }
}
