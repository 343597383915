import dayjs from 'dayjs';
import { TenantBillingStatDTO } from '@repositories/tenantBillingStatRepository/Types';

export class TenantBillingStatModel {
  private _dataModel: TenantBillingStatDTO;

  constructor(dto: TenantBillingStatDTO) {
    this._dataModel = dto;
  }

  get tenantId() {
    return this._dataModel.tenantId;
  }

  get month() {
    return this._dataModel.month;
  }

  get annualBillingAmountEstimate() {
    return this._dataModel.annualBillingAmountEstimate;
  }

  get annualPayment() {
    return this._dataModel.annualPayment;
  }

  get annualPaymentEstimate() {
    return this._dataModel.annualPaymentEstimate;
  }

  get monthlyBillingAmount() {
    return this._dataModel.monthlyBillingAmount;
  }

  get monthlyPayment() {
    return this._dataModel.monthlyPayment;
  }

  get monthlyPaymentEstimate() {
    return this._dataModel.monthlyPaymentEstimate;
  }

  get monthlyPaymentEstimateGrowthRate() {
    return this._dataModel.monthlyPaymentEstimateGrowthRate * 100;
  }

  get previousMonthlyBillingAmount() {
    return this._dataModel.previousMonthlyBillingAmount;
  }

  get previousMonthlyPayment() {
    return this._dataModel.previousMonthlyPayment;
  }

  get monthlyBillingAmountEstimateGrowthRate() {
    return this._dataModel.monthlyBillingAmountEstimateGrowthRate;
  }

  get optimizedAnnualPaymentEstimate() {
    return this._dataModel.optimizedAnnualPaymentEstimate;
  }

  get remainingOptimizedAnnualPaymentEstimate() {
    return this._dataModel.remainingOptimizedAnnualPaymentEstimate;
  }

  get remainingAnnualPaymentEstimate() {
    return this._dataModel.remainingAnnualPaymentEstimate ?? 0;
  }

  get remainingMonthlyPaymentEstimate() {
    const remainingMonth = 12 - dayjs().get('month');
    return Math.floor(this.remainingAnnualPaymentEstimate / remainingMonth);
  }

  get remainingAnnualPaymentReductionEstimate() {
    return this._dataModel.remainingAnnualPaymentReductionEstimate ?? 0;
  }

  get remainingMonthlyPaymentReductionEstimate() {
    const remainingMonth = 12 - dayjs().get('month');
    return Math.floor(this.remainingAnnualPaymentReductionEstimate / remainingMonth);
  }

  get optimizableAnnualPaymentEstimate() {
    return this._dataModel.annualPaymentEstimate - this._dataModel.remainingOptimizedAnnualPaymentEstimate;
  }

  get optimizableAnnualPaymentEstimateGrowthRate() {
    return -(this.optimizableAnnualPaymentEstimate * 100) / this._dataModel.annualPaymentEstimate;
  }

  get optimizableMonthlyPaymentEstimate() {
    return Math.floor(this.optimizableAnnualPaymentEstimate / 12);
  }

  get optimizableMonthlyPaymentEstimateGrowthRate() {
    return this.optimizableAnnualPaymentEstimateGrowthRate;
  }
}
