import type { FormLicenseRequest } from './validationSchema';
import { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Box, FormHelperText, MenuItem, Select, TextField } from '@mui/material';
import dayjs from 'dayjs';
import { Dialog } from '@components/Dialog';
import { DatePicker } from '@components/datePicker';
import { Button } from '@components/index';
import { useStore } from '@stores/RootStore';
import { fonts } from '@theme/fontsCustomer';
import type { LicenseCreateDTO, LicenseCurrencyUnit, LicenseUserType } from '@repositories/licenseRepository';
import { useAddLicense, useGetLicense, useUpdateLicense } from '@queryHooks/useLicense';
import { useGetProductPlanDetail } from '@queryHooks/useSoftware';
import type { LicenseModel } from '@models/LicenseModel';
import type { SubscriptionModel } from '@models/SubscriptionModel';
import { CURRENCY_UNIT_TYPE_LIST, useLicenseAddForm } from './validationSchema';

type Props = {
  subscription: SubscriptionModel;
  selectLicense?: LicenseModel;
  dialogType: 'create' | 'update';
  open: boolean;
  onClose: () => void;
};

export const LicenseEditorModal = ({ subscription, selectLicense, dialogType, open, onClose }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const {
    uiStore: { toastStore },
  } = useStore();
  const [addAnotherLicense, setAddAnotherLicense] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const { data: planOptions } = useGetProductPlanDetail(subscription.software.id, subscription.plan.planId, {
    enabled: !!open,
  });
  /**
   * NOTE: 라이선스 목록, 상세 조회시 DTO의 형태는 같지만 목록 조회시 비어있는 필드가 있음
   * props로 넘어오는 selectLicense의 dto가 목록 조회에서 받은 LicenseDTO인 경우 비어있는 필드들이 수정 API 호출시 값이 사라짐
   * form에는 서버를 거치지 않고 바로 값을 표시하면서 위 이슈를 수정하기 위해 props는 유지하면서 상세 조회를 해오도록 수정
   */
  const { data: targetLicense } = useGetLicense(selectLicense?.licenseId ?? '');
  const { mutate: addLicense } = useAddLicense();
  const { mutate: licenseUpdate } = useUpdateLicense();

  const { handleSubmit, reset, control, formState, watch, trigger, register, setValue } = useLicenseAddForm();
  const [selectedStartDate, selectedEndDate] = watch(['startDate', 'endDate']);
  const handleClose = () => {
    reset();
    onClose();
  };

  const handleDateOnChange = (onChange: UnknownFunction, triggerType?: 'startDate') => (value: any) => {
    if (dayjs(value).format('YYYY.MM.DD') !== 'Invalid Date') {
      onChange(dayjs(value));
    } else if (value.target.value === '') {
      onChange(null);
    } else {
      onChange(dayjs(value));
    }

    if (triggerType) trigger(triggerType);
  };

  const validation = {
    success: (data: FormLicenseRequest) => {
      setIsSubmitting(true);

      if (dialogType === 'create') {
        const rqData: LicenseCreateDTO = {
          endDate: data.endDate ? dayjs(data.endDate).format('YYYY-MM-DD') : undefined,
          planId: subscription.plan.planId,
          planOptionId: data.planOptionId,
          startDate: dayjs(data.startDate).format('YYYY-MM-DD'),
          subscriptionId: subscription.id,
          currencyUnit: data.currencyUnit,
          renewalDate: data.renewalDate ? dayjs(data.renewalDate).format('YYYY-MM-DD') : undefined,
          licenseCode: data.licenseCode,
          licenseUserType: data.licenseUserType,
        };
        addLicense(rqData, {
          onSuccess: () => {
            toastStore.open(t('Subscrib_Detail_License_14'));
            if (addAnotherLicense) {
              setValue('licenseCode', undefined);
            } else {
              reset();
              onClose();
            }
            setIsSubmitting(false);
          },
          onError: () => {
            setIsSubmitting(false);
          },
        });
      } else {
        const updateDTO = targetLicense?.getLicenseUpdateDTO({
          planId: subscription.plan.planId,
          planOptionId: data.planOptionId,
          startDate: dayjs(data.startDate).format('YYYY-MM-DD'),
          endDate: data.endDate !== null ? dayjs(data.endDate).format('YYYY-MM-DD') : undefined,
          currencyUnit: data.currencyUnit,
          renewalDate: data.renewalDate ? dayjs(data.renewalDate).format('YYYY-MM-DD') : undefined,
          licenseCode: data.licenseCode,
        });

        if (updateDTO) {
          licenseUpdate(updateDTO, {
            onSuccess: () => {
              toastStore.open(t('Subscrib_Detail_License_25'));
              onClose();
              setIsSubmitting(false);
            },
            onError: () => {
              setIsSubmitting(false);
            },
          });
        }
      }
    },
  };

  useEffect(() => {
    if (dialogType === 'create') {
      reset({
        planOptionId: '',
        startDate: '',
        endDate: '',
        currencyUnit: subscription.currencyUnit,
        renewalDate: '',
        licenseUserType: subscription.plan.licenseUserType ?? 'MULTI_USER',
      });
    } else {
      reset({
        planOptionId: selectLicense?.planOption.planOptionId,
        startDate: selectLicense?.startDate,
        endDate: selectLicense?.endDate,
        currencyUnit: selectLicense?.currencyUnit,
        renewalDate: selectLicense?.renewalDate,
        licenseCode: selectLicense?.licenseCode,
        licenseUserType: selectLicense?.licenseUserType,
      });
    }
  }, [reset, dialogType, selectLicense, open, subscription.currencyUnit, subscription.plan.licenseUserType]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={dialogType === 'create' ? t('Subscrib_Detail_License_08') : t('Subscrib_Detail_License_27')}
      height={862}
      size="medium"
    >
      <AddLicenseForm onSubmit={handleSubmit(validation.success)}>
        <Container>
          <Element>
            <NameText>{t('Subscrib_Main_12')}</NameText>
            <PlanNameBox>
              <PlanName>{subscription.plan.planName}</PlanName>
            </PlanNameBox>
          </Element>
          <Element>
            <NameText>
              {t('Subscrib_Edit_01')}
              <EssentialMark>*</EssentialMark>
            </NameText>
            <Controller
              control={control}
              name="planOptionId"
              render={({ field: { ref, value, onChange } }) => (
                <FieldSelect
                  inputRef={ref}
                  id="plan-option-id"
                  value={value}
                  onChange={onChange}
                  size="medium"
                  fullWidth
                  displayEmpty
                  renderValue={(value: string) =>
                    planOptions?.planOptionList?.find(({ planOptionId }) => planOptionId === value)?.optionName ||
                    t('Subscrib_Edit_05')
                  }
                  error={!!formState.errors.planOptionId}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        width: '424px',
                        maxHeight: '208px',
                        marginTop: '9px',
                        borderRadius: '0 0 2px 2px',
                        boxShadow: '0',
                        border: `1px solid ${theme.colors['border-gray-light']}`,
                        '& .MuiList-root': {
                          border: 'none',
                          padding: '4px 0',
                          '& .MuiMenuItem-root': {
                            margin: '0 5px',
                          },
                        },
                      },
                    },
                  }}
                >
                  {planOptions?.planOptionList?.map(planOption => (
                    <StyledMenuItem key={planOption.planOptionId} value={planOption.planOptionId}>
                      {planOption.optionName}
                    </StyledMenuItem>
                  ))}
                </FieldSelect>
              )}
            />
            {formState.errors.planOptionId?.message && (
              <SelectHelperText>{formState.errors.planOptionId?.message}</SelectHelperText>
            )}
          </Element>
          <DateElement>
            <DateNameText>
              {t('Subscrib_Detail_License_09')}
              <EssentialMark>*</EssentialMark>
            </DateNameText>
            <NameText>{t('Subscrib_Detail_License_10')}</NameText>
          </DateElement>
          <Element>
            <Box display="flex" alignItems="flex-start" gap="16px">
              <DateBox>
                <Controller
                  control={control}
                  name="startDate"
                  render={({ field: { ref, value, onChange } }) => (
                    <DatePicker
                      inputRef={ref}
                      inputFormat={t('DateFormat_YMD')}
                      value={value}
                      onChange={handleDateOnChange(onChange)}
                      renderInput={params => (
                        <StyledDatePickerInput
                          {...params}
                          sx={{ width: '204px' }}
                          fullWidth
                          error={!!formState.errors.startDate}
                        />
                      )}
                      showDaysOutsideCurrentMonth
                      shouldDisableDate={day => {
                        const disableDate = dayjs(selectedEndDate);
                        return dayjs.isDayjs(day) ? day.isAfter(disableDate) : false;
                      }}
                    />
                  )}
                />
                {formState.errors.startDate?.message && (
                  <SelectHelperText>{formState.errors.startDate?.message}</SelectHelperText>
                )}
              </DateBox>
              <DateBox>
                <Controller
                  control={control}
                  name="endDate"
                  render={({ field: { ref, value, onChange } }) => (
                    <DatePicker
                      inputRef={ref}
                      inputFormat={t('DateFormat_YMD')}
                      value={value}
                      onChange={handleDateOnChange(onChange)}
                      renderInput={params => (
                        <StyledDatePickerInput
                          {...params}
                          sx={{ width: '204px' }}
                          fullWidth
                          error={!!formState.errors.endDate}
                          onChange={handleDateOnChange(onChange)}
                        />
                      )}
                      showDaysOutsideCurrentMonth
                      shouldDisableDate={day => {
                        const disableDate = dayjs(selectedStartDate).add(1, 'D');
                        return dayjs.isDayjs(day) ? day.isBefore(disableDate) : false;
                      }}
                    />
                  )}
                />
              </DateBox>
            </Box>
          </Element>
          <Element>
            <NameText>
              통화 코드
              <EssentialMark>*</EssentialMark>
            </NameText>
            <Controller
              control={control}
              name="currencyUnit"
              render={({ field: { ref, value, onChange } }) => (
                <FieldSelect
                  inputRef={ref}
                  id="currencyUnit"
                  value={value}
                  onChange={onChange}
                  size="medium"
                  fullWidth
                  displayEmpty
                  renderValue={(value: LicenseCurrencyUnit) => value}
                  error={!!formState.errors.currencyUnit}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        width: '424px',
                        maxHeight: '208px',
                        marginTop: '9px',
                        borderRadius: '0 0 2px 2px',
                        boxShadow: '0',
                        border: `1px solid ${theme.colors['border-gray-light']}`,
                        '& .MuiList-root': {
                          border: 'none',
                          padding: '4px 0',
                          '& .MuiMenuItem-root': {
                            margin: '0 5px',
                          },
                        },
                      },
                    },
                  }}
                >
                  {CURRENCY_UNIT_TYPE_LIST.map(unit => (
                    <StyledMenuItem key={unit} value={unit}>
                      {unit}
                    </StyledMenuItem>
                  ))}
                </FieldSelect>
              )}
            />
            {formState.errors.currencyUnit?.message && (
              <SelectHelperText>{formState.errors.currencyUnit?.message}</SelectHelperText>
            )}
          </Element>
          <Element>
            <NameText>갱신 날짜</NameText>
            <Controller
              control={control}
              name="renewalDate"
              render={({ field: { ref, value, onChange } }) => (
                <DatePicker
                  inputRef={ref}
                  inputFormat={t('DateFormat_YMD')}
                  value={value}
                  onChange={handleDateOnChange(onChange, 'startDate')}
                  renderInput={params => (
                    <StyledDatePickerInput
                      {...params}
                      sx={{ width: '100%' }}
                      fullWidth
                      error={!!formState.errors.endDate}
                      onChange={handleDateOnChange(onChange, 'startDate')}
                    />
                  )}
                  showDaysOutsideCurrentMonth
                  shouldDisableDate={day => {
                    const disableDate = dayjs(selectedStartDate).add(1, 'D');
                    return dayjs.isDayjs(day) ? day.isBefore(disableDate) : false;
                  }}
                />
              )}
            />
          </Element>
          <Element>
            <NameText>라이선스 코드</NameText>
            <TextField {...register('licenseCode')} />
          </Element>
          <Element>
            <NameText>
              라이선스 타입
              <EssentialMark>*</EssentialMark>
            </NameText>
            <Controller
              control={control}
              name="licenseUserType"
              render={({ field: { ref, value, onChange } }) => (
                <FieldSelect
                  inputRef={ref}
                  id="licenseUserType"
                  value={value}
                  onChange={onChange}
                  size="medium"
                  fullWidth
                  displayEmpty
                  error={!!formState.errors.licenseUserType}
                >
                  <StyledMenuItem key="INDIVIDUAL" value="INDIVIDUAL">
                    싱글
                  </StyledMenuItem>
                  <StyledMenuItem key="MULTI_USER" value="MULTI_USER">
                    멀티
                  </StyledMenuItem>
                </FieldSelect>
              )}
            />
            {formState.errors.licenseUserType?.message && (
              <SelectHelperText>{formState.errors.licenseUserType?.message}</SelectHelperText>
            )}
          </Element>

          <InfoPosition>
            {dialogType === 'create' && (
              <CheckboxPosition>
                <input
                  type="checkbox"
                  checked={addAnotherLicense}
                  onChange={e => setAddAnotherLicense(e.target.checked)}
                />
                <TextBox>다른 라이선스 계속 추가 </TextBox>
              </CheckboxPosition>
            )}
            <InfoBox>
              <TextLi>{t('Subscrib_Detail_License_12')}</TextLi>
            </InfoBox>
          </InfoPosition>
          <ButtonPosition>
            <ButtonBox>
              <Button
                type="submit"
                disabled={isSubmitting}
                variant="contain"
                color="purple"
                size="extraLarge"
                paddingHorizontal={69.5}
                style={{ maxWidth: '168px', whiteSpace: 'pre' }}
              >
                {t('Additional_Commitment_08')}
              </Button>
              <Button
                variant="outline"
                color="gray"
                size="extraLarge"
                paddingHorizontal={69.5}
                onClick={handleClose}
                style={{ maxWidth: '168px', whiteSpace: 'pre' }}
              >
                {t('Subscrib_Detail_BuyRequest_13')}
              </Button>
            </ButtonBox>
          </ButtonPosition>
        </Container>
      </AddLicenseForm>
    </Dialog>
  );
};

const InfoPosition = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
`;

const AddLicenseForm = styled.form`
  position: relative;
  height: 100%;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const EssentialMark = styled.span`
  margin-left: 2px;
  color: ${({ theme: { colors } }) => colors['ic-red-light']};
`;

const Element = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
`;

const NameText = styled.span`
  ${fonts.Headline8}
  margin-bottom: 6px;
`;

const PlanNameBox = styled.div`
  padding: 10px;
  background-color: ${({ theme: { colors } }) => colors['bg-gray-light']};
  border: 1px solid ${({ theme: { colors } }) => colors['border-gray-light']};
  border-radius: 2px;
`;

const PlanName = styled.span`
  ${fonts.Body2};
  color: ${({ theme: { colors } }) => colors['text-gray-main']};
`;

const DateElement = styled.div`
  display: flex;
`;

const DateNameText = styled.span`
  ${fonts.Headline8}
  margin-bottom: 6px;
  width: 220px;
`;

const StyledDatePickerInput = styled(DatePicker.Input)`
  & .MuiInputBase-root {
    &.Mui-disabled {
      background-color: ${({ theme: { colors } }) => colors['bg-gray-light']};
    }
    &.Mui-disabled.Mui-error,
    &.Mui-error {
      background-color: ${({ theme: { colors } }) => colors['bg-white']};
      & .MuiOutlinedInput-notchedOutline {
        border: 1px solid ${({ theme: { colors } }) => colors['border-red-light']};
      }
      & .MuiSelect-select {
        color: ${({ theme: { colors } }) => colors['text-red-light']};
        -webkit-text-fill-color: ${({ theme: { colors } }) => colors['text-red-light']};
      }
      & .MuiInputBase-input {
        &::-webkit-input-placeholder {
          -webkit-text-fill-color: ${({ theme: { colors } }) => colors['text-red-light']};
          color: ${({ theme }) => theme.colors['text-red-light']};
        }
        &:-moz-placeholder {
          -webkit-text-fill-color: ${({ theme: { colors } }) => colors['text-red-light']};
          color: ${({ theme }) => theme.colors['text-red-light']};
        }
        &::-moz-placeholder {
          -webkit-text-fill-color: ${({ theme: { colors } }) => colors['text-red-light']};
          color: ${({ theme }) => theme.colors['text-red-light']};
        }
        &:-ms-input-placeholder {
          -webkit-text-fill-color: ${({ theme: { colors } }) => colors['text-red-light']};
          color: ${({ theme }) => theme.colors['text-red-light']};
        }
      }
      & .MuiOutlinedInput-notchedOutline {
        border-color: ${({ theme }) => theme.colors['border-red-light']};
      }
      & svg g path {
        fill: ${({ theme }) => theme.colors['ic-red-light']};
        opacity: 1;
      }
    }
  }
`;

const CheckboxPosition = styled.div`
  position: absolute;
  width: 424px;
  top: 570px;
  left: 0px;
  display: flex;
`;

const InfoBox = styled.div`
  padding: 12px 33px 12px 28px;
  width: 424px;
  border-radius: 5px;
  position: absolute;
  top: 612px;
  left: 0px;
  background-color: ${({ theme }) => theme.colors['bg-gray-light-50']};
`;

const TextLi = styled.li`
  position: relative;
  ${fonts.Body2}
  color: ${({ theme }) => theme.colors['text-gray-sub-dark']};
  list-style-type: none;
  white-space: pre;
  &::before {
    position: absolute;
    content: '•';
    color: ${({ theme }) => theme.colors['ic-gray-light']};
    font-size: 1.5em;
    left: -15px;
    top: -2px;
    opacity: 30%;
  }
`;

const ButtonPosition = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
`;

const ButtonBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 8px;
  margin-top: 50px;
  position: absolute;
  top: 655px;
  left: 207px;
`;

const FieldSelect = styled(Select)`
  &.Mui-disabled {
    background-color: ${({ theme: { colors } }) => colors['bg-gray-light-m']};
    & .MuiOutlinedInput-notchedOutline {
      border: 1px solid ${({ theme: { colors } }) => colors['border-gray-light']};
    }
    & .MuiSelect-select {
      color: ${({ theme: { colors } }) => colors['text-gray-light']};
      -webkit-text-fill-color: ${({ theme: { colors } }) => colors['text-gray-light']};
    }
    & .MuiSelect-icon path {
      fill: ${({ theme: { colors } }) => colors['text-gray-light']};
    }
  }

  &.Mui-disabled.Mui-error,
  &.Mui-error {
    background-color: ${({ theme: { colors } }) => colors['bg-white']};
    & .MuiOutlinedInput-notchedOutline {
      border: 1px solid ${({ theme: { colors } }) => colors['border-red-light']};
    }
    & .MuiSelect-select {
      color: ${({ theme: { colors } }) => colors['text-red-light']};
      -webkit-text-fill-color: ${({ theme: { colors } }) => colors['text-red-light']};
    }
    & .MuiSelect-icon path {
      fill: ${({ theme: { colors } }) => colors['text-red-light']};
    }
  }
` as unknown as typeof Select;

const StyledMenuItem = styled(MenuItem)``;

const SelectHelperText = styled(FormHelperText)`
  margin: 0;
  color: ${({ theme: { colors } }) => colors['text-red-light']};
  ${fonts.Body4};
`;

const DateBox = styled.div`
  display: flex;
  flex-direction: column;
`;

const TextBox = styled.div`
  ${fonts.Body2};
  list-style: none;
  padding: 12px 33px 12px 16px;
  display: flex;
`;
