import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import { Loader } from '@components/loader';
import { CalendarStartIcon } from '@icons/CalendarStartIcon';
import {
  CalendarEndIcon,
  GroupIcon,
  GroupNameIcon,
  GroupUpperIcon,
  PencilLineIcon,
  PersonIcon,
  SubscriptionIcon,
} from '@icons/index';
import { useGetUserGroup, useGetUserGroupMembers, useUpdateUserGroup } from '@queryHooks/useUserGroup';
import { UserGroupModel } from '@models/userGroupModels/UserGroupModel';
import { ParentGroupEidt } from './InfoParentGroupEdit';
import * as S from './InfoStyles';

type FormData = {
  name: string;
  description?: string;
  userGroupCode?: string;
  parentGroup: string;
};

type EditMode = {
  name: boolean;
  userGroupCode: boolean;
  description: boolean;
  parentGroup: boolean;
};

export const Info = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [isEditMode, setIsEditMode] = useState<EditMode>({
    name: true,
    userGroupCode: true,
    description: true,
    parentGroup: true,
  });

  const { id } = useParams();
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const { mutate: updateUserGroup } = useUpdateUserGroup();

  const { data: userGroup } = useGetUserGroup(id as string);
  const { data: pagedMembers, isSuccess } = useGetUserGroupMembers(id ?? '');

  const {
    register,
    getValues,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    mode: 'onChange',
    shouldFocusError: true,
    defaultValues: {
      description: userGroup?.description,
      name: userGroup?.name,
      userGroupCode: userGroup?.userGroupCode,
    },
  });

  const handleEnterKey = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (userGroup) {
      if (e.currentTarget.dataset.key === 'name') {
        if (e.key === 'Enter') {
          updateUserGroup(
            {
              description: userGroup.description,
              name: getValues('name'),
              parentUserGroupId: userGroup.parentUserGroup.userGroupId,
              userGroupCode: userGroup.userGroupCode,
              userGroupId: userGroup.userGroupId,
            },
            {
              onSuccess: () => {
                setIsEditMode(prev => {
                  return { ...prev, name: true };
                });
              },
            },
          );
        }
        if (e.key === 'Escape') {
          setIsEditMode(prev => {
            return { ...prev, name: true };
          });
        }
      }

      if (e.currentTarget.dataset.key === 'code') {
        if (e.key === 'Enter') {
          updateUserGroup(
            {
              description: userGroup.description,
              name: userGroup.name,
              parentUserGroupId: userGroup.parentUserGroup.userGroupId,
              userGroupCode: getValues('userGroupCode'),
              userGroupId: userGroup.userGroupId,
            },
            {
              onSuccess: () => {
                setIsEditMode(prev => {
                  return { ...prev, userGroupCode: true };
                });
              },
            },
          );
        }
        if (e.key === 'Escape') {
          setIsEditMode(prev => {
            return { ...prev, userGroupCode: true };
          });
        }
      }
    }
  };

  const handleTextFieldClick = () => {
    setIsEditMode(prev => ({ ...prev, description: true }));
  };

  const handleTextFieldBlur = () => {
    if (userGroup) {
      updateUserGroup(
        {
          description: getValues('description'),
          name: userGroup?.name,
          parentUserGroupId: userGroup?.parentUserGroup.userGroupId,
          userGroupCode: userGroup?.userGroupCode,
          userGroupId: userGroup?.userGroupId,
        },
        {
          onSuccess: () => {
            setIsEditMode(prev => ({ ...prev, description: true }));
          },
        },
      );
    }
  };

  const nameRegister = register('name', { required: '그룹 이름을 입력해주세요' });
  const userGroupRegister = register('userGroupCode', { required: '그룹 코드를 입력해주세요' });

  useEffect(() => {
    reset({
      description: userGroup?.description,
      userGroupCode: userGroup?.userGroupCode,
      name: userGroup?.name,
    });
  }, [reset, userGroup]);

  return (
    <>
      <S.Container>
        <S.InfoWrapper>
          <S.InfoNameWrapper>
            <GroupNameIcon width={16} height={16} color={theme.colors['ic-gray-dark']} />
            <strong>{t('Member_Group_13')}</strong>
          </S.InfoNameWrapper>
          {isEditMode.name ? (
            <S.InfoValue>{userGroup?.name}</S.InfoValue>
          ) : (
            <S.GroupTextField
              {...nameRegister}
              fullWidth
              autoComplete="off"
              size="small"
              inputRef={(ref: HTMLInputElement) => {
                if (ref) {
                  nameRegister.ref(ref);
                  ref.setSelectionRange(0, ref.value.length);
                }
              }}
              onClick={e => e.stopPropagation()}
              onKeyUp={handleEnterKey}
              error={!!errors.name?.message}
              className={isEditMode.name ? 'read-mode' : 'edit-mode'}
              data-key="name"
              autoFocus
            />
          )}
          {isEditMode.name && (
            <S.StyledButton
              size="small"
              variant="outline"
              onClick={() => {
                setIsEditMode(prev => ({ ...prev, name: false }));
              }}
              paddingHorizontal={12}
            >
              {t('Ad_Cart_09')}
            </S.StyledButton>
          )}
        </S.InfoWrapper>
        <S.InfoWrapper>
          <S.InfoNameWrapper>
            <GroupIcon width={16} height={16} color={theme.colors['ic-gray-dark']} />
            <strong>{t('Member_Group_Bulk_Create_17')}</strong>
          </S.InfoNameWrapper>
          {isEditMode.userGroupCode ? (
            <S.InfoValue>{userGroup?.userGroupCode}</S.InfoValue>
          ) : (
            <S.GroupTextField
              {...userGroupRegister}
              fullWidth
              autoComplete="off"
              size="small"
              inputRef={(ref: HTMLInputElement) => {
                if (ref) {
                  userGroupRegister.ref(ref);
                  ref.setSelectionRange(0, ref.value.length);
                }
              }}
              onClick={e => e.stopPropagation()}
              onKeyUp={handleEnterKey}
              error={!!errors.userGroupCode?.message}
              className={isEditMode.userGroupCode ? 'read-mode' : 'edit-mode'}
              data-key="code"
              autoFocus
            />
          )}
          {isEditMode.userGroupCode && (
            <S.StyledButton
              size="small"
              variant="outline"
              onClick={() => {
                setIsEditMode(prev => ({ ...prev, userGroupCode: false }));
              }}
              paddingHorizontal={12}
            >
              {t('Ad_Cart_09')}
            </S.StyledButton>
          )}
        </S.InfoWrapper>
        <S.InfoWrapper>
          <S.InfoNameWrapper>
            <GroupUpperIcon width={16} height={16} />
            <strong>{t('Member_Group_Create_04')}</strong>
          </S.InfoNameWrapper>
          <S.InfoValue>
            {!userGroup?.parentUserGroup.userGroupId ? userGroup?.tenant.name : userGroup?.parentUserGroup.name || '-'}
          </S.InfoValue>

          <S.StyledButton
            size="small"
            type="button"
            variant="outline"
            onClick={() => setIsOpenModal(true)}
            paddingHorizontal={12}
          >
            {t('Ad_Cart_09')}
          </S.StyledButton>
        </S.InfoWrapper>
        <S.InfoWrapper>
          <S.InfoNameWrapper>
            <PersonIcon width={16} height={16} color={theme.colors['ic-gray-dark']} />
            <strong>{t('Member_Group_14')}</strong>
          </S.InfoNameWrapper>
          {isSuccess ? <S.InfoValue>{pagedMembers?.totalElements}</S.InfoValue> : <Loader />}
        </S.InfoWrapper>
        <S.InfoWrapper>
          <S.InfoNameWrapper>
            <SubscriptionIcon width={16} height={16} color={theme.colors['ic-gray-dark']} />
            <strong>{t('Member_Group_05')}</strong>
          </S.InfoNameWrapper>
          <S.InfoValue>{userGroup?.subscriptions.length}</S.InfoValue>
        </S.InfoWrapper>
        <S.InfoWrapper>
          <S.InfoNameWrapper>
            <CalendarStartIcon width={16} height={16} color={theme.colors['ic-gray-dark']} />
            <strong>{t('Member_Group_15')}</strong>
          </S.InfoNameWrapper>
          <S.InfoValue>{userGroup?.createdDate.format(t('DateFormat_YMD'))}</S.InfoValue>
        </S.InfoWrapper>
        <S.InfoWrapper>
          <S.InfoNameWrapper>
            <CalendarEndIcon width={16} height={16} color={theme.colors['ic-gray-dark']} />
            <strong>{t('Member_Group_16')}</strong>
          </S.InfoNameWrapper>
          <S.InfoValue>{userGroup?.updatedDate.format(t('DateFormat_YMD'))}</S.InfoValue>
        </S.InfoWrapper>
        <S.InfoWrapper>
          <S.InfoNameWrapper className="align-top">
            <PencilLineIcon width={16} height={16} color={theme.colors['ic-gray-dark']} />
            <strong>{t('Member_Group_Create_05')}</strong>
          </S.InfoNameWrapper>
          <S.TextAreaWrapper>
            <S.StyledTextField
              {...register('description')}
              autoComplete="off"
              size="small"
              multiline
              placeholder={t('Member_Group_Detail_InformationTab')}
              className="description edit-mode"
              rows={9}
              InputProps={{
                readOnly: !isEditMode.description,
              }}
              onClick={handleTextFieldClick}
              onBlur={handleTextFieldBlur}
            />
          </S.TextAreaWrapper>
        </S.InfoWrapper>
      </S.Container>
      <ParentGroupEidt isOpen={isOpenModal} onClose={() => setIsOpenModal(false)} />
    </>
  );
};
