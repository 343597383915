import React, { ForwardedRef, type HTMLAttributes, forwardRef, useEffect, useRef, useState, useCallback } from 'react';
import { createPortal } from 'react-dom';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { debounce } from 'lodash';
import { fonts } from '@theme/fontsCustomer';
import { TriangleIcon } from '@icons/TriangleIcon';
import { DropdownContextProvider } from './DropdownContext';
import { DropdownMenu } from './DropdownMenu';

export type DropdownProps = {
  variant?: 'default' | 'no-line';
  value?: string | number;
  onChange?: (value: string | number) => void;
  width?: string;
  disabled?: boolean;
  size?: 'small' | 'large' | 'extraLarge';
  spaceBetweenMenu?: number;
} & Omit<HTMLAttributes<HTMLButtonElement>, 'onChange'>;

const DropdownBase = forwardRef(
  (
    {
      value,
      onChange,
      variant = 'default',
      width,
      children,
      disabled,
      size = 'small',
      spaceBetweenMenu = -1,
      placeholder,
      ...props
    }: StrictPropsWithChildren<DropdownProps>,
    ref: ForwardedRef<HTMLButtonElement>,
  ) => {
    const { colors } = useTheme();
    const [menuListState, setMenuListState] = useState<{ isOpen: boolean; isMount: boolean }>({
      isOpen: false,
      isMount: false,
    });
    const [menuListBoundingRect, setMenuListTopLeft] = useState<{ width: number; top: number; left: number } | null>();
    const selectRef = useRef<HTMLButtonElement | null>(null);

    const [label, setLabel] = useState<React.ReactNode>();

    useEffect(() => {
      const childArray = React.Children.toArray(children);
      childArray.forEach(child => {
        if (React.isValidElement(child) && child.props.value === value) {
          setLabel(child.props.children);
        }
      });

      for (let i = 0; i < childArray.length; i += 1) {
        const child = childArray[i];
        if (React.isValidElement(child) && child.props.value === value) {
          setLabel(child.props.children);
          break;
        }

        if (i === childArray.length - 1) {
          setLabel(undefined);
        }
      }
    }, [children, value]);

    const handleMenuChange = (label: React.ReactNode, value: string | number) => {
      if (typeof onChange === 'function') {
        setLabel(label);
        onChange(value);
      }
    };

    const handleSelectClick = (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      e.preventDefault();
      setMenuListState({ isOpen: true, isMount: true });
    };

    const handleBackgroundClick = (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      setMenuListState(prev => ({ ...prev, isOpen: false }));
      setTimeout(() => {
        setMenuListState(prev => ({ ...prev, isMount: false }));
      }, 100);
    };

    const updateOptionListPosition = useCallback(() => {
      const rect = selectRef.current?.getBoundingClientRect();
      if (rect) {
        setMenuListTopLeft({ width: rect.width, top: rect.bottom + spaceBetweenMenu, left: rect.left });
      }
    }, [spaceBetweenMenu]);

    useEffect(() => {
      if (menuListState.isMount) {
        updateOptionListPosition();
      }
    }, [menuListState.isMount, updateOptionListPosition]);

    useEffect(() => {
      const handleResize = debounce(updateOptionListPosition, 50);

      const containerWindow = selectRef.current?.ownerDocument.defaultView ?? window;
      if (containerWindow) {
        containerWindow.addEventListener('resize', handleResize);
      }

      return () => {
        if (containerWindow) {
          containerWindow.removeEventListener('resize', handleResize);
        }
      };
    }, [updateOptionListPosition]);

    return (
      <>
        <Select
          {...props}
          ref={btnRef => {
            if (btnRef) {
              selectRef.current = btnRef;
              if (typeof ref === 'function') {
                ref(btnRef);
              } else if (ref?.current) {
                // eslint-disable-next-line no-param-reassign
                ref.current = btnRef;
              }
            }
          }}
          onClick={disabled ? undefined : handleSelectClick}
          $width={width}
          $variant={menuListState.isOpen ? 'default' : variant}
          disabled={disabled}
          $size={size}
          type="button"
        >
          {label ?? <span>{placeholder}</span>}
          <TriangleIcon
            width={16}
            height={16}
            rotateNum={180}
            color={disabled ? colors['ic-gray-light'] : colors['ic-gray-dark']}
            style={{ marginLeft: 'auto' }}
          />
        </Select>
        {menuListState.isMount &&
          !disabled &&
          menuListBoundingRect &&
          createPortal(
            <MenuBackground onClick={handleBackgroundClick}>
              <DropdownContextProvider value={{ value, onChange, width, onMenuChange: handleMenuChange }}>
                <MenuList className={menuListState.isOpen ? 'zoom-in' : 'zoom-out'} style={{ ...menuListBoundingRect }}>
                  {children}
                </MenuList>
              </DropdownContextProvider>
            </MenuBackground>,
            document.body,
          )}
      </>
    );
  },
);

export const Dropdown = Object.assign(DropdownBase, {
  Menu: DropdownMenu,
});

const Select = styled.button<{
  $width: DropdownProps['width'];
  $variant: DropdownProps['variant'];
  $size: DropdownProps['size'];
}>`
  all: unset;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ $variant, theme: { colors } }) =>
    $variant === 'default' ? colors['bg-white'] : 'transparent'};
  border: 1px solid
    ${({ $variant, theme: { colors } }) => ($variant === 'no-line' ? 'transparent' : colors['border-gray-light'])};
  border-radius: 2px;
  width: ${({ $width }) => $width || 'auto'};
  box-sizing: border-box;
  padding-top: ${({ $size }) => ($size === 'small' ? '5px' : $size === 'large' ? '9px' : '13px')};
  padding-bottom: ${({ $size }) => ($size === 'small' ? '5px' : $size === 'large' ? '9px' : '13px')};
  padding-left: 8px;
  padding-right: 12px;
  ${fonts.Button4}
  cursor: pointer;

  &:hover {
    background-color: ${({ theme: { colors } }) => colors['state-white-hover']};
  }

  &:disabled {
    cursor: auto;
    background-color: ${({ theme: { colors } }) => colors['bg-gray-dark']};
    color: ${({ theme: { colors } }) => colors['text-gray-light']};
  }
`;

const MenuBackground = styled.div`
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const MenuList = styled.ul`
  list-style-type: none;
  margin: 0;
  position: absolute;
  border: 1px solid ${({ theme: { colors } }) => colors['border-gray-light']};
  background-color: ${({ theme: { colors } }) => colors['bg-white']};
  border-radius: 2px;
  padding: 4px 5px;
  box-sizing: border-box;

  @keyframes zoom-in-animation {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  &.zoom-in {
    animation: zoom-in-animation 100ms ease;
    transform-origin: center top;
  }

  @keyframes zoom-out-animation {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  &.zoom-out {
    transform: scale(0);
    animation: zoom-out-animation 100ms ease;
    transform-origin: center top;
  }
`;
