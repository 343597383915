import type { SelectChangeEvent } from '@mui/material';
import type { ChangeEvent } from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { MenuItem, Select, TextField } from '@mui/material';
import { observer } from 'mobx-react';
import { TimezoneAutocomplete } from '@pages/customer/common';
import { useStore } from '@stores/RootStore';
import { fonts } from '@theme/fontsCustomer';
import { ClockIcon } from '@icons/ClockIcon';
import { GlobeIcon } from '@icons/GlobeIcon';
import ToolsIcon from '@icons/ToolsIcon';
import { TriangleIcon } from '@icons/TriangleIcon';

type Cron = {
  display: string;
  expression: string;
};

type ScheduleType = 'everyday' | 'userconfig';

type Schedule = {
  type: ScheduleType;
  detail: string | null;
  timezone: string | null;
};

const crons: Cron[] = [
  { display: '00:00', expression: '0 0 0 * * ?' },
  { display: '01:00', expression: '0 0 1 * * ?' },
  { display: '02:00', expression: '0 0 2 * * ?' },
  { display: '03:00', expression: '0 0 3 * * ?' },
  { display: '04:00', expression: '0 0 4 * * ?' },
  { display: '05:00', expression: '0 0 5 * * ?' },
  { display: '06:00', expression: '0 0 6 * * ?' },
  { display: '07:00', expression: '0 0 7 * * ?' },
  { display: '08:00', expression: '0 0 8 * * ?' },
  { display: '09:00', expression: '0 0 9 * * ?' },
  { display: '10:00', expression: '0 0 10 * * ?' },
  { display: '11:00', expression: '0 0 11 * * ?' },
  { display: '12:00', expression: '0 0 12 * * ?' },
  { display: '13:00', expression: '0 0 13 * * ?' },
  { display: '14:00', expression: '0 0 14 * * ?' },
  { display: '15:00', expression: '0 0 15 * * ?' },
  { display: '16:00', expression: '0 0 16 * * ?' },
  { display: '17:00', expression: '0 0 17 * * ?' },
  { display: '18:00', expression: '0 0 18 * * ?' },
  { display: '19:00', expression: '0 0 19 * * ?' },
  { display: '20:00', expression: '0 0 20 * * ?' },
  { display: '21:00', expression: '0 0 21 * * ?' },
  { display: '22:00', expression: '0 0 22 * * ?' },
  { display: '23:00', expression: '0 0 23 * * ?' },
];

export const SchedulerConfig = observer(() => {
  const { t } = useTranslation();
  const { workflowDetailStore } = useStore();
  const isEditable = workflowDetailStore.checkEditable();
  const triggerModel = workflowDetailStore.triggerNode?.data.triggerModel;
  const [schedule, setSchedule] = useState<Schedule>({
    type: crons.find(c => c.expression === triggerModel?.cronExpression) ? 'everyday' : 'userconfig',
    detail: triggerModel?.cronExpression ?? '0 0 0 * * ?',
    timezone: triggerModel?.cronTimeZone ?? 'UTC',
  });

  useEffect(() => {
    if (!isEditable || !triggerModel) return;

    triggerModel.cronTimeZone = schedule.timezone ?? 'UTC';
    triggerModel.cronExpression = schedule.detail ?? '0 0 0 * * ?';
  }, [isEditable, schedule, triggerModel]);

  const handleChangeTimezoneAutocomplete = (value: string | null) => {
    if (!isEditable || !triggerModel) return;

    setSchedule(prev => {
      return { ...prev, timezone: value };
    });
  };

  const handleChangeScheduleType = (e: SelectChangeEvent<'everyday' | 'userconfig'>) => {
    if (!isEditable || !triggerModel) return;

    setSchedule(prev => {
      return { ...prev, type: e.target.value as ScheduleType };
    });
  };

  const handleChangeCronTime = (e: SelectChangeEvent) => {
    if (!isEditable || !triggerModel) return;

    setSchedule(prev => {
      return { ...prev, detail: e.target.value };
    });
  };

  const handleChangeCustom = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (!isEditable || !triggerModel) return;

    setSchedule(prev => {
      return { ...prev, detail: e.target.value };
    });
  };

  return (
    <ScheduleContent>
      <ScheduleWrapper>
        <Title>
          <GlobeIcon width={16} height={16} />
          <span>{t('Workflow_Create_42')}</span>
        </Title>
        <Config>
          <TimezoneAutocomplete
            value={schedule.timezone}
            onChange={handleChangeTimezoneAutocomplete}
            disabled={!isEditable}
          />
        </Config>
      </ScheduleWrapper>
      <ScheduleWrapper>
        <Title>
          <ClockIcon width={16} height={16} />
          <span>{t('Workflow_Create_43')}</span>
        </Title>
        <Config>
          <Select
            fullWidth
            value={schedule.type}
            onChange={handleChangeScheduleType}
            disabled={!isEditable}
            IconComponent={Arrow}
            sx={{
              height: '40px',
              '& .MuiSelect-select': {
                height: '20px',
                padding: '10px 12px',
                fontSize: '14px',
                lineHeight: '20px',
                letterSpacing: '0.15px',
              },
            }}
            MenuProps={{
              PaperProps: {
                sx: {
                  maxWidth: '647px',
                  width: '100%',
                  marginTop: '-3px',
                },
              },
            }}
          >
            <MenuItem value="everyday">{t('Workflow_Create_46')}</MenuItem>
            <MenuItem value="userconfig">{t('Workflow_Create_47')}</MenuItem>
          </Select>
        </Config>
      </ScheduleWrapper>
      <ScheduleWrapper>
        <Title>
          <ToolsIcon width={16} height={16} />
          <span>{t('Workflow_Create_44')}</span>
        </Title>
        <Config>
          {schedule.type === 'everyday' && (
            <Select
              fullWidth
              value={schedule.detail as string}
              displayEmpty
              onChange={handleChangeCronTime}
              disabled={!isEditable}
              sx={{
                height: '40px',
                '& .MuiSelect-select': {
                  height: '20px',
                  padding: '10px 12px',
                  fontSize: '14px',
                  lineHeight: '20px',
                  letterSpacing: '0.15px',
                },
              }}
              MenuProps={{
                sx: {
                  '& .MuiPaper-root': {
                    minWidth: 'calc(100% - 0px)',
                    maxWidth: 'calc(100% - 0px)',
                    marginTop: '-3px',
                  },
                  '& .MuiList-root': {
                    width: '100%',
                    maxHeight: '328px',
                  },
                },
              }}
              IconComponent={Arrow}
              renderValue={value => crons.find(v => value === v.expression)?.display ?? t('Workflow_Create_45')}
            >
              {crons.map(cron => {
                return (
                  <MenuItem key={cron.display} value={cron.expression}>
                    {cron.display}
                  </MenuItem>
                );
              })}
            </Select>
          )}
          {schedule.type === 'userconfig' && (
            <TextField
              fullWidth
              placeholder="Cron 표현식"
              size="medium"
              value={schedule.detail}
              onChange={handleChangeCustom}
              disabled={!isEditable}
            />
          )}
        </Config>
      </ScheduleWrapper>
    </ScheduleContent>
  );
});

const Arrow = (props: JSX.Element) => {
  return (
    <TriangleIcon
      rotateNum={180}
      width={16}
      height={16}
      className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSelect-icon MuiSelect-iconOutlined"
      {...props}
    />
  );
};

const ScheduleContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 20px 24px;
  margin-bottom: 16px;
  background-color: ${props => props.theme.colors['bg-gray-lighter']};
`;

const ScheduleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const Title = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  ${fonts.Headline8};
  align-items: center;
`;

const Config = styled.div`
  width: 100%;
`;
