import type { ChangeEvent } from 'react';
import type React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { Avatar, Menu, MenuItem, Switch, TableCell, TableRow, TextField, useTheme } from '@mui/material';
import { useIsMutating } from '@tanstack/react-query';
import { Button, Label } from '@components/index';
import { DateText } from '@components/text/DateText';
import { Tooltip } from '@components/tooltip/Tooltip';
import { fonts } from '@theme/fontsCustomer';
import { CheckIcon } from '@icons/CheckIcon';
import { ThreeDotIcon } from '@icons/ThreeDotIcon';
import { XIcon } from '@icons/XIcon';
import type { WorkflowActionCreateDTO } from '@repositories/workflowRepository/Types';
import {
  useActiveWorkflow,
  useDeactiveWorkflow,
  useDeleteWorkflow,
  useGetWorkflow,
  useUpdateWorkflow,
} from '@queryHooks/useWorkflow';
import { dateFormat, dateTimeFormat } from '@utils/dateUtil';
import type { WorkflowModel } from '@models/workflow/WorkflowModel';

interface Props {
  data: WorkflowModel;
  idx: number;
}

export const WorkflowRow = ({ data, idx }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);
  const menuOpen = Boolean(menuAnchor);
  const [name, setName] = useState<string>(data.name);
  const [status, setStatus] = useState<boolean>(data.status === 'ACTIVE');
  const [isNameEdit, setIsNameEdit] = useState<boolean>(false);

  const handleClickRow = () => {
    navigate(`${data.id}`);
  };

  const handleClickThreedot = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setMenuAnchor(e.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  const { mutate: deleteWorkflow } = useDeleteWorkflow(data.id);
  const { data: workflowDetail } = useGetWorkflow(data.id, {
    enabled: menuOpen,
  });

  const handleClickWorkflowDeleteMenu = () => {
    deleteWorkflow();
    handleMenuClose();
  };

  const handleClickWorkflowNameChangeMenu = () => {
    setIsNameEdit(true);
    handleMenuClose();
  };

  const isMutating = useIsMutating();

  const { mutate: updateWorkflowName } = useUpdateWorkflow(data.id, name);
  const { mutate: activeWorkflow } = useActiveWorkflow(data.id);
  const { mutate: deactiveWorkflow } = useDeactiveWorkflow(data.id);

  const handleChangeStatus = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      activeWorkflow(undefined, {
        onSuccess: () => {
          setStatus(!e.target.checked);
        },
      });
    } else {
      deactiveWorkflow(undefined, {
        onSuccess: () => {
          setStatus(!e.target.checked);
        },
      });
    }
  };

  const menuStyles = {
    elevation: 0,
    sx: {
      '& .MuiMenu-list': {
        borderRadius: '4px',
      },
    },
  };

  const saveName = () => {
    if (!isMutating) {
      updateWorkflowName(
        {
          actions: workflowDetail?.actions.map(action => {
            return {
              actionMethod: action.actionMethod,
              connectionId: action.connection?.connectionId,
              actionArguments: action.actionArguments?.map(argument => ({
                argumentName: argument.argumentName,
                expression: argument.expression,
                expressionType: argument.expressionType,
              })),
              actionName: action.actionName,
            };
          }) as WorkflowActionCreateDTO[],
          name,
          trigger: {
            baseTriggerId: workflowDetail?.trigger.baseTrigger.id as string,
            conditions: workflowDetail?.trigger.conditions.map(
              condition => condition.transferWorkflowTriggerConditionCreateDTO,
            ),
            cronExpression: workflowDetail?.trigger.cronExpression,
            cronTimeZone: workflowDetail?.trigger.cronTimeZone,
            customConditionExpression: workflowDetail?.trigger.customConditionExpression,
          },
          type: 'CUSTOM',
          description: workflowDetail?.description,
          id: workflowDetail?.id,
          tenantId: workflowDetail?.tenant.id,
        },
        {
          onSuccess: () => {
            setIsNameEdit(false);
          },
        },
      );
    }
  };

  const titleHandlers = {
    clickEditBtn: () => {
      setIsNameEdit(true);
    },
    handleChangeTextField: (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.value.length < 51) setName(e.target.value);
    },
    keyUpTextfield: (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        if (name.length > 0) saveName();
        else {
          setName(data.name);
          setIsNameEdit(false);
        }
      }
      if (e.key === 'Escape') {
        setName(data.name);
        setIsNameEdit(false);
      }
    },
  };

  const getLabel = () => {
    switch (data.errorCode) {
      case 'CONNECTION.ERROR.API_ACCESS_DENIED':
        return '커넥션에 문제가 발생했습니다. 커넥션 상태를 확인해주세요.';
      case 'CONNECTION.ERROR.CONNECT_API_FAILED':
        return '외부 서버에 문제가 발생했습니다. 잠시 후 다시 확인해주세요.';
      case 'CONNECTION.ERROR.INVALID_CONNECTION_INFO':
        return '액션 데이터 처리에 문제가 발생했습니다. 커스텀으로 입력한 데이터 필드를 확인해주세요.';
      default:
        return '문제가 발생했습니다.';
    }
  };

  return (
    <>
      <TableRow onClick={handleClickRow}>
        <TableCell align="center">{idx + 1}</TableCell>
        <TableCell>
          <NameCell edit={isNameEdit}>
            {isNameEdit ? (
              <EidtNameWrapper onClick={e => e.stopPropagation()}>
                <EditTextField
                  fullWidth
                  value={name}
                  size="small"
                  onChange={titleHandlers.handleChangeTextField}
                  onKeyUp={titleHandlers.keyUpTextfield}
                  InputProps={{
                    endAdornment: (
                      <>
                        <CurrentCheckSpan>{name.length}</CurrentCheckSpan>
                        <LimitCheckSpan>/</LimitCheckSpan>
                        <LimitCheckSpan>50</LimitCheckSpan>
                        <CheckIconButton
                          variant="outline"
                          onClick={() => {
                            saveName();
                          }}
                        >
                          <CheckIcon width={14} height={14} color={theme.colors['ic-green-light']} />
                        </CheckIconButton>
                        <XIconButton
                          variant="outline"
                          onClick={() => {
                            setName(data.name);
                            setIsNameEdit(false);
                          }}
                        >
                          <XIcon width={14} height={14} />
                        </XIconButton>
                      </>
                    ),
                  }}
                />
              </EidtNameWrapper>
            ) : (
              <span className="workflow-name">{data.name}</span>
            )}
            <div className="trigger">
              {data.trigger.baseTrigger.name}
              <span>
                {data.actions?.map(action => {
                  return <Avatar key={action.id} src={action.connection?.subscription.product.iconUrl} />;
                })}
              </span>
            </div>
          </NameCell>
        </TableCell>
        <TableCell>{data.creator.name}</TableCell>
        <TableCell onClick={e => e.stopPropagation()}>
          <Switch checked={status} onChange={handleChangeStatus} />
        </TableCell>
        <TableCell>{dateFormat(data.latestExecutionTime)}</TableCell>
        <TableCell>
          <Tooltip
            label={data.isLatestExecutionSuccess ? '' : getLabel()}
            disableMouseEnterListener={data.isLatestExecutionSuccess}
            placement="bottom-right"
          >
            <Label variant="solid" color={data.isLatestExecutionSuccess ? 'green' : 'red'}>
              {data.latestExecutionStatusStr}
            </Label>
          </Tooltip>
          <DateText>{dateTimeFormat(data.latestExecutionTime ?? '')}</DateText>
        </TableCell>
        <TableCell align="center" onClick={e => e.stopPropagation()}>
          <ThreeDotButton onClick={handleClickThreedot}>
            <ThreeDotIcon
              width={18}
              height={18}
              color={menuOpen ? theme.colors['ic-purple'] : theme.colors['ic-gray-dark']}
            />
          </ThreeDotButton>
        </TableCell>
      </TableRow>
      <Menu
        open={menuOpen}
        anchorEl={menuAnchor}
        onClose={handleMenuClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        PaperProps={menuStyles}
        sx={{ marginTop: '5px' }}
      >
        <MenuItem onClick={handleClickWorkflowNameChangeMenu}>{t('Workflow_Main_15')}</MenuItem>
        <MenuItem onClick={handleClickWorkflowDeleteMenu}>
          <Delete>{t('Workflow_Main_18')}</Delete>
        </MenuItem>
      </Menu>
    </>
  );
};

const XIconButton = styled(Button)`
  border: 1px solid ${({ theme: { colors } }) => colors['border-gray-lighter']};
  border-radius: 2px;
  background-color: ${({ theme: { colors } }) => colors['bg-white']};
  margin: 0px 0px 0px 4px;
  padding: 4px;
`;

const CurrentCheckSpan = styled.span`
  color: ${({ theme: { colors } }) => colors['text-gray-main']};
  text-align: right;

  ${fonts.Body4}
`;

const LimitCheckSpan = styled.span`
  color: ${({ theme: { colors } }) => colors['text-gray-light']};
  margin: 0px 0px 0px 2px;
  ${fonts.Body4}
`;

const CheckIconButton = styled(Button)`
  border-radius: 2px;
  border: 1px solid ${({ theme: { colors } }) => colors['border-gray-lighter']};
  background-color: ${({ theme: { colors } }) => colors['bg-white']};
  margin-left: 10px;
  padding: 4px;
`;

const NameCell = styled.div<{ edit: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding: 15px 0 12px;
  & .workflow-name {
    ${fonts.Body2};
    color: ${({ theme: { colors } }) => colors['text-gray-main']};
  }
  & .trigger {
    height: 24px;
    display: flex;
    align-items: center;

    ${fonts.Body4};
    color: ${({ theme: { colors } }) => colors['text-gray-sub-dark']};
    margin-top: ${props => (props.edit ? '20px' : '0px')};
    opacity: ${props => (props.edit ? '0.3' : '1')};
  }
`;

const Delete = styled.span`
  ${fonts.Body2};
  color: ${props => props.theme.colors['text-red-light']};
`;

const EidtNameWrapper = styled.div`
  width: 100%;
  position: absolute;
  top: 9px;
  left: -11px;
  z-index: 5;
`;

const EditTextField = styled(TextField)`
  width: 100%;
  & .MuiInputBase-input::placeholder {
    color: ${({ theme }) => theme.colors['text-gray-main']};
  }
`;

const ThreeDotButton = styled(Button)`
  width: 100%;
  height: 42px;
  cursor: pointer;
`;
