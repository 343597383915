import type {
  ModuleUsagePrices,
  PlanVisibilityScope,
  Software,
  SoftwareModule,
  SoftwarePreview,
} from '@repositories/softwareRepository';
import { ProductRelationDetailModel } from './ProductRelationDetailModel';
import { SoftwareCategoryModel } from './SoftwareCategoryModel';
import { SoftwareSimpleModel } from './SoftwareSimpleModel';

export class SoftwareModel {
  private _id: string;

  private _productCode: string;

  private _name: string;

  private _description: string;

  private _url: string | undefined;

  private _companyName: string | undefined;

  private _iconUrl: string | undefined;

  private _thumbnailUrl: string | undefined;

  private _modules: SoftwareModule[];

  private _categories: SoftwareCategoryModel[];

  private _moduleUsagePrices: ModuleUsagePrices;

  private _currencyUnit: CurrencyUnit;

  private _overview: string;

  private _features: string;

  private _advantages: string;

  private _previews: SoftwarePreview[];

  private _hashtags: string[];

  private _isSubscribing: boolean;

  private _relatedProducts: ProductRelationDetailModel[];

  private _providerId: string | null;

  private _planVisibilityScope: PlanVisibilityScope;

  constructor(dto?: Software) {
    this._id = dto?.id || '';
    this._productCode = dto?.productCode || '';
    this._name = dto?.name || '';
    this._description = dto?.description || '';
    this._url = dto?.url || '';
    this._companyName = dto?.companyName || '';
    this._iconUrl = dto?.iconUrl || '';
    this._thumbnailUrl = dto?.thumbnailUrl;
    this._modules = dto?.modules || [];
    this._categories = dto?.categories ? dto?.categories.map(cat => new SoftwareCategoryModel(cat)) : [];
    this._moduleUsagePrices = dto?.moduleUsagePrices || {};
    this._currencyUnit = dto?.currencyUnit || 'KRW';
    this._overview = dto?.overview || '';
    this._features = dto?.features || '';
    this._advantages = dto?.advantages || '';
    this._previews = dto?.softwarePreviews || [];
    this._hashtags = dto?.hashtags || [];
    this._isSubscribing = dto?.isSubscribing ?? false;
    this._relatedProducts = dto?.relatedProducts
      ? dto.relatedProducts.map(el => new ProductRelationDetailModel(el))
      : [];
    this._providerId = dto?.providerId || null;
    this._planVisibilityScope = dto?.planVisibilityScope || 'ALL_USERS';
  }

  public get providerId(): string | null {
    return this._providerId;
  }
  public get id(): string {
    return this._id;
  }

  public get productCode(): string {
    return this._productCode;
  }

  public get name(): string {
    return this._name;
  }

  public get description(): string | undefined {
    return this._description;
  }

  public get url(): string | undefined {
    return this._url;
  }

  public get companyName(): string | undefined {
    return this._companyName;
  }

  public get iconUrl(): string | undefined {
    return this._iconUrl;
  }

  public get thumbnailUrl(): string | undefined {
    return this._thumbnailUrl;
  }

  /**
   * @deprecated 모듈 목록은 ProductPlanModel의 usageMetricList를 사용해야 함
   */
  public get modules(): SoftwareModule[] {
    return this._modules;
  }

  public get categories(): SoftwareCategoryModel[] {
    return this._categories;
  }

  public get categoryNames(): string {
    return this.categories?.map(({ name }) => name).join(', ') ?? '';
  }

  public get moduleUsagePrices() {
    return this._moduleUsagePrices;
  }

  public get currencyUnit(): CurrencyUnit {
    return this._currencyUnit;
  }

  public get overview(): string {
    return this._overview;
  }

  public get features(): string {
    return this._features;
  }

  public get advantages(): string {
    return this._advantages;
  }

  public get previews(): SoftwarePreview[] {
    return this._previews;
  }

  public get hashtags(): string[] {
    return this._hashtags;
  }

  public get isSubscribing(): boolean {
    return this._isSubscribing;
  }

  public get planVisibilityScope(): PlanVisibilityScope {
    return this._planVisibilityScope;
  }

  public get relatedProducts(): ProductRelationDetailModel[] {
    return this._relatedProducts;
  }

  public get simpleModel(): SoftwareSimpleModel {
    return new SoftwareSimpleModel({
      id: this._id,
      productCode: this._productCode,
      name: this._name,
      description: this._description,
      url: this._url,
      companyName: this._companyName,
      iconUrl: this._iconUrl,
      thumbnailUrl: this._thumbnailUrl,
      category: this._categories[0],
      categoryCount: this._categories.length,
      isSubscribing: this._isSubscribing,
      planVisibilityScope: this._planVisibilityScope,
    });
  }
}
