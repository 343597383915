import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { useTheme } from '@mui/material';
import { LNB } from '@pages/customer/common/lnb';
import { DepthIcon } from '@icons/DepthIcon';
import { WorkflowIcon } from '@icons/WorkflowIcon';
import { withLogin } from '@hoc/withLogin';
import * as S from './WorkflowStyles';

export const Workflow = withLogin(() => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <S.Container>
      <LNB
        menuItem={[
          {
            name: t('Ad_GNB_Menu_02'),
            icon: <WorkflowIcon width={20} height={20} color={theme.colors['ic-gray-main']} />,
            subMenu: [
              {
                name: t('Workflow_LNB_01'),
                icon: <DepthIcon width={16} height={16} color={theme.colors['ic-gray-main']} />,
                selectedIcon: <DepthIcon width={16} height={16} color={theme.colors['ic-purple-light']} />,
                to: 'management',
              },
            ],
          },
        ]}
      />
      <Outlet />
    </S.Container>
  );
});
