import type {
  LogicalOperator,
  WorkflowTriggerConditionCreateDTO,
  WorkflowTriggerConditionDTO,
} from '@repositories/workflowRepository/Types';

export class WorkflowTriggerConditionDTOModel {
  private _fieldName: string;
  private _id: string;
  private _logicalOperator: LogicalOperator | undefined;
  private _operator: 'EQ';
  private _value: string;

  constructor(dto: WorkflowTriggerConditionDTO) {
    this._fieldName = dto.fieldName || '';
    this._id = dto.id || '';
    this._logicalOperator = dto.logicalOperator || undefined;
    this._operator = dto.operator || 'EQ';
    this._value = dto.value || '';
  }

  get fieldName() {
    return this._fieldName;
  }

  set fieldName(value) {
    this._fieldName = value;
  }

  get id() {
    return this._id;
  }

  get logicalOperator() {
    return this._logicalOperator;
  }

  get operator() {
    return this._operator;
  }

  get value() {
    return this._value;
  }

  get transferWorkflowTriggerConditionCreateDTO(): WorkflowTriggerConditionCreateDTO {
    return {
      fieldName: this._fieldName,
      logicalOperator: this._logicalOperator || undefined,
      operator: this._operator,
      value: this._value,
    };
  }

  setFieldName(name: string) {
    this._fieldName = name;
  }

  setLogicalOperator(logicalOp: LogicalOperator) {
    this._logicalOperator = logicalOp;
  }

  setValue(value: string) {
    this._value = value;
  }
}
