import type { MonthlyBillingStatDTO } from '@repositories/subscriptionBillingStatRepository/Types';

export class MonthlyBillingStatModel {
  private dto: MonthlyBillingStatDTO;

  constructor(dto: MonthlyBillingStatDTO) {
    this.dto = dto;
  }

  get month() {
    return this.dto.month;
  }

  get monthlyPayment() {
    return this.dto.monthlyPayment;
  }

  get monthlyPaymentEstimate() {
    return this.dto.monthlyPaymentEstimate;
  }

  get monthlyPaymentEstimateGrowthRate() {
    return this.dto.monthlyPaymentEstimateGrowthRate;
  }

  get subscriptionCount() {
    return this.dto.subscriptionCount ?? 0;
  }

  get totalUserCount() {
    return this.dto.subscriptionUserStat?.totalUserCount ?? 0;
  }

  get abnormalActiveMemberCount() {
    return this.dto.subscriptionUserStat?.abnormalActiveMemberCount ?? 0;
  }

  get abnormalBlockedMemberCount() {
    return this.dto.subscriptionUserStat?.abnormalBlockedMemberCount ?? 0;
  }

  get billableUserCount() {
    return this.dto.subscriptionUserStat?.billableUserCount ?? 0;
  }

  get billableMemberCount() {
    return this.dto.subscriptionUserStat?.billableMemberCount ?? 0;
  }

  get billableNonMemberCount() {
    return this.dto.subscriptionUserStat?.billableNonMemberCount ?? 0;
  }
}
