import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery, useTheme } from '@mui/material';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import enSpotSlide01 from '@images/en_spotSlide01.png';
import enSpotSlide02 from '@images/en_spotSlide02.png';
import logo from '@images/service_logo.png';
import { ArrowIcon } from '@icons/index';
import * as S from './IntroductionEnStyles';
import 'swiper/css';
import 'swiper/css/pagination';

export const IntroductionEn = () => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const theme = useTheme();
  const navigate = useNavigate();
  const matches = useMediaQuery(theme.breakpoints.down(1240));
  const handleIntroduceInquiryClick = () => {
    navigate('/tenant-request');
  };

  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const swiperSetting = {
    centeredSlides: true,
  };

  return (
    <S.Content>
      <div className="spot">
        <div className="inner">
          <h2>
            Enterprise
            <br className="mobile" /> Subsciption Service, <br className="mobile" /> <span>SPLITTER.</span>
          </h2>
          <p>
            Simplify your digital asset management with Splitter. <br className="pc" />
            <br className="mobile" />
            Enjoy the full benefit one-stop SaaS solution
          </p>
          <button type="button" onClick={handleIntroduceInquiryClick}>
            Get Started Now
            <ArrowIcon rotateNum={45} width={matches ? 20 : 24} height={matches ? 20 : 24} color={colors['ic-white']} />
          </button>
          <S.SpotCarousel {...settings}>
            <figure>
              <img src={enSpotSlide01} alt="구독현황 화면" width="100%" />
            </figure>
            <figure>
              <img src={enSpotSlide02} alt="스토어 화면" width="100%" />
            </figure>
          </S.SpotCarousel>
        </div>
      </div>
      <div className="section01">
        <div className="inner">
          <h3>Main Services</h3>
          <div className="service_info01">
            <strong className="info_title">
              <span>01</span>
              Digital Asset
              <br className="mobile" /> Total Management
            </strong>
            <S.SectionCarousel>
              <Swiper
                modules={[Pagination]}
                spaceBetween={matches ? 10 : 0}
                slidesPerView={1}
                pagination={{ clickable: true }}
                {...swiperSetting}
              >
                <SwiperSlide className="card01">
                  <strong>
                    Integrated Digital Asset
                    <br className="mobile" /> Management{' '}
                  </strong>
                  <p>
                    We manage digital assets using a centralized management tool. We
                    <br className="mobile" /> send governing messages and
                    <br className="mobile" /> regularly review the portfolio of <br className="mobile" />
                    subscribed products. We help identify duplicate or unused subscriptions to assist in cost savings.
                  </p>
                </SwiperSlide>
                <SwiperSlide className="card02">
                  <strong>
                    External(Legacy)
                    <br className="mobile" /> IT Asset Integration
                  </strong>
                  <p>
                    We enable enterprises to directly register and manage their existing digital assets. You can connect
                    the required APIs and collect and
                    <br className="mobile" /> monitor the data you need, including
                    <br className="mobile" /> usage and expenditure costs.
                  </p>
                </SwiperSlide>
                <SwiperSlide className="card03">
                  <strong>
                    Individual Permission
                    <br className="mobile" /> Management
                  </strong>
                  <p>
                    You can monitor license usage and user activation status in real-time at the member/department
                    level. You
                    <br className="mobile" /> can manage license access
                    <br className="mobile" /> permissions at the role level and
                    <br className="mobile" /> check detailed department-specific
                    <br className="mobile" /> information, including activation
                    <br className="mobile" /> status and renewal periods (active, inactive).
                  </p>
                </SwiperSlide>
                <SwiperSlide className="card04">
                  <strong>{t('Workflow_LNB_01')}</strong>
                  <p>
                    We maximize operational efficiency
                    <br className="mobile" /> by simplifying SaaS product
                    <br className="mobile" /> management processes with
                    <br className="mobile" /> workflow automation tools. Our user-
                    <br className="mobile" />
                    friendly UI automates repetitive
                    <br className="mobile" /> tasks for employee onboarding and
                    <br className="mobile" />
                    offboarding.
                  </p>
                </SwiperSlide>
                <SwiperSlide className="card05">
                  <strong>
                    Usage Optimization and
                    <br className="mobile" /> Cost Prediction
                  </strong>
                  <p>
                    You can monitor usage and
                    <br className="mobile" /> expenditure costs to gain insights
                    <br className="mobile" /> into your subscription status. With
                    <br className="mobile" />
                    usage optimization, cost savings,
                    <br className="mobile" /> and budget forecasting features,
                    <br className="mobile" />
                    you can manage financial and
                    <br className="mobile" />
                    procurement processes more
                    <br className="mobile" /> systematically.
                  </p>
                </SwiperSlide>
                <SwiperSlide className="card06">
                  <strong>
                    Financial Settlement
                    <br className="mobile" /> Management
                  </strong>
                  <p>
                    We provide monthly billing based on
                    <br className="mobile" /> your license usage, with real-time
                    <br className="mobile" /> access to billing details for the
                    <br className="mobile" /> licenses. You can set usage quotas
                    <br className="mobile" /> and thresholds for licenses on a<br className="mobile" />{' '}
                    department-by-department basis,
                    <br className="mobile" /> allowing you to track expenditure
                    <br className="mobile" /> costs.
                  </p>
                </SwiperSlide>
              </Swiper>
            </S.SectionCarousel>
          </div>
          <div className="service_info02">
            <strong className="info_title">
              <span>02</span>Open Market Place
            </strong>
            <S.SectionCarousel>
              <Swiper
                modules={[Pagination]}
                spaceBetween={matches ? 10 : 0}
                slidesPerView={1}
                pagination={{ clickable: true }}
                {...swiperSetting}
              >
                <SwiperSlide className="card0201">
                  <strong>
                    Varied Digital Asset
                    <br className="mobile" /> Marketplace
                  </strong>
                  <p>
                    You can search for and purchase
                    <br className="mobile" /> over a thousand subscription
                    <br className="mobile" /> products, not only in SaaS but also in
                    <br className="mobile" />
                    software, hardware, networking, and
                    <br className="mobile" /> more, in a diverse Digital Asset
                    <br className="mobile" /> subscription marketplace.
                  </p>
                </SwiperSlide>
                <SwiperSlide className="card0202">
                  <strong>
                    Open Subscription
                    <br className="mobile" /> Marketplace
                  </strong>
                  <p>
                    In our open subscription marketplace,
                    <br className="mobile" /> you can explore a wide range of
                    <br className="mobile" /> suppliers, from SaaS to on-premise
                    <br className="mobile" /> and hardware. Verified specialists in
                    <br className="mobile" /> each field offer customized services
                    <br className="mobile" /> for businesses.
                  </p>
                </SwiperSlide>
                <SwiperSlide className="card0203">
                  <strong>
                    From Contract to
                    <br className="mobile" /> Implementation at Once
                  </strong>
                  <p>
                    We facilitate cost-effective
                    <br className="mobile" /> subscription product procurement
                    <br className="mobile" /> with the best contract terms through
                    <br className="mobile" /> ISV agreements. Our marketplace's
                    <br className="mobile" /> price simulation feature makes
                    <br className="mobile" /> budgeting easy, allowing for
                    <br className="mobile" /> seamless product adoption without
                    <br className="mobile" /> complex requests.
                  </p>
                </SwiperSlide>
                <SwiperSlide className="card0204">
                  <strong>Employee On-Demand Store</strong>
                  <p>
                    We offer a subscription store where
                    <br className="mobile" /> companies can pick and choose the
                    <br className="mobile" /> products they need. We gather information on company size,
                    <br className="mobile" /> industry, required product
                    <br className="mobile" /> categories, and preferences to
                    <br className="mobile" /> recommend popular subscriptions in
                    <br className="mobile" /> similar industries.
                  </p>
                </SwiperSlide>
              </Swiper>
            </S.SectionCarousel>
          </div>
          <div className="service_info03">
            <strong className="info_title">
              <span>03</span> Employ
              <br className="mobile" /> Subscription Store
            </strong>
            <S.SectionCarousel>
              <Swiper
                modules={[Pagination]}
                spaceBetween={matches ? 10 : 0}
                slidesPerView={1}
                pagination={{ clickable: true }}
                {...swiperSetting}
              >
                <SwiperSlide className="card0301">
                  <strong>One-Step Subscription</strong>
                  <p>
                    Employees can complete the entire
                    <br className="mobile" /> process, from subscription
                    <br className="mobile" /> application to license issuance, in
                    <br className="mobile" /> one step through a tailored Employee
                    <br className="mobile" /> On-Demand Store. We provide pages
                    <br className="mobile" /> where you can select and apply for
                    <br className="mobile" />
                    various pricing plans for each SaaS
                    <br className="mobile" /> software.
                  </p>
                </SwiperSlide>
                <SwiperSlide className="card0302">
                  <strong>Self-Service Management</strong>
                  <p>
                    Through the technical support
                    <br className="mobile" /> services within the marketplace,
                    <br className="mobile" /> you can directly request after-sales
                    <br className="mobile" />
                    service for subscription products.
                    <br className="mobile" /> Corporate administrators can monitor
                    <br className="mobile" /> employee requests and manage self
                    <br className="mobile" />
                    -service support.
                  </p>
                </SwiperSlide>
              </Swiper>
            </S.SectionCarousel>
          </div>
        </div>
      </div>
      <div className="banner">
        <p>
          Subscription Management Platform,<strong>SPLITTER</strong>
        </p>
      </div>
      <div className="section02">
        <div className="inner">
          <h3>Features</h3>
          <div className="service_info01">
            <strong className="info_title">
              Over 1,000 <br className="mobile" />
              Subscription Products <br className="mobile" />
              for Your Business Needs!
            </strong>
            <ul className="card-wrapper">
              <li className="card01">
                <strong className="blind">Store</strong>
                <p className="blind">Let's subscribe to all the digital assets we wanted to use at the company!</p>
              </li>
              <li className="card02">
                <strong className="blind">Subscription Request</strong>
                <p className="blind">No need to wait for approval, get immediate license access upon application!</p>
              </li>
            </ul>
          </div>
          <div className="service_info02">
            <strong className="info_title">
              All the Essential
              <br className="mobile" /> Subscription Information
              <br className="mobile" /> in One Place!
            </strong>
            <ul className={matches ? 'card-wrapper' : 'card-wrapper pc'}>
              <li className="card01">
                <strong className="blind">Subscription Management</strong>
                <p className="blind">Manage scattered subscription information in one place!</p>
              </li>
              <li className="card02">
                <strong className="blind">Subscription Details Management</strong>
                <p className="blind">Check your subscription status and don't forget to renew!</p>
              </li>
              <li className="card03">
                <strong className="blind">Legacy Asset Management</strong>
                <p className="blind">Register and manage the SaaS subscriptions you already have.</p>
              </li>
            </ul>
          </div>
          <div className="service_info03">
            <strong className={matches ? 'info_title' : 'blind'}>
              Strict Management
              <br className="mobile" /> Directly Linked
              <br className="mobile" /> to Expenses!
            </strong>
            <ul className={matches ? 'card-wrapper' : 'blind'}>
              <li className="card01">
                <strong className="blind">Periodic Assessment</strong>
                <p className="blind">Check the usage of the licenses you are using by periodical inquiries.</p>
              </li>
              <li className="card02">
                <strong className="blind">Data Visualization</strong>
                <p className="blind">Get an at-a-glance view of the top 10 products with cumulative usage.</p>
              </li>
              <li className="card03">
                <strong className="blind">Subscription Details</strong>
                <p className="blind">Analyze subscriptions in detail by specific item.</p>
              </li>
              <li className="card04">
                <strong className="blind">Usage by User</strong>
                <p className="blind">Check the activation date of members and modify access permissions.</p>
              </li>
            </ul>
          </div>
          <div className="service_info04">
            <strong className={matches ? 'info_title' : 'blind'}>
              Essential to Save
              <br className="mobile" /> on Subscription Costs:
              <br className="mobile" /> Check Invoices!
            </strong>
            <ul className={matches ? 'card-wrapper' : 'blind'}>
              <li className="card01">
                <strong className="blind">Billing Management</strong>
                <p className="blind">
                  Monitor expenses and view real-time billing details through real-time usage analysis.
                </p>
              </li>
              <li className="card02">
                <strong className="blind">Budget Forecasting</strong>
                <p className="blind">Predict the next expenditure amount based on usage insights!</p>
              </li>
            </ul>
          </div>
          <div className="service_info05">
            <strong className={matches ? 'info_title' : 'blind'}>
              Let's Work Smart
              <br className="mobile" /> with Process Automation!
            </strong>
            <div className={matches ? 'card01' : 'blind'}>
              <strong className="blind">Provisioning / Deprovisioning</strong>
              <p className="blind">Create various workflows for employees with ID provisioning / deprovisioning!</p>
            </div>
          </div>
        </div>
      </div>
      <div className="banner02">
        <p>
          Manage all of Your Company's <br />
          Subscription Products in Splitter.
        </p>
        <button type="button" onClick={handleIntroduceInquiryClick}>
          Contact Us
        </button>
      </div>
      <div className="footer">
        <div className="inner">
          <figure>
            <img
              src={logo}
              alt="메가존 디지털 로고"
              width={matches ? '155px' : '123px'}
              height={matches ? '48px' : '38px'}
            />
          </figure>
          {/* <ul>
              <li>
                <span>서비스이용약관</span>
              </li>
              <li>
                <span>개인 정보처리방침</span>
              </li>
            </ul> */}
          <p>Ⓒ 1998-2023 MEGAZONE.DIGITAL ALL RIGHTS RESERVED</p>
        </div>
      </div>
    </S.Content>
  );
};
