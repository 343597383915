import { makeAutoObservable } from 'mobx';
import { AlertModalStore } from '@components/alert/AlertModalStore';
import { ToastStore } from '@components/toast/ToastStore';
import { ErrorNotiUiStore } from '@pages/admin/modals/ErrorNoti/ErrorNotiUiStore';
import { StatisticFilterUiStore } from '@pages/admin/modals/StatisticFilter/StatisticFilterUiStore';
import { ApplicationCreateStore } from '@pages/admin/modals/applicationCreate/ApplicationCreateStore';
import { OrderModifyModalStore } from '@pages/customer/common/cart/orderModifyModal/OrderModifyModalStore';
import { AlertStore } from '@pages/customer/common/modals/alert/AlertStore';
import { UserGroupCreateStore } from '@pages/customer/common/modals/userGroupCreate/UserGroupCreateStore';
import { MyProfileModalStore } from '@pages/customer/employee/gnb/myProfileModal/MyProfileModalStore';
import { ExternalManagerAddFromModalStore } from '@pages/customer/manager/userGroup/managerConfig/managerAdd/ExternalManagerAddFromModalStore';
import { ManagerAddFromUserStore } from '@pages/customer/manager/userGroup/managerConfig/managerAdd/ManagerAddFromUserStore';
import { MemberAddStore } from '@pages/customer/manager/userGroup/userGroupMgmt/userGroupDetail/members/memberAdd/MemberAddStore';
import { UserIndividualRegisterStore } from '@pages/customer/manager/userGroup/userMgmt/userAdd/UserIndividualRegisterStore';
import { UserEditStore } from '@pages/customer/manager/userGroup/userMgmt/userEdit/UserEditStore';
import type { StatisticSearchFilter } from '@type/Statistics';
import type { SearchFilter } from '@type/Subscription';
import { useStore } from './RootStore';

class UiStore {
  private _permissionTypeUi: 'ADMIN_UI' | 'IT_ADMIN_UI' | 'USER_UI';

  private _isSessionExpiredVisible: boolean;

  private _isVisibleTenantCreateModal: boolean;

  private _isSubscriptionSearchModalVisible: boolean;

  private _selectedSubscriptionSearchFilter: SearchFilter;

  private _isVisibleSoftwarePlanCreateModal: boolean;

  private _softwarePlanYear: number | null;

  private _isVisibleProviderCreateModal: boolean;

  private _isVisibleProviderDetailModal: boolean;

  private _providerDetailId: string;

  private _isVisiblePurchaseContractCreateModal: boolean;

  private _selectedStatisticSearchFilter: StatisticSearchFilter;

  private _isYearPickerVisible: boolean;

  errorNotiUiStore: ErrorNotiUiStore;

  isGlobalLoaderVisible: boolean;

  statisticFilterUiStore: StatisticFilterUiStore;

  private _applicationCreateStore: ApplicationCreateStore;

  private _isLnbVisible: boolean;

  private _userGroupCreateStore: UserGroupCreateStore;

  private _alertStore: AlertStore;

  private _memberAddStore: MemberAddStore;

  private _orderModifyModalStore: OrderModifyModalStore;

  private _alertModalStore: AlertModalStore;

  private _profileModalStore: MyProfileModalStore;

  private _toastStore: ToastStore;

  private _userIndividualRegisterStore: UserIndividualRegisterStore;

  private _userEditStore: UserEditStore;

  private _managerAddFromUserStore: ManagerAddFromUserStore;

  private _externalManagerAddFromModalStore: ExternalManagerAddFromModalStore;

  private _isCartToast: boolean;

  private _cartSoftwareId: string;

  constructor() {
    this._permissionTypeUi = 'USER_UI';
    this._isSessionExpiredVisible = false;
    this._isVisibleTenantCreateModal = false;
    this._isSubscriptionSearchModalVisible = false;
    this._selectedSubscriptionSearchFilter = {};
    this._isVisibleSoftwarePlanCreateModal = false;
    this._softwarePlanYear = null;
    this._isVisibleProviderCreateModal = false;
    this._isVisibleProviderDetailModal = false;
    this._providerDetailId = '';
    this._isVisiblePurchaseContractCreateModal = false;
    this._selectedStatisticSearchFilter = {};
    this._isYearPickerVisible = false;
    this.errorNotiUiStore = new ErrorNotiUiStore();
    this.isGlobalLoaderVisible = false;
    this.statisticFilterUiStore = new StatisticFilterUiStore();
    this._applicationCreateStore = new ApplicationCreateStore();
    this._isLnbVisible = false;
    this._userGroupCreateStore = new UserGroupCreateStore();
    this._alertStore = new AlertStore();
    this._memberAddStore = new MemberAddStore();
    this._orderModifyModalStore = new OrderModifyModalStore();
    this._alertModalStore = new AlertModalStore();
    this._profileModalStore = new MyProfileModalStore();
    this._toastStore = new ToastStore();
    this._userIndividualRegisterStore = new UserIndividualRegisterStore();
    this._userEditStore = new UserEditStore();
    this._managerAddFromUserStore = new ManagerAddFromUserStore();
    this._externalManagerAddFromModalStore = new ExternalManagerAddFromModalStore();
    this._isCartToast = false;
    this._cartSoftwareId = '';
    makeAutoObservable<UiStore>(this);
  }

  setTypeUi(typeUi: 'ADMIN_UI' | 'IT_ADMIN_UI' | 'USER_UI') {
    this._permissionTypeUi = typeUi;
  }

  get isAdminTypeUi() {
    return this._permissionTypeUi === 'ADMIN_UI';
  }

  get isItAdminTypeUi() {
    return this._permissionTypeUi === 'IT_ADMIN_UI';
  }

  get isUserTypeUi() {
    return this._permissionTypeUi === 'USER_UI';
  }

  get externalManagerAddFromModalStore(): ExternalManagerAddFromModalStore {
    return this._externalManagerAddFromModalStore;
  }

  get managerAddFromUserStore(): ManagerAddFromUserStore {
    return this._managerAddFromUserStore;
  }

  get userEditStore(): UserEditStore {
    return this._userEditStore;
  }

  get userIndividualRegisterStore(): UserIndividualRegisterStore {
    return this._userIndividualRegisterStore;
  }

  get toastStore(): ToastStore {
    return this._toastStore;
  }

  get profileModalStore(): MyProfileModalStore {
    return this._profileModalStore;
  }

  get alertModalStore(): AlertModalStore {
    return this._alertModalStore;
  }

  get orderModifyModalStore(): OrderModifyModalStore {
    return this._orderModifyModalStore;
  }

  get memberAddStore(): MemberAddStore {
    return this._memberAddStore;
  }

  get alertStore(): AlertStore {
    return this._alertStore;
  }

  get isVisiblePurchaseContractCreateModal(): boolean {
    return this._isVisiblePurchaseContractCreateModal;
  }

  openPurchaseContractCreateModal = () => {
    this._isVisiblePurchaseContractCreateModal = true;
  };

  closePurchaseContractCreateModal = () => {
    this._isVisiblePurchaseContractCreateModal = false;
  };

  get isVisibleProviderDetailModal(): boolean {
    return this._isVisibleProviderDetailModal;
  }

  get providerDetailId(): string {
    return this._providerDetailId;
  }

  openProviderDetailModal(id: string) {
    this._providerDetailId = id;
    this._isVisibleProviderDetailModal = true;
  }

  closeProviderDetailModal() {
    this._isVisibleProviderDetailModal = false;
  }

  get isVisibleProviderCreateModal(): boolean {
    return this._isVisibleProviderCreateModal;
  }

  openProviderCreateModal() {
    this._isVisibleProviderCreateModal = true;
  }

  closeProviderCreateModal() {
    this._isVisibleProviderCreateModal = false;
  }

  get isVisibleSoftwarePlanCreateModal(): boolean {
    return this._isVisibleSoftwarePlanCreateModal;
  }

  get softwarePlanYear(): number {
    return this._softwarePlanYear as number;
  }

  openSoftwareCreateModal(year: number) {
    this._softwarePlanYear = year;
    this._isVisibleSoftwarePlanCreateModal = true;
  }

  closeSoftwareCreateModal() {
    this._isVisibleSoftwarePlanCreateModal = false;
  }

  get selectedStatisticSearchFilter(): StatisticSearchFilter {
    return this._selectedStatisticSearchFilter;
  }

  public setSelectedStatisticSearchFilter = (filter: StatisticSearchFilter) => {
    this._selectedStatisticSearchFilter = filter;
  };

  public get isSessionExpiredVisible(): boolean {
    return this._isSessionExpiredVisible;
  }

  public openSessionExpiredModal() {
    this._isSessionExpiredVisible = true;
  }

  public closeSessionExpiredModal() {
    this._isSessionExpiredVisible = false;
  }

  public get isVisibleTenantCreateModal() {
    return this._isVisibleTenantCreateModal;
  }

  public openTenantCreateModal() {
    this._isVisibleTenantCreateModal = true;
  }

  public closeTenantCreateModal() {
    this._isVisibleTenantCreateModal = false;
  }

  public get isSubscriptionSearchModalVisible(): boolean {
    return this._isSubscriptionSearchModalVisible;
  }

  public openSubscriptionSearchModal = () => {
    this._isSubscriptionSearchModalVisible = true;
  };

  public closeSubscriptionSearchModal = () => {
    this._isSubscriptionSearchModalVisible = false;
  };

  public get selectedSubscriptionSearchFilter(): SearchFilter {
    return this._selectedSubscriptionSearchFilter;
  }

  public setSelectedSubscriptionSearchFilter = (filter: SearchFilter) => {
    this._selectedSubscriptionSearchFilter = filter;
  };

  public setIsYearPickerVisible = (isVisible: boolean) => {
    this._isYearPickerVisible = isVisible;
  };

  get isYearPickerVisible() {
    return this._isYearPickerVisible;
  }

  public openGlobalLoader = () => {
    this.isGlobalLoaderVisible = true;
  };

  public closeGlobalLoader = () => {
    this.isGlobalLoaderVisible = false;
  };

  get applicationCreateStore() {
    return this._applicationCreateStore;
  }

  get userGroupCreateStore() {
    return this._userGroupCreateStore;
  }

  get isLnbVisible() {
    return this._isLnbVisible;
  }

  openLnb = () => {
    this._isLnbVisible = true;
  };

  closeLnb = () => {
    this._isLnbVisible = false;
  };
  get cartSoftwareId(): string {
    return this._cartSoftwareId;
  }

  get isCartToast() {
    return this._isCartToast;
  }
  openCartToast = (id: string) => {
    this._cartSoftwareId = id;
    this._isCartToast = true;
  };

  closeCartToast = () => {
    this._isCartToast = false;
  };
}

const uiStore = new UiStore();

export default uiStore;

export const useUiStore = () => {
  const { uiStore } = useStore();
  return uiStore;
};
