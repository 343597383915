import dayjs from 'dayjs';
import type { SubscriptionUserDTO, SubscriptionUserUpdateRq } from '@repositories/subscriptionRepository';

export class SubscriptionUserModel {
  private dto: SubscriptionUserDTO;
  private _subscriptionId: string;

  constructor(dto: SubscriptionUserDTO, subscriptionId: SubscriptionId) {
    this.dto = dto;
    this._subscriptionId = subscriptionId;
  }

  get subscriptionId() {
    return this._subscriptionId;
  }

  get isMember() {
    return this.dto.isMember;
  }

  get billableStatus() {
    return this.dto.billableStatus;
  }

  get userLoginId() {
    return this.dto.userLoginId;
  }

  get userName() {
    return this.dto.user?.name || this.dto.userName;
  }

  get userEmail() {
    return this.dto.userEmail || this.dto.user?.email;
  }

  get userStatus() {
    return this.dto.userStatus;
  }

  get userType() {
    return this.dto.userType;
  }

  get userRole() {
    return this.dto.userRole;
  }

  get userOrganization() {
    return this.dto.userOrganization || this.dto.userGroup?.name;
  }

  get lastLoginTime() {
    return this.dto.lastLoginTime;
  }

  get userId() {
    return this.user?.id;
  }

  get user() {
    return this.dto.user;
  }

  get userGroup() {
    return this.dto.userGroup;
  }

  get tenantMemberStatus() {
    return this.dto.tenantMemberStatus;
  }

  get createdBy() {
    return this.dto.createdBy;
  }

  get createdAt() {
    return dayjs(this.dto.createdDate);
  }

  get updatedBy() {
    return this.dto.updatedBy;
  }

  get updatedAt() {
    return dayjs(this.dto.updatedDate);
  }

  get memo() {
    return this.dto.memo ?? '';
  }

  getSubscriptionUserUpdateRq(updateValue: Partial<SubscriptionUserUpdateRq>): SubscriptionUserUpdateRq {
    return {
      billableStatus: this.dto.billableStatus,
      lastLoginTime: this.dto.lastLoginTime,
      memo: this.dto.memo,
      userEmail: this.dto.userEmail,
      userId: updateValue.userId !== undefined ? updateValue.userId : this.user ? this.user.id : undefined,
      userLoginId: this.dto.userLoginId,
      userName: this.dto.userName,
      userOrganization: this.dto.userOrganization,
      userRole: this.dto.userRole,
      userType: this.dto.userType,
      ...updateValue,
    };
  }
}
