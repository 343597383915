// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';
import { makeAutoObservable } from 'mobx';
import sessionStorage from '@repositories/browserStorage/sessionStorage/SessionStorage';
import type { AdditionalPermission, Permission } from '@repositories/userRepository';
import { jwtUtil } from '@utils/jwtUtil';
import { TenantModel } from '@models/tenantModels';

type JWT = {
  sub: string;
  id: string;
  roles: string[];
  ap: string[];
  iat: number;
  exp: number;
};

export type AuthTenant = {
  id: string;
  permission: Permission;
  type: string;
};

class AuthStore {
  private _isLogin: boolean;
  private _id: string;
  private _name: string;
  private _curTenant: AuthTenant;
  private _ap: AdditionalPermission[];
  private _managerTenantInfo?: TenantModel;

  constructor() {
    this._isLogin = false;
    this._id = '';
    this._name = '';
    this._curTenant = {
      id: '',
      permission: 'USER',
      type: 'Tenant',
    };
    this._ap = [];
    this._managerTenantInfo = undefined;

    makeAutoObservable<AuthStore>(this);
  }

  private clear = () => {
    this._isLogin = false;
    this._id = '';
    this._name = '';
    this._curTenant = {
      id: '',
      permission: 'USER',
      type: 'Tenant',
    };
    this._ap = [];
  };

  /**
   * 컴포넌트 최상단에서 호출하여 토큰의 정보를 authStore에 받아오는 함수
   * 새로고침 등의 이유로 화면이 다시 그려질 때 로그인 상태를 유지하기 위함
   * @param curTenantId 어드민 페이지인 경우 사용. 어느 테넌트의 정보를 표시하는지 구분하는데 사용
   * @param isAdmin 슈퍼 어드민인지 아니면 어드민/유저인지 구분하는데 사용
   */
  public initialize = (curTenantId?: string, isAdmin = false) => {
    const tk = sessionStorage.get('tk');
    if (tk) {
      this._isLogin = true;
      const decoded: JWT = jwt_decode(tk as string);
      this._ap = jwtUtil.parseAp(decoded.ap);
      if (!isAdmin) {
        this.updateCurTenant(curTenantId);
        if (!this.curTenant?.id) {
          // 240216 hsj. 토큰을 authStore와 연동하는 과정이기 때문에 굳이 sessionStorage에서 토큰을 지워주진 않음
          this.clear();
          return;
        }
      }
    }

    const authInfo = sessionStorage.get('authInfo');
    if (authInfo && typeof authInfo === 'string') {
      const parse = JSON.parse(authInfo);
      this._id = parse.id;
      this._name = parse.name;
    } else {
      this._id = '';
      this._name = '';
    }
  };

  public get isLogin(): boolean {
    return this._isLogin;
  }

  /**
   * - 직접 호출하지 말고 useUser::useSignIn에서만 호출할 것
   * - login은 useSignIn을 이용해 처리
   * @returns 로그인 성공 실패 여부
   */
  public login(id: string, name: string, ap: AdditionalPermission[], curTenantId?: string, isAdmin = false) {
    this._isLogin = true;
    this._id = id;
    this._name = name;
    const authInfo = { id, name };
    sessionStorage.set('authInfo', JSON.stringify(authInfo));
    this._ap = ap;
    const tk = sessionStorage.get('tk');

    if (isAdmin) {
      return true;
    }

    let success = true;
    if (tk) {
      this.updateCurTenant(curTenantId);
      if (!this.curTenant?.id) {
        // 240216 hsj. 로그인에 실패한 것이기 때문에 sessionStorage의 토큰까지 제거
        this.logout();
        success = false;
      }
    }

    return success;
  }

  private updateCurTenant(curTenantId?: string) {
    const tenants = this._ap
      .filter(ap => ap.resourceType === 'Tenant')
      .map(ap => ({ id: ap.resourceId, permission: ap.permission, type: 'Tenant' }));

    if (curTenantId) {
      const curTenant = tenants.find(({ id }) => id === curTenantId);
      if (curTenant) {
        this._curTenant = curTenant;
      }
    } else {
      this._curTenant = tenants[0];
    }
  }

  /**
   * - 직접 호출하지 말고 useUser::useLogout에서만 호출할 것
   * - logout은 useLogout을 이용해 처리
   */
  public logout() {
    sessionStorage.removeItem('tk');
    this.clear();
  }

  public get id(): string {
    return this._id;
  }

  public get name() {
    return this._name;
  }

  public get curTenant(): AuthTenant {
    return this._curTenant;
  }

  public get isAdmin(): boolean {
    return !!this._ap.find(ap => ap.resourceId === this._curTenant.id && ap.permission === 'SUPER_ADMIN');
  }

  public get isItAdmin(): boolean {
    return !!this._ap.find(
      ap =>
        ap.resourceId === this._curTenant.id &&
        (ap.permission === 'GENERAL_ADMIN' || ap.permission === 'SUPER_ADMIN' || ap.permission === 'EXTERNAL_ADMIN'),
    );
  }

  setManagerTenantInfo(tenantInfo: TenantModel) {
    this._managerTenantInfo = tenantInfo;
  }

  get managerTenantInfo() {
    return this._managerTenantInfo ?? new TenantModel();
  }
}

const authStore = new AuthStore();

export default authStore;
