import dayjs from 'dayjs';
import i18next from 'i18next';
import type { DailyUsageDTO, ModuleUsageList } from '@repositories/usage/Types';
import { ModuleUsageListModel } from './ModuleUsageListModel';

export class DailyUsageModel {
  private _dto: DailyUsageDTO;
  private _usageList: ModuleUsageListModel[];

  constructor(dto: DailyUsageDTO) {
    this._dto = dto;
    this._usageList = dto.usageList.map(usage => new ModuleUsageListModel(usage)) ?? [];
  }

  get date() {
    return this._dto.date;
  }

  get subscriptionId() {
    return this._dto.subscriptionId;
  }

  get usageList(): ModuleUsageListModel[] {
    return this._usageList;
  }

  get usageListWithSum(): ModuleUsageListModel[] {
    return [this.usageSummary, ...this._usageList];
  }

  get usageSummary(): ModuleUsageListModel {
    const summary: ModuleUsageList = {
      module: i18next.t('Acc_Main_12'),
      usages: [
        {
          value: this.usageList.reduce((sum, { usages }) => sum + usages[0].value, 0),
          limit: 0,
          unit: '',
        },
      ],
    };

    return new ModuleUsageListModel(summary);
  }

  get isForecasted() {
    return this._dto.status === 'FORECASTED';
  }

  get createdDate(): string {
    return this._dto.createdDate ? dayjs(this._dto.createdDate).format('YYYY-MM-DD HH:mm:ss') : '';
  }

  get updatedDate() {
    return this._dto.updatedDate ? dayjs(this._dto.updatedDate).format('YYYY-MM-DD HH:mm:ss') : '';
  }

  getUsageOfModule(moduleCode: string): number | undefined {
    return this.usageList.find(({ module }) => module === moduleCode)?.usages[0].value;
  }
}
